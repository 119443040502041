import React, { useState, useEffect, useRef } from 'react';
import { Modal, Divider, Button, message } from 'antd';
import {
    Page,
    XMSearchInput,
    XMTable,
    XMCascader,
    XMClearFilter
} from '@/components';
import { getDistrictTree, getEduTenantByPage, delEduTenant } from '@/data-source/school';
import AddSchoolModal from './AddSchoolModal';
import User from '@/common/js/user';
import _ from 'underscore';

function SchoolManage(props: any) {
    const [query, setQuery] = useState<any>({
        current: 1,
        size: 10,
        tenantId: User.tenantId
    })
    const [district, setDistrict] = useState<any[]>([])
    const [list, setList] = useState([])
    const [modal, setModal] = useState<any>(null)
    const [total, setTotal] = useState<any>(0)
    // const timerRef = useRef<any>(null);
    // const queryRef = useRef<any>(null);

    useEffect(() => {
        getEduTenant();
    }, [query])

    function getEduTenant() {
        getEduTenantByPage(query).then((res: any) => {
            setList(res.result.records);
            setTotal(res.result.total)
        })
    }

    // useEffect(() => {
    //     clearTimeout(timerRef.current);
    //     timerRef.current = setTimeout(() => {
    //         getEduTenant()
    //     }, 300)
    // }, [queryRef.current])

    function handleClickAdd() {
        const _modal = <AddSchoolModal type='add' info={{}} district={district} onClose={() => {
            getEduTenant();
            setModal(null)
        }} />
        setModal(_modal)
    }

    function handleClickEdit(info: any) {
        const _modal = <AddSchoolModal type='edit' district={district} info={info} onClose={() => {
            setModal(null);
            getEduTenant()
        }} />
        setModal(_modal)
    }

    useEffect(() => {
        getDistrictTree({}).then((res: any) => {
            setDistrict(res.result)
        })
    }, [])


    function handlePageChange(page: number) {
        const _query = { ...query };
        _query.current = page;
        setQuery(_query)
    }

    function renderColumns() {
        return [
            {
                key: 'tenantName',
                dataIndex: 'tenantName',
                title: '学校名称',
            },
            {
                key: 'address',
                dataIndex: 'address',
                title: '地址',
            },
            {
                key: 'linkName',
                dataIndex: 'linkName',
                title: '联系人',
            },
            {
                key: 'linkPhone',
                dataIndex: 'linkPhone',
                title: '联系人手机',
            },
            {
                key: 'operator',
                dataIndex: 'operator',
                title: '操作',
                render: (_: any, record: any) => {
                    return (
                        <>
                            <span className='a-link' onClick={() => {
                                handleClickEdit(record)
                            }}>
                                编辑
                            </span>
                            <Divider type='vertical' />
                            <span className='a-link' onClick={() => {
                                handleClickDelete(record)
                            }}>
                                删除
                            </span>
                        </>
                    );
                },
            },
        ];
    }



    function handleClickDelete({ id } = { id: '' }) {
        Modal.confirm({
            icon: <span style={{color:'#FFB714'}}  className="iconfont modal-confirm-icon">&#xe8b0;</span>,
            title: '你确定要删除校区吗？',
            content: '删除后，将删除“河上中学”相关联的考核任务数据。',
            okText:'确认删除',
            onOk: () => {
                delEduTenant({ id }).then(() => {
                    message.success('删除成功')
                    getEduTenant();
                })
            }
        })
       
    }

    return <Page title='学校管理'>
        <div className='page__top__filter__wrap'>
            <Button type='primary' onClick={handleClickAdd}>
                添加学校
            </Button>
            <div className='filter__right__wrap'>
                <XMSearchInput
                    value={query.tenantName}
                    className='filter__item'
                    placeholder='请输入学校名称'
                    onChange={_.debounce((e: any) => {
                        const _query = { ...query };
                        _query.tenantName = e.target.value;
                        setQuery(_query)
                    }, 200)}
                    label='学校名称'
                />
                <XMCascader
                    style={{width: '100%'}}
                    className='filter__item'
                    fieldNames={{ label: 'label', value: 'value', children: 'children' }}
                    options={district}
                    label='地址'
                    value ={query.codes ||[]}
                    changeOnSelect={true}
                    placeholder='请选择地址'
                    onChange={(codes: any) => {
                        const _query = { ...query };
                        _query.provinceCode =codes[0];
                        _query.cityCode =codes[1];
                        _query.areaCode =codes[2];
                        _query.codes =codes;
                        setQuery(_query)
                    
                    }}
                    allowClear
                />
                <XMSearchInput
                    className='filter__item'
                    label='联系人'
                    value={query.linkNameOrPhone}
                    placeholder='请输入姓名/手机号'
                    onChange={_.debounce((e: any) => {
                        const _query = { ...query };
                        _query.linkNameOrPhone = e.target.value;
                        setQuery(_query)
                    }, 300)}
                />
            </div>
            <XMClearFilter
                onClick={() => {
                    setQuery({
                        current: 1,
                        size: 10,
                        tenantId: User.tenantId
                    })
                }}
            />
        </div>
        <div className='tip-message'>{`学校共${total}个`}</div>
        <XMTable
            dataSource={list}
            columns={renderColumns()}
            total={total}
            current={query.current}
            onPageChange={handlePageChange}
        />
        {modal}
        
    </Page>
}

export default SchoolManage
