/*
 * @Author: liuqing
 * @Date: 2021-09-28 16:41:00
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-25 15:36:10
 * @Description: 指派弹窗
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useState } from 'react';
import { DatePicker, Form, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { XMModal } from '@/components';
import style from './AssignModal.module.less';
import SchoolSelect from './SchoolSelect';
import _ from 'underscore';
import { appointAssessTable } from '@/data-source/assess';
import moment from 'moment';

interface IAssignModalProps {
  assessTableId: string | number;
  tenantIdList?: string[];
  onCancel?: () => void;
  onOk?: () => void;
}

function AssignModal(props: IAssignModalProps) {
  const { assessTableId, tenantIdList = [], onCancel, onOk } = props;
  const [schoolSelectVisible, setSchoolSelectVisible] = useState(false);
  const [date, setDate] = useState<any>({});
  const [schoolList, setSchoolList] = useState<any[]>([]);

  function handleOk() {
    if (_.isEmpty(date)) {
      return message.warn('请选择完成期限');
    }
    if (_.isEmpty(schoolList)) {
      return message.warn('请选择学校');
    }
    const params = {
      ...date,
      assessTableId,
      tenantIdList: schoolList.map((item: any) => item.id),
    };
    appointAssessTable(params).then(() => {
      message.success('指派成功');
      onOk && onOk();
    });
  }

  function renderSchoolList() {
    return (
      <div className={style.schoolList}>
        <div
          className={style.title}
          style={{ marginBottom: schoolList.length === 0 ? 0 : 16 }}
        >
          共{schoolList.length}个学校
        </div>
        <div className={style.listContainer}>
          {schoolList.map((item: any, index: number) => (
            <div className={style.schoolItem} key={item.id}>
              <div className={style.schoolName}>{item.name}</div>
              <div
                className='a-link'
                onClick={() => {
                  const data = _.clone(schoolList);
                  data.splice(index, 1);
                  setSchoolList(data);
                }}
              >
                删除
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <XMModal
      title='指派'
      visible={true}
      width={480}
      okText='指派'
      centered={false}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item required label='完成期限'>
          <DatePicker.RangePicker
            disabledDate={(current) => {
              return current && current < moment().startOf('day');
            }}
            onChange={dates => {
              if (dates?.length === 2) {
                setDate({
                  startDate: dates[0]?.startOf('day').valueOf(),
                  endDate: dates[1]?.endOf('day').valueOf(),
                });
              }
            }}
            getPopupContainer={trigger => trigger.parentElement as HTMLElement}
          />
        </Form.Item>
        <Form.Item required label='学校' className={style.schoolForm}>
          <div className={style.schoolWrap}>
            <div
              className={style.chooseText}
              onClick={() => setSchoolSelectVisible(!schoolSelectVisible)}
            >
              <span>选择学校</span>
              <DownOutlined />
            </div>
            <SchoolSelect
              visible={schoolSelectVisible}
              disabledList={tenantIdList}
              selectedList={schoolList}
              onCancel={() => setSchoolSelectVisible(false)}
              onOk={(ids, list) => {
                setSchoolSelectVisible(false);
                setSchoolList(list);
              }}
            />
            {renderSchoolList()}
          </div>
        </Form.Item>
      </Form>
    </XMModal>
  );
}

export default AssignModal;
