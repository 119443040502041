/*
 * @Author: liuqing
 * @Date: 2021-09-24 15:21:03
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-11 14:56:25
 * @Description: 下拉选择框的二次封装
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { ReactNode } from 'react';
import { Select, SelectProps } from 'antd';
import { v4 as uuid } from 'uuid';

interface XMStaticSelectProps extends SelectProps<any> {
  label?: string | ReactNode;
  selectClassName?: string;
  width?: number | string;
  allowClear?: boolean;
  dataSet?: IOption[];
}

interface IOption {
  id: string | number;
  name: string | number | ReactNode;
}

function XMStaticSelect(props: XMStaticSelectProps) {
  const {
    label,
    className,
    selectClassName,
    width,
    allowClear = true,
    dataSet = [],
    ...restProps
  } = props;

  function getPopupContainer(trigger: HTMLElement) {
    return trigger.parentElement as HTMLElement;
  }

  return (
    <div className={className}>
      {label && <label>{label}：</label>}
      <Select
        className={selectClassName}
        {...restProps}
        style={{ width }}
        allowClear={allowClear}
        getPopupContainer={getPopupContainer}
      >
        {dataSet.map((item: IOption) => (
          <Select.Option key={uuid()} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default XMStaticSelect;
