/*
 * @Author: liuqing
 * @Date: 2020-11-13 13:52:04
 * @Last Modified by: liuqing
 * @Last Modified time: 2020-11-17 19:28:11
 * @Desc: 使用 tooltip 展示长字符串
 */

import React from 'react';
import { Tooltip } from 'antd';

interface EllipsisProps {
  text: string; // 指定的文本
  length?: number; // 到达指定长度截取
  hyphen?: boolean; // 空值是否需要显示 -
}

function XMEllipsis(props: EllipsisProps) {
  const { text, length = 20, hyphen = false } = props;
  if (text) {
    if (text.length > length) {
      return <Tooltip title={text}>{text.substring(0, length)}...</Tooltip>;
    }
    return <span>{text}</span>;
  }
  if (hyphen) {
    return <span>-</span>;
  }
  return null;
}

export default XMEllipsis;
