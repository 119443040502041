/*
 * @Author: 吴文洁
 * @Date: 2020-08-31 09:34:51
 * @LastEditors: liuqing
 * @LastEditTime: 2021-09-27 15:48:31
 * @Description: 
 * @Copyright: 杭州杰竞科技有限公司 版权所有
 */

import Axios from './axios';

class Service {

  static Edubff(url: string, params: any, option?: any) {
    return Axios.post('POST', `edubff/${url}`, params, option);
  }

  static Anon(url: string, params: any, option?: any) {
    return this.Edubff(`anon/${url}`, params, option)
  }

  static Public(url: string, params: any, option?: any) {
    return this.Edubff(`public/${url}`, params, option)
  }

  static post(url: string, params: any, option?: any) {
    return Axios.post('POST', url, params, option);
  }

  static MFS(url: string, params: any, option?: any) {
    return Axios.post('POST', `mfs/${url}`, params, option);
  }
}

export default Service;
