/*
 * @Author: liuqing
 * @Date: 2021-09-28 14:44:25
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-18 19:10:56
 * @Description: 富文本编辑器
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { CSSProperties, useEffect, useState } from 'react';
import E from 'wangeditor';
import { v4 as uuid } from 'uuid';

import './index.less';

interface IEditorProps {
  width?: number;
  height?: number;
  limit?: number;
  placeholder?: string;
  style?: CSSProperties;
  value?: any;
  onChange?: (length: number, html: any) => void;
}

function XMRichTextEditor(props: IEditorProps) {
  const {
    width = 550,
    height = 90,
    limit = 1000,
    placeholder,
    style,
    value,
    onChange,
    ...restProps
  } = props;
  const editorId = `editor__${uuid()}`;
  
  const [textLength, setTextLength] = useState(0);

  useEffect(() => {
    const editor = new E(`#${editorId}`);
    editor.config.height = height;
    editor.config.zIndex = 1;
    editor.config.focus = false;
    editor.config.showFullScreen = false;
    editor.config.placeholder = placeholder || '请输入';
    editor.config.menus = [
      'head',
      'bold',
      'fontSize',
      'fontName',
      'italic',
      'underline',
      'strikeThrough',
      'foreColor',
      'backColor',
      'list',
      'justify',
      'emoticon',
    ];
    editor.config.onchange = function (html: any) {
      let str = editor.txt.text(); // 去除所有特殊字符
      str = str.replace(/<xml>[\s\S]*?<\/xml>/gi, '');
      str = str.replace(/<style>[\s\S]*?<\/style>/gi, '');
      str = str.replace(/<\/?[^>]*>/g, '');
      str = str.replace(/[ | ]*\n/g, '\n');
      str = str.replace(/\&nbsp\;/gi, ' ');
      str = str.replace(/[\r\n]/g, '');
      str = str.replace(/<\/?a.*?>/g, '');
      const textLength = str.length;
      setTextLength(textLength);
      onChange && onChange(textLength, html);
    };
    editor.create();
    editor.txt.html(value);
  }, []);

  return (
    <div className='xm-rich-text-editor' style={{ width }}>
      <div id={editorId} style={{ width, ...style }} {...restProps}></div>
      <div className='limit-text'>
        <span className={textLength > limit ? 'editor-overflow-warn' : ''}>
          {textLength}
        </span>
        <span>/{limit}</span>
      </div>
    </div>
  );
}

export default XMRichTextEditor;
