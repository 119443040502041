/*
 * @Author: liuqing
 * @Date: 2021-09-24 14:35:48
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-14 10:06:25
 * @Description: 搜索框的二次封装
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import cls from 'classnames';
import { Input, InputProps } from 'antd';
import style from './index.module.less';

interface XMSearchInputProps extends InputProps {
  label?: string | ReactNode;
  style?: CSSProperties;
  allowClear?: boolean;
  inputClassName?: string;
  value?: any; // 需要和 onChange 配合
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: (value?: any) => void; // 当 value 不受控时，直接通过 onSearch 获取输入的值
}

function XMSearchInput(props: XMSearchInputProps) {
  const {
    label,
    className,
    inputClassName,
    width,
    allowClear = false,
    value = '',
    onChange,
    onSearch,
    style: restStyle,
    ...restProps
  } = props;

  const [internalVal, setInternalVal] = useState(value);

  // 当 value 受控时，保证内部的 value 与传入的一致
  useEffect(() => {
    setInternalVal(value);
  }, [value]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInternalVal(e.target.value);
    onChange && onChange(e);
  }

  function handleSearch() {
    onSearch && onSearch(internalVal);
  }

  function renderSuffix() {
    return (
      <span className={cls('iconfont', style.suffix)} onClick={handleSearch}>
        &#xe817;
      </span>
    );
  }

  return (
    <div className={className}>
      {label && <label className={style.label}>{label}：</label>}
      <Input
        className={inputClassName}
        style={{ width, ...restStyle }}
        {...restProps}
        value={internalVal}
        allowClear={allowClear}
        suffix={renderSuffix()}
        onChange={handleChange}
        onPressEnter={handleSearch}
      />
    </div>
  );
}

export default XMSearchInput;
