/*
 * @Author: liuqing
 * @Date: 2021-10-15 10:58:37
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-22 17:52:23
 * @Description: 学校端考核任务详情页
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch, withRouter } from 'react-router';
import { Badge, Divider, TableColumnsType, Tooltip } from 'antd';
import { v4 as uuid } from 'uuid';
import { Page, XMEllipsis, XMTable } from '@/components';
import ReportExamModal from './ReportExamModal';
import style from './ExamineDetail.module.less';
import { formatDate, getUrlParams } from '@/domains/basic-domain/utils';
import { getAssessTaskDetail } from '@/data-source/assess';
import { scoreUnitMap, TScoreUnit } from '@/common/js/enum';

const STATUS_MAP: any = {
  PROGRESS: {
    status: 'processing',
    text: '进行中',
  },
  INIT: {
    status: 'default',
    text: '未开始',
  },
  OVER: {
    status: 'default',
    text: '已结束',
  },
};

function SchExamineDetail() {
  const from = getUrlParams('from');
  const isFromEdu = from === 'edu';
  const match = useRouteMatch();
  const id = (match.params as any).id;
  const [assessTask, setAssessTask] = useState<any>({});
  const [contentList, setContentList] = useState([]);
  const [calculateCount, setCalculateCount] = useState(0);
  const [notCalculateCount, setNotCalculateCount] = useState(0);
  const [modal, setModal] = useState<any>(null);

  const {
    categoryName = '',
    created,
    declaredCount = 0,
    description = '-',
    endDate,
    itemCount = 0,
    name,
    score = 0,
    standardStatus,
    standardType,
    standardCount = '-',
    startDate,
    taskStatus = 'INIT',
    tenantName,
    totalScore = 0,
  } = assessTask;

  const breadcrumb = isFromEdu ? '学校考核任务详情页' : '考核任务详情页';
  const pageTitle = isFromEdu ? tenantName : name;

  const canReport = useMemo(() => taskStatus === 'PROGRESS', [taskStatus]);

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    let calculateCount = 0;
    let notCalculateCount = 0;
    contentList.map((item: any) => {
      if (item.calculateScore) {
        calculateCount += 1;
      } else {
        notCalculateCount += 1;
      }
    });
    setCalculateCount(calculateCount);
    setNotCalculateCount(notCalculateCount);
  }, [contentList]);

  function getDetail() {
    getAssessTaskDetail(id).then(res => {
      const { assessTaskVO = {}, contentList = {} } = res.result || {};
      setAssessTask(assessTaskVO);
      setContentList(contentList);
    });
  }

  const columns: TableColumnsType = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '考核项',
      render: value => <XMEllipsis text={value} hyphen length={8} />,
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: '考核说明',
      render: value => {
        if (!value) {
          return '-';
        }
        if (value.length > 8) {
          return (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: value }}></div>}
            >
              <div style={{ display: 'inline-flex' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: value.substring(0, 8),
                  }}
                ></div>
                <span>...</span>
              </div>
            </Tooltip>
          );
        }
        return <div dangerouslySetInnerHTML={{ __html: value }}></div>;
      },
    },
    {
      key: 'tagNames',
      dataIndex: 'tagNames',
      title: '标签',
      render: value => <XMEllipsis text={value.join('/')} hyphen length={12} />,
    },
    {
      key: 'unit',
      dataIndex: 'unit',
      title: '计分方式',
      render: (value: TScoreUnit, record: any) => {
        if (record.calculateScore) {
          return scoreUnitMap[value];
        }
        return '-';
      },
    },
    {
      key: 'created',
      dataIndex: 'created',
      title: '申报时间',
      render: value => (value ? formatDate(value) : '-'),
    },
    {
      key: 'itemScore',
      dataIndex: 'itemScore',
      title: '得分',
      render: value => (value !== undefined ? value : '-'),
    },
    {
      key: 'creatorName',
      dataIndex: 'creatorName',
      title: '申报人',
      render: value => (value ? value : '-'),
    },
    {
      key: 'operation',
      dataIndex: 'operation',
      title: '操作',
      render: (value, record: any) => {
        // 管理端查看考核任务
        if (isFromEdu) {
          return (
            <span
              className='a-link'
              onClick={() => {
                const modal = (
                  <ReportExamModal
                    taskId={id}
                    success={getDetail}
                    close={() => {
                      setModal(null);
                    }}
                    type='see'
                    info={record}
                  />
                );
                setModal(modal);
              }}
            >
              查看详情
            </span>
          );
        }
        // 有 id 表示申报过
        if (record.id) {
          return (
            <>
              <span
                className={!canReport ? 'link-disabled' : 'a-link'}
                onClick={() => {
                  if (!canReport) {
                    return;
                  }
                  const modal = (
                    <ReportExamModal
                      taskId={id}
                      success={getDetail}
                      close={() => {
                        setModal(null);
                      }}
                      type='edit'
                      info={record}
                    />
                  );
                  setModal(modal);
                }}
              >
                修改
              </span>
              <Divider type='vertical' />
              <span
                onClick={() => {
                  const modal = (
                    <ReportExamModal
                      taskId={id}
                      success={getDetail}
                      close={() => {
                        setModal(null);
                      }}
                      type='see'
                      info={record}
                    />
                  );
                  setModal(modal);
                }}
                className='a-link'
              >
                查看
              </span>
            </>
          );
        } else {
          return (
            <span
              onClick={() => {
                if (!canReport) {
                  return;
                }
                const modal = (
                  <ReportExamModal
                    taskId={id}
                    success={getDetail}
                    close={() => {
                      setModal(null);
                    }}
                    type='edit'
                    info={record}
                  />
                );
                setModal(modal);
              }}
              className={!canReport ? 'link-disabled' : 'a-link'}
            >
              去申报
            </span>
          );
        }
      },
    },
  ];

  function enhanceText(text: string | number) {
    return <span style={{ color: '#2966FF' }}> {text}</span>;
  }

  return (
    <Page breadcrumb={breadcrumb}>
      <div className='page__item__title'>
        <span className='page__subtitle' style={{ marginRight: 24 }}>
          {pageTitle}
        </span>
        <Badge
          status={STATUS_MAP[taskStatus].status}
          text={STATUS_MAP[taskStatus].text}
        />
      </div>
      <div className={style.topTips}>
        <div style={{ marginBottom: 12 }}>
          <span className={style.tipItem}>分类：{categoryName}</span>
          <span className={style.tipItem}>
            创建时间：{created ? formatDate(created) : '-'}
          </span>
          <span className={style.tipItem}>
            申报时限：
            {startDate
              ? `${formatDate(startDate)}～${formatDate(endDate)}`
              : '-'}
          </span>
        </div>
        <div className={style.description}>
          <div className={style.name}>考核表说明：</div>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
      </div>
      <div className='page__item__title'>达标情况</div>
      <div className={style.summaryWrap}>
        {standardStatus !== undefined && (
          <Choose>
            <When condition={standardStatus}>
              <div
                className={style.standardTypeTag}
                style={{ borderColor: '#0ACCA4', color: '#0ACCA4' }}
              >
                已达标
              </div>
            </When>
            <Otherwise>
              <div
                className={style.standardTypeTag}
                style={{ borderColor: '#FF4F4F', color: '#FF4F4F' }}
              >
                未达标
              </div>
            </Otherwise>
          </Choose>
        )}
        <div className={style.summaryBox}>
          <div className={style.value}>
            {score}
            {standardType === 'SCORE' ? `/${standardCount}` : ''}
          </div>
          <div className={style.total}>总分{totalScore}</div>
          <div className={style.name}>
            当前得分{standardType === 'SCORE' ? '/达标分' : ''}
          </div>
        </div>
        <div className={style.summaryBox}>
          <div className={style.value}>
            {declaredCount}
            {standardType === 'PASSITEM' ? `/${standardCount}` : ''}
          </div>
          <div className={style.total}>总项数{itemCount}</div>
          <div className={style.name}>
            已申报{standardType === 'PASSITEM' ? '/达标项' : ''}
          </div>
        </div>
      </div>
      <div className='page__item__title'>考核内容</div>
      <div style={{ marginBottom: 12 }}>
        考核项共{enhanceText(contentList.length)}
        ，总分{enhanceText(totalScore)}，其中计分项
        {enhanceText(calculateCount)}，不计分项
        {enhanceText(notCalculateCount)}
      </div>
      <XMTable
        rowKey={() => uuid()}
        columns={columns}
        dataSource={contentList}
      />

      {modal}
    </Page>
  );
}

export default withRouter(SchExamineDetail);
