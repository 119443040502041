/*
 * @Author: liuqing
 * @Date: 2021-09-27 11:16:05
 * @LastEditors: liuqing
 * @LastEditTime: 2021-09-27 17:19:41
 * @Description: 登录接口
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import Service from '@/common/js/service';

export function login(params: object): Promise<any> {
  return Service.Anon('login/login', params);
}

export function authQrUrl(params: object): Promise<any> {
  return Service.Anon('login/authQrUrl', params);
}

export function bindQrUrl(params: object): Promise<any> {
  return Service.Anon('login/bindQrUrl', params);
}

export function loginOrBind(params: object): Promise<any> {
  return Service.Anon('login/loginOrBind', params);
}
export function bindWechat(params: object): Promise<any> {
  return Service.Public('admin/bindWechat', params);
}

