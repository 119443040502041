/*
 * @Author: liuqing
 * @Date: 2021-09-28 14:34:41
 * @LastEditors: liuqing
 * @LastEditTime: 2021-09-28 14:36:43
 * @Description:
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React from 'react';
import { Tooltip } from 'antd';

interface IXMClearFilterProps {
  onClick: () => void;
}

function XMClearFilter(props: IXMClearFilterProps) {
  const { onClick } = props;

  return (
    <Tooltip title='清空筛选'>
      <span className='iconfont filter__clear__icon' onClick={onClick}>
        &#xe61b;
      </span>
    </Tooltip>
  );
}

export default XMClearFilter;
