import { STORE_PERMISSION, STORE_USER_INFO } from "./constants";

/*
 * @Author: liuqing
 * @Date: 2021-09-28 11:14:13
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-08 11:08:07
 * @Description: 
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
export function setStorePermission(payload: any) {
  return {
    type: STORE_PERMISSION,
    payload
  }
}

export function setStoreUserInfo(payload: any) {
  return {
    type: STORE_USER_INFO,
    payload
  }
}
