/*
 * @Author: liuqing
 * @Date: 2021-09-18 10:22:54
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-19 14:35:32
 * @Description: 登录页
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import cls from 'classnames';
import { Button, Input, message } from 'antd';
import md5 from 'md5';
import { authQrUrl, login } from '@/data-source/login';
import User from '@/common/js/user';
import BindWechat from '@/components/BindWechat'
import style from './index.module.less';
import RCHistory from '@/routes/history';

type LoginType = 'PHONE' | 'WX';

function Login() {
  const [loginType, setLoginTpe] = useState<LoginType>('WX');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [wechatOpenId, setWechatOpenId] = useState<any>('');
  const [wechatNickName, setWechatNickName] = useState<any>('');
  
  const href = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE0NHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAuaWNvbjM4X21zZyB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30KLmltcG93ZXJCb3h7d2lkdGg6MTQ0cHh9Cg=='

  // 只允许输入 11 位数字
  function handlePhoneChange(e: ChangeEvent<HTMLInputElement>) {
    setPhone(e.target.value.replace(/[^0-9]/g, ''));
  }

  function handleLogin() {
    const params: any = {
      accountNo: phone,
      appTerm: 'EDUBFF_PC_WEB_ADMIN',
      certificate: md5(password)
    }
    if (wechatOpenId) {
      params.wechatOpenId = wechatOpenId;
      params.wechatNickName=wechatNickName
    }
    if (verify()) {
      login(params).then(res => {
        const {
          account,
          id,
          name,
          path,
          tenantId,
          tenantName,
          wechatOpenId,
          xmToken,
        } = res.result;
        User.account = account;
        User.uid = id;
        User.name = name;
        User.path = path;
        User.tenantId = tenantId;
        User.tenantName = tenantName;
        User.wechatOpenId = wechatOpenId;
        User.token = xmToken;

        RCHistory.push('/');
      });
    }
  }

  function verify(): boolean {
    if (phone === '') {
      message.warn('请输入手机号');
      return false;
    }

    if (phone.length < 11) {
      message.warn('请输入11位手机号');
      return false;
    }

    if (password === '') {
      message.warn('请输入密码');
      return false;
    }

    return true;
  }

  function renderPhoneLogin() {
    return (
      <Fragment>
        <form className={style.phoneWrap}>
          <Input
            className={style.input}
            placeholder='请输入手机号'
            maxLength={11}
            value={phone}
            onChange={handlePhoneChange}
          />
          <Input.Password
            className={style.input}
            placeholder='请输入密码'
            value={password}
            onChange={e => setPassword(e.target.value)}
            onPressEnter={handleLogin}
          />
        </form>
        <Button className={style.btn} type='primary' onClick={handleLogin}>
          登录
        </Button>
      </Fragment>
    );
  }

  function bindOrlogin(openId: any,name:any) {
    setWechatOpenId(openId)
    setWechatNickName(name)
  }

  return (
    <div id={style.loginPage}>
      <div className={style.loginBg}>
        <img src='https://image.xiaomaiketang.com/xm/YS55f334WB.png' alt='' />
      </div>
      <div className={style.loginWrap}>
        <div className={style.leftTopBlock}></div>
        <div className={style.rightBottomBlock}></div>
        <div className={style.loginContainer}>
          <img className={style.logo} src='https://image.xiaomaiketang.com/xm/J73mhn6hFb.png' alt='' />
          <div className={style.line}></div>
          <div className={style.tabsWrap}>
            <div
              className={cls(
                style.tabName,
                loginType === 'WX' ? style.active : ''
              )}
              onClick={() => setLoginTpe('WX')}
            >
              微信登录
            </div>
            <div
              className={cls(
                style.tabName,
                loginType === 'PHONE' ? style.active : ''
              )}
              onClick={() => setLoginTpe('PHONE')}
            >
              手机号登录
            </div>
          </div>
          {loginType === 'PHONE' && renderPhoneLogin()}
          {loginType === 'WX' && (!!wechatOpenId ? <div>
            {renderPhoneLogin()}
            <div className={style.wechat123} onClick={()=>{setWechatOpenId(null)}}> <span>微信扫码登录</span> </div>
            </div> : <BindWechat bindOrlogin={bindOrlogin} href={href} style={{ width: 144, height: 165, marginTop: 12 }} success={() => { }} text={'登录'} type={'login'} />)}
        </div>
      </div>
    </div>
  );
}

export default Login;
