import Service from '@/common/js/service';

export function saveEduCategory(params: object): Promise<any> {
    return Service.Public('eduCategory/saveEduCategory', params);
}

export function editEduCategory(params: object): Promise<any> {
    return Service.Public('eduCategory/editEduCategory', params);
}

export function getEduCategoryTreeList(params?: object): Promise<any> {
    return Service.Public('eduCategory/getEduCategoryTreeList', params || {});
}

export function delEduCategory(params: object): Promise<any> {
    return Service.Public('eduCategory/delEduCategory', params);
}

export function moveEduCategory(params: object): Promise<any> {
    return Service.Public('eduCategory/moveEduCategory', params);
}
