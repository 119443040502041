/*
 * @Author: 吴文洁
 * @Date: 2020-08-11 11:47:14
 * @LastEditors: renmanyi
 * @LastEditTime: 2021-09-10 15:52:26
 * @Description:
 * @Copyright: 杭州杰竞科技有限公司 版权所有
 */

import service from './service'

class Upload {
  static uploadBlobToOSS(Blob, name, dir, dataType = 'url') {
    // 上传图片和视频
    console.log(name,'name')
    return service
      .MFS('anon/mfs/webTokenWithAccessUrl', {
        resourceName: name,
        instId: '1000000000000000000'
      })
      .then((res) => {
        const signInfo = res.result;
        const { url } = res.result;
        return this.uploadBlobToNewOSS(Blob, name, dir, signInfo.signatureVO || signInfo).then(() => {
          return dataType === 'url' ? url : signInfo;
        });
      });
  }
  static uploadBlobToOSSId(Blob, name, dir, dataType = 'url') {
    // 上传图片和视频   回传资源id
    return service
      .MFS('anon/mfs/webTokenWithAccessUrl', {
        resourceName: name,
        instId: '1000000000000000000'
      })
      .then((res) => {
        const signInfo = res.result;
        const { resourceId } = res.result;
        return this.uploadBlobToNewOSS(Blob, name, dir, signInfo.signatureVO || signInfo).then(() => {
          return dataType === 'url' ? resourceId : signInfo;
        });
      });
  }
  static asyncUploadVideoToOSS(Blob, name, complete, dir, needSize) {
    name = window.encodeURI(name.toLowerCase());
    let ossSignServerAddress = UPLOAD + 'xm/oss/web/token?bucket=v';
    const xhr = new XMLHttpRequest();
    xhr.open('GET', ossSignServerAddress, true);
    xhr.onload = () => {
      const signInfo = JSON.parse(xhr.responseText);
      const fd = new FormData();

      fd.append('Filename', name);
      fd.append('callback', signInfo.callback);
      fd.append('expire', signInfo.expire);
      fd.append('policy', signInfo.policy);
      fd.append('signature', signInfo.signature);
      fd.append('OSSAccessKeyId', signInfo.accessid);
      fd.append('success_action_status', 200);
      if (!dir) {
        dir = Blob.type == 'text/html' ? 'html/' : '';
      }
      fd.append('key', signInfo.dir + dir + name);
      fd.append('file', Blob);

      xhr.open('POST', signInfo.host, true);
      xhr.onload = () => {
        const result = JSON.parse(xhr.responseText);

        if (needSize) {
          complete(result);
        } else {
          complete((result.url || false).replace(/http:/, 'https:'));
        }
      };
      xhr.send(fd);
    };
    xhr.onerror = () => {
      complete(false);
    };
    xhr.send(null);
    return xhr;
  }

  static getVideoParseRoute(videoUrl) {
    return service.MFS('anon/video/parse', { videoUrl }).then((res) => {
      return res.result.sdUrl || videoUrl;
    });
  }

  // mfs上传文件

  static uploadBlobToMFSOSS(signInfoUrl, signInfoParams, Blob, name, dir) {
    return service.postJSON(signInfoUrl, signInfoParams).then((res) => {
      var signInfo = res.result;
      return this.uploadBlobToNewOSS(Blob, name, dir, signInfo.signatureVO || signInfo).then((res) => {
        return signInfo;
      });
    });
  }

  static uploadBlobToNewOSS(Blob, name, dir, signInfo) {
    console.log(signInfo, 'sign');
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      var fd = new FormData();
      fd.append('OSSAccessKeyId', signInfo.accessId);
      fd.append('policy', signInfo.policy);
      fd.append('callback', signInfo.callback);
      fd.append('Filename', name);
      fd.append('expire', signInfo.expire);
      fd.append('signature', signInfo.signature);
      fd.append('key', signInfo.key);
      fd.append('file', Blob);
      fd.append('success_action_status', 200);
      xhr.open('POST', signInfo.host.replace(/http:/, 'https:'), true);
      xhr.onload = () => {
        const result = JSON.parse(xhr.responseText);
        resolve(result.url, signInfo.resourceId);
      };
      xhr.send(fd);
    });
  }

  // 监听多媒体上传进度
  static uploadToOSSEvent(Blob, name, onInit = () => { }, onProgress = () => { }, onLoad = () => { }, onError = () => { }) {
    service
      .MFS('anon/mfs/webTokenWithAccessUrl', {
        resourceName: name,
        instId: ''
      })
      .then((res) => {
        const signInfo = res.result;
        const { url } = res.result;
        const xhr = new XMLHttpRequest();
        const fd = new FormData();

        fd.append('OSSAccessKeyId', signInfo.accessId);
        fd.append('policy', signInfo.policy);
        fd.append('callback', signInfo.callback);
        fd.append('Filename', name);
        fd.append('expire', signInfo.expire);
        fd.append('signature', signInfo.signature);
        fd.append('key', signInfo.key);
        fd.append('file', Blob);
        fd.append('success_action_status', 200);

        onInit(url, xhr, signInfo);
        xhr.open('POST', signInfo.host);
        xhr.upload.addEventListener('progress', onProgress, false);
        xhr.onload = onLoad;
        xhr.onerror = onError;
        xhr.send(fd);
      });
  }
}

export default Upload;
