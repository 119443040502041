/*
 * @Author: liuqing
 * @Date: 2021-10-13 14:07:31
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-18 10:52:03
 * @Description: 分类下拉选择
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useState, useEffect, CSSProperties } from 'react';
import { TreeSelect, TreeSelectProps } from 'antd';
import { getEduCategoryTreeList } from '@/data-source/category';
import _ from 'underscore';

interface ICategorySelectProps extends TreeSelectProps<any> {
  width?: number | string;
  style?: CSSProperties;
  className?: string;
  showLabel?: boolean;
}

function CategorySelect(props: ICategorySelectProps) {
  const {
    width = '100%',
    style,
    className = '',
    showLabel = false,
    ...restProps
  } = props;

  const [categoryList, setCategoryList] = useState<any>([]);

  useEffect(() => {
    getCategoryData();
  }, []);

  function getCategoryData(categoryName?: string) {
    const mappedChildren = (children: any[]) => {
      return children.map((item: any) => {
        if (item.children && item.children.length > 0) {
          item.children = mappedChildren(item.children);
        }
        return {
          title: item.categoryName,
          value: item.id,
          key: item.id,
          children: item.children,
          disabled: item.id === '-1'
        };
      });
    };
    getEduCategoryTreeList({
      categoryName,
    }).then(res => {
      const result = res.result || [];
      const data = mappedChildren(result);
      setCategoryList(data);
    });
  }

  function handleSearch(value: string) {
    getCategoryData(value);
  }

  return (
    <div className={className}>
      {showLabel && <label>分类：</label>}
      <TreeSelect
        {...restProps}
        style={{ width: width, ...style }}
        treeData={categoryList}
        allowClear
        showSearch
        placeholder='请选择分类'
        getPopupContainer={trigger => trigger.parentElement}
        onSearch={_.debounce(handleSearch, 200)}
      />
    </div>
  );
}

export default CategorySelect;
