import React, { useEffect, useState, useImperativeHandle } from "react";
import { Input } from "antd";
import "./map.less";
let map = {};
let geocoder = {};

function GaodeMap({ width, height, edit, mapRef, disabled, changeAddress }) {
  const [showSelect, setShowSelect] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [keyWord, setKeyWord] = useState("");

  useEffect(() => {
    map = new AMap.Map('allmap', {
      zoom: 15,
    });
    geocoder = new AMap.Geocoder({
      city: "010", //城市设为北京，默认：“全国”
      radius: 1000 //范围，默认：500
    })
    if (!edit) {
      // 当前浏览器定位
      AMap.plugin('AMap.Geolocation', function () {
        var geolocation = new AMap.Geolocation({
          zoomToAccuracy: true,   //定位成功后是否自动调整地图视野到定位点
          showMarker: true,
          buttonPosition: 'RB',
        });
        map.addControl(geolocation);
        geolocation.getCurrentPosition(function (status, result) {
          if (status == 'complete') {
            map.panTo([result.position.lng, result.position.lat]);
          } else {
            console.log("浏览器定位失败");
          }
        });
      });
    }
    map.on("click", clickMapMarker);


  }, []);

  useImperativeHandle(mapRef, () => ({
    initialAddress
  }));



  // 地图初始化
  function initialAddress(lng, lat) {
    map.panTo([lng, lat]);
    getAddress(lng, lat, false);
  }

  // 地图点击监听
  function clickMapMarker(e) {
    getAddress(e.lnglat.getLng(), e.lnglat.getLat());
  }
  // 根据经纬度获取坐标
  function getAddress(lng, lat, type = true) {
    geocoder.getAddress(`${lng},${lat}`, function (status, result) {
      // console.log( result.regeocode.addressComponent,' result.regeocode.addressComponent')
      const { province, city, district, township, street, streetNumber,adcode } = result.regeocode.addressComponent || {};
      if (status === "complete" && result.regeocode) {
        const address = {
          longitude: lng,
          latitude: lat,
          province,
          city,
          province,
          district,
          township,
          street,
          streetNumber,
          adcode
        }
        changeAddress(address, type);

        map.clearMap();
        const marker = new AMap.Marker({
          icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
          position: [lng, lat]
        });
        map.add(marker);

        if (township.length) {
          marker.setLabel({
            offset: new AMap.Pixel(20, 20),  //设置文本标注偏移量
            content: `<div class='info'>${township}${street}${streetNumber}</div> `, //设置文本标注内容
            direction: 'right' //设置文本标注方位
          });
        }
      }
    });
  }
  return (
    <div className="map">
      {
        disabled &&
        <div
          className="mask"
          style={{ width: width, height: height }}></div>
      }
      <div
        id="allmap"
        style={{ width: width, height: height }}></div>
      <div className="container">
        <div id="r-result">
          <Input
            placeholder="请搜索地址"
            id="keyWord"
            style={{ width: 250 }}
            value={keyWord}
            onFocus={() => {
              setShowSelect(true);
            }}
            onChange={(e) => {
              AMap.plugin('AMap.Autocomplete', function () {
                var autoOptions = {
                  city: '全国'
                }
                var autoComplete = new AMap.Autocomplete(autoOptions);
                autoComplete.search(e.target.value, function (status, result) {
                  // 搜索成功时，result即是对应的匹配数据
                  if (status === "complete") {
                    let list = [...result.tips];
                    list = list.filter(item => {
                      return item.location;
                    });
                    setAddressList(list);

                  } else {
                    setAddressList([]);
                  }

                });
              });
              setKeyWord(e.target.value);
            }}
            size="20"
            disabled={disabled}
          />
        </div>
        <div
          id="searchResultPanel"
          style={{
            height: "auto",
            display: `${showSelect ? "block" : "none"}`,
          }}
        >
          {

            addressList.length ?
              addressList.map((item, index) => {
                return (
                  <div
                    className="address-item"
                    key={index}
                    onClick={() => {
                      map.panTo([item.location.lng, item.location.lat]);
                      setAddressList([]);
                      setShowSelect(false);
                      setKeyWord("");

                      getAddress(item.location.lng, item.location.lat);

                    }}
                  >
                    <span className="title">{item.name}</span>
                    <span className="ml4 sub-title">
                      {item.district}
                    </span>
                  </div>
                )
              })
              : <div className="no-list">
                <img src="https://image.xiaomaiketang.com/xm/RwJ7BSFECm.png"></img>
                <p>暂无数据</p>
              </div>
          }
        </div>
      </div>

    </div>
  )
}

export default GaodeMap;