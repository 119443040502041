/*
 * @Author: liuqing
 * @Date: 2021-09-23 10:35:42
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-15 10:47:09
 * @Description:
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useState, useContext } from 'react';
import { Modal, Form, Input, Radio, message } from 'antd';
import style from './AddModal.module.less';
import md5 from 'md5';
import cls from 'classnames';
import {
  editEduAdmin,
  getAdminDetailById,
  saveEduAdmin,
} from '@/data-source/admin';
import { XMStaticSelect } from '@/components';
import { getEduTenantList } from '@/data-source/school';
import XMCropper from '@/components/cropper';
import { AppContext } from '@/store';

interface IAddModalProps {
  isEdit?: boolean;
  id?: string | number;
  onCancel: () => void;
  onOk: () => void;
}

function AddModal(props: IAddModalProps) {
  const { isEdit, id, onCancel, onOk } = props;
  const [pwdDisable, setPwdDisable] = useState(isEdit);
  const [originalPwd, setOriginalPwd] = useState('');
  const [schoolList, setSchoolList] = useState([]);
  const [avatar, setAvatar] = useState('');
  const [form] = Form.useForm();
  const [showSchool, setShowSchool] = useState(true);
  const { state } = useContext(AppContext);
  const { userInfo = {} } = state;

  useEffect(() => {
    getSchoolList();
  }, []);

  useEffect(() => {
    if (isEdit) {
      getAdminDetailById(id!).then(res => {
        const {
          account,
          accountType = 'BUREAU',
          avatar,
          name,
          password,
          tenantId,
        } = res.result;
        form.setFieldsValue({
          account,
          accountType,
          name,
          password: '******',
          tenantId,
        });
        setAvatar(avatar);
        setOriginalPwd(password);
        setShowSchool(accountType === 'ACADEMIC');
      });
    }
  }, [isEdit]);

  function getSchoolList() {
    getEduTenantList().then(res => {
      setSchoolList(res.result || []);
    });
  }

  function handleFormChange(changedValues: any, allValues: any) {
    if (changedValues.account !== undefined) {
      form.setFieldsValue({
        account: changedValues.account.replace(/[^0-9]/g, ''),
      });
    }
    if (changedValues.accountType !== undefined) {
      form.setFieldsValue({
        tenantId: undefined,
      });
      setShowSchool(changedValues.accountType === 'ACADEMIC');
    }
  }

  function request(params: any) {
    if (isEdit) {
      params.id = id;
      params.password = pwdDisable
        ? originalPwd
        : md5(form.getFieldValue('password'));
      return editEduAdmin(params);
    } else {
      return saveEduAdmin(params);
    }
  }

  function handleSave() {
    form.validateFields().then(values => {
      const params = {
        ...values,
        avatar,
        password: md5(values.password),
      };

      request(params).then(() => {
        message.success('保存成功');
        onOk && onOk();
      });
    });
  }

  return (
    <Modal
      title={isEdit ? '编辑用户' : '添加用户'}
      centered
      width={680}
      visible={true}
      wrapClassName={style.userModal}
      okText='保存'
      onCancel={onCancel}
      onOk={handleSave}
    >
      <Form
        labelCol={{ span: 4 }}
        form={form}
        autoComplete='off'
        initialValues={{}}
        onValuesChange={handleFormChange}
      >
        <Form.Item label='真实姓名' name='name' rules={[{ required: true }]}>
          <Input
            className={style.input}
            placeholder='请输入真实姓名，10字以内'
            maxLength={10}
          />
        </Form.Item>
        <Form.Item
          label='手机号'
          name='account'
          rules={[
            {
              required: true,
              len: 11,
            },
          ]}
        >
          <Input
            className={style.input}
            placeholder='请输入手机号'
            maxLength={11}
          />
        </Form.Item>
        <div className={style.pwdFormItem}>
          <Form.Item
            label='密码'
            name='password'
            initialValue='123456'
            rules={[{ required: true, len: 6 }]}
          >
            <Input
              className={style.input}
              placeholder='请输入密码'
              maxLength={6}
              disabled={pwdDisable}
            />
          </Form.Item>
          {isEdit && (
            <span
              className={cls('a-link', style.resetPwdBtn)}
              onClick={() => {
                setPwdDisable(false);
                form.setFieldsValue({
                  password: '123456',
                });
              }}
            >
              重置
            </span>
          )}
        </div>
        <Form.Item
          className={style.radioLeftLabel}
          label='账号类型'
          initialValue={isEdit ? undefined : 'ACADEMIC'}
          name='accountType'
          rules={[{ required: true }]}
        >
          {/* 管理员不能编辑自己 */}
          <Radio.Group disabled={userInfo.id === id}>
            <Radio value='BUREAU'>管理员</Radio>
            <div className={style.radioTip}>
              可登录管理后台，拥有管理校区权限
            </div>
            <Radio value='ACADEMIC'>教务人员</Radio>
            <div className={style.radioTip}>可操作单校区工作台</div>
          </Radio.Group>
        </Form.Item>
        {showSchool && (
          <Form.Item
            label='所属学校'
            name='tenantId'
            rules={[{ required: true, message: '请选择学校' }]}
          >
            <XMStaticSelect
              className={style.input}
              placeholder='请选择学校'
              dataSet={schoolList}
            />
          </Form.Item>
        )}
      </Form>
      <div className={style.avatarBox}>
        <XMCropper
          changeurl={(url: any) => {
            setAvatar(url);
          }}
          title='设置头像'
          url={avatar || 'https://image.xiaomaiketang.com/xm/rJeQaZxtc7.png'}
        />
      </div>
    </Modal>
  );
}

export default AddModal;
