import React, { useEffect, useState, useRef } from 'react';
import { bindQrUrl, loginOrBind, authQrUrl, bindWechat } from '@/data-source/login';
import User from '@/common/js/user';
import RCHistory from '@/routes/history';
import './index.less'
declare var location: any;
declare var WxLogin: any


function Index(props: any) {
    const [url, setUrl] = useState(null);
    const timer = useRef<any>(null);

    useEffect(() => {
        localStorage.removeItem('wx_code');
        localStorage.removeItem('wx_state');
        window.addEventListener('storage', storageChange);
        return () => {
            window.removeEventListener('storage', storageChange);
        }
    }, [])

    function storageChange(e: any) {
        if (e.key == 'wx_code' &&e.newValue&& e.oldValue != e.newValue) {
            getBindUrl();
            (props.type === 'login' ? loginOrBind : bindWechat)({
                code: localStorage.getItem('wx_code'),
                state: localStorage.getItem('wx_state')
            }).then((res: any) => {

                if (props.type === 'login') {
                    const {
                        account,
                        id,
                        name,
                        path,
                        tenantId,
                        tenantName,
                        wechatOpenId,
                        xmToken,
                    } = res.result;
                    User.account = account;
                    User.uid = id;
                    User.name = name;
                    User.path = path;
                    User.tenantId = tenantId;
                    User.tenantName = tenantName;
                    User.wechatOpenId = wechatOpenId;
                    User.token = xmToken;
                    RCHistory.push('/');
                }
                props.success()
            }).catch((res) => {
                localStorage.removeItem('wx_code');
                localStorage.removeItem('wx_state');
                if (res.code === 'WECHAT_NOT_BIND') {
                    if (props.bindOrlogin) {
                        props.bindOrlogin(res.result.wechatOpenId,res.result.wechatNickName)
                    }
                }

            })

        }
    }

    function getParameterByName(name: any, url: any) {
        name = name.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
        const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
        const realUrl = url || location.href;
        const results = regex.exec(realUrl);
        return results === null
            ? ""
            : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    useEffect(() => {
        getBindUrl()
        timer.current = setInterval(() => {
            getBindUrl()
        }, 3000000)

        return () => {
            clearInterval(timer.current)
        }

    }, [])

    useEffect(() => {
        console.log(45678)
        if (url) {
            console.log('tyujhgfgbhnm,')
            const params = {
                self_redirect: true,
                id: "wx_login",
                appid: getParameterByName('appid', url),
                scope: "snsapi_login",
                redirect_uri: encodeURIComponent(getParameterByName('redirect_uri', url)),
                state: getParameterByName('state', url),
                style: "black",
                href: props.href || "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIxOHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAuaWNvbjM4X21zZyB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30="
            }
            new WxLogin(params);

        }
    }, [url])

    function getBindUrl() {
       
        (props.type === 'login' ? authQrUrl : bindQrUrl)({
            redirectUri: (location.origin + location.pathname).replace('index.html', '') + 'wechat.html',
            userId: User.uid
        }).then((res) => {
            setUrl(res.result)
        })
    }

    return <div className="wechatModal" >
        <div style={props.style} id="wx_login" ></div>
        <img src="https://image.xiaomaiketang.com/xm/J73mhn6hFb.png" alt="" />
        <p className='text'>请使用微信扫码{props.text}</p>
        {/* 21345 */}
    </div>
}

export default Index
