/*
 * @Author: liuqing
 * @Date: 2021-09-28 11:14:09
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-08 11:07:48
 * @Description: 
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
export const STORE_PERMISSION = 'STORE_PERMISSION'; // 权限
export const STORE_USER_INFO = 'STORE_USER_INFO'; // 用户信息
