/*
 * @Author: liuqing
 * @Date: 2021-09-29 13:51:01
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-16 16:54:57
 * @Description:
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { Button, Checkbox } from 'antd';
import { XMSearchInput } from '@/components';
import style from './SchoolSelect.module.less';
import { getEduTenantList } from '@/data-source/school';
import _ from 'underscore';

interface ISchoolSelectProps {
  visible?: boolean;
  selectedList?: any[];
  disabledList?: string[];
  onCancel?: () => void;
  onOk?: (ids: any[], selectedList: any[]) => void;
}

function SchoolSelect(props: ISchoolSelectProps) {
  const {
    visible,
    selectedList = [],
    disabledList = [],
    onCancel,
    onOk,
  } = props;

  const [schoolList, setSchoolList] = useState([]);
  const [allSchoolList, setAllSchoolList] = useState([]);
  const [schoolIds, setSchoolIds] = useState<any[]>([]);
  const [schoolName, setSchoolName] = useState<string>('');

  useEffect(() => {
    setSchoolIds(selectedList.map((item: any) => item.id));
  }, [selectedList]);

  useEffect(() => {
    getSchoolList();
  }, [schoolName]);

  function getSchoolList() {
    getEduTenantList(schoolName).then(res => {
      if (!schoolName) {
        // 这里保存的是所有学校列表
        setAllSchoolList(res.result || []);
      }
      setSchoolList(res.result || []);
    });
  }

  function handleOk() {
    const selectedList = allSchoolList.filter((item: any) =>
      schoolIds.includes(item.id)
    );
    onOk && onOk(schoolIds, selectedList);
  }

  return (
    <div
      className={cls(
        style.schoolSelectWrap,
        visible ? style.open : style.hidden
      )}
    >
      <XMSearchInput
        className={style.input}
        placeholder='搜索学校名称'
        width={272}
        value={schoolName}
        onChange={_.debounce((e: any) => setSchoolName(e.target.value), 200)}
        onSearch={value => {
          setSchoolName(value);
        }}
      />
      <div className={style.main}>
        <Checkbox.Group
          value={schoolIds}
          onChange={values => setSchoolIds(values)}
        >
          {allSchoolList.map((item: any) => (
            <div
              className={style.listItem}
              key={item.id}
              style={{
                display: schoolList.map((sch: any) => sch.id).includes(item.id)
                  ? 'block'
                  : 'none',
              }}
            >
              <Checkbox
                value={item.id}
                disabled={disabledList.includes(item.id)}
              >
                {item.name}
              </Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      </div>
      <div className={style.footer}>
        <Button className='mr8' size='small' onClick={onCancel}>
          取消
        </Button>
        <Button size='small' type='primary' onClick={handleOk}>
          确定
        </Button>
      </div>
    </div>
  );
}

export default SchoolSelect;
