/*
 * @Author: liuqing
 * @Date: 2021-09-28 10:56:58
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-09 10:44:12
 * @Description: 状态管理
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useReducer } from 'react';
import { AppContext as Context } from '@/store';
import App from './App';
import baseReducer from '@/store/reducers';

function AppContext() {
  const [state, dispatch] = useReducer(baseReducer, {userInfo:{}});

  return (
    <Context.Provider value={{ state, dispatch }}>
      <App />
    </Context.Provider>
  );
}

export default AppContext;
