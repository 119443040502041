/*
 * @Author: 吴文洁
 * @Date: 2020-04-27 20:35:34
 * @LastEditors: liuqing
 * @LastEditTime: 2021-09-27 14:39:49
 * @Description:
 */

import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import 'moment/locale/zh-cn';
import 'antd/dist/antd.less';
import '@/common/less/index.less';

import RootRouter from './routes';

moment.locale('zh-cn');

ReactDOM.render(<RootRouter />, document.getElementById('root'));
