/*
 * @Author: liuqing
 * @Date: 2021-10-14 11:31:10
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-20 10:04:59
 * @Description: 考核表详情页
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useState } from 'react';
import { useRouteMatch, withRouter } from 'react-router';
import { Page, XMEllipsis, XMTable } from '@/components';
import { TableColumnsType } from 'antd';
import style from './ExamineDetail.module.less';

import { getAssessTableDetail, getEduTaskByPage } from '@/data-source/assess';
import { formatDate } from '@/domains/basic-domain/utils';
import RCHistory from '@/routes/history';
import { SorterResult } from 'antd/lib/table/interface';

function ExamineDetail() {
  const match = useRouteMatch();
  const id = (match.params as any).id;
  const [detail, setDetail] = useState<any>({});

  const {
    name,
    categoryName,
    created,
    itemCount = 0,
    standardCount = 0,
    totalScore = 0,
    taskCount = 0,
    standardTaskCount = 0,
    standardType,
    standardSetting,
  } = detail;

  const [query, setQuery] = useState<any>({
    current: 1,
    assessTableId: id,
  });
  const [total, setTotal] = useState(0);
  const [taskList, setTaskList] = useState([]);

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    getTableData();
  }, [query]);

  function getDetail() {
    getAssessTableDetail(id).then(res => {
      setDetail(res.result || {});
    });
  }

  function getTableData() {
    getEduTaskByPage(query).then(res => {
      const { records = [], total = 0 } = res.result || {};
      setTaskList(records);
      setTotal(total);
    });
  }

  const columns: TableColumnsType = [
    {
      key: 'tenantName',
      dataIndex: 'tenantName',
      title: '学校名称',
      render: value => <XMEllipsis text={value} length={8} />,
    },
    {
      key: 'created',
      dataIndex: 'created',
      title: '创建时间',
      sorter: true,
      render: value => formatDate(value),
    },
    {
      key: 'startDate',
      dataIndex: 'startDate',
      title: '申报时限',
      render: (value, record: any) =>
        `${formatDate(value)}～${formatDate(record.endDate)}`,
    },
    {
      key: 'standardStatus',
      dataIndex: 'standardStatus',
      title: '达标状态',
      render: value => {
        if (value === undefined) {
          return '-';
        }
        if (value) {
          return (
            <span
              className={style.standardStatus}
              style={{
                borderColor: '#3BBDAA',
                color: '#3BBDAA',
              }}
            >
              已达标
            </span>
          );
        } else {
          return (
            <span
              className={style.standardStatus}
              style={{
                borderColor: '#FF4F4F',
                color: '#FF4F4F',
              }}
            >
              未达标
            </span>
          );
        }
      },
    },
    {
      key: 'declaredCount',
      dataIndex: 'declaredCount',
      title: '申报项数/总项数',
      render: (value, record: any) => {
        return `${value || 0}/${record.itemCount || 0}`;
      },
    },
    {
      key: 'score',
      dataIndex: 'score',
      title: '得分/总分',
      render: (value, record: any) => {
        return `${value || 0}/${record.totalScore || 0}`;
      },
    },
    {
      key: 'operation',
      dataIndex: 'operation',
      title: '操作',
      render: (value, record: any) => {
        return (
          <span
            className='a-link'
            onClick={() => {
              RCHistory.push(`/sch/examine/detail/${record.id}?from=edu`);
            }}
          >
            查看数据
          </span>
        );
      },
    },
  ];

  return (
    <Page breadcrumb='考核表详情页'>
      <div className='page__item__title'>
        <span className='page__subtitle'>{name}</span>
      </div>
      <div className={style.info}>
        <div className={style.infoItem} style={{ marginRight: 24 }}>
          分类：{categoryName}
        </div>
        <div className={style.infoItem}>创建时间：{formatDate(created)}</div>
      </div>
      <div className={style.summaryWrap}>
        <div className={style.summaryBox}>
          <div className={style.value}>{itemCount}</div>
          <div className={style.name}>考核项</div>
        </div>
        {standardSetting && (
          <Choose>
            <When condition={standardType === 'SCORE'}>
              <div className={style.summaryBox}>
                <div className={style.value}>
                  {standardCount}/{totalScore}
                </div>
                <div className={style.name}>达标分/总分</div>
              </div>
            </When>
            <Otherwise>
              <div className={style.summaryBox}>
                <div className={style.value}>
                  {standardCount}/{itemCount}
                </div>
                <div className={style.name}>达标项/总项</div>
              </div>
            </Otherwise>
          </Choose>
        )}
        <div className={style.summaryBox}>
          <div className={style.value}>{taskCount}</div>
          <div className={style.name}>已指派数量</div>
        </div>
        <div className={style.summaryBox}>
          <div className={style.value}>
            {standardSetting ? standardTaskCount : '-'}
          </div>
          <div className={style.name}>达标数量</div>
        </div>
      </div>
      <XMTable
        rowKey='id'
        columns={columns}
        dataSource={taskList}
        total={total}
        current={query.current}
        onChange={(_, __, sorter: SorterResult<any> | SorterResult<any>[]) => {
          if ((sorter as SorterResult<any>).order) {
            setQuery({
              ...query,
              sortField: 'CREATED',
              sortType:
                (sorter as SorterResult<any>).order === 'ascend'
                  ? 'SORT_ASC'
                  : 'SORT_DESC',
            });
          } else {
            setQuery({
              current: query.current,
              assessTableId: id,
            });
          }
        }}
        onPageChange={page => {
          setQuery({
            ...query,
            current: page,
          });
        }}
      />
  
    </Page>
  );
}

export default withRouter(ExamineDetail);
