/*
 * @Author: yuananting
 * @Date: 2021-02-22 17:51:28
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-18 11:25:20
 * @Description: 助学工具-新建编辑课程分类模态框
 * @Copyrigh: © 2020 杭州杰竞科技有限公司 版权所有
 */
import React, { Component } from "react";
import { Modal, Form, Input, message } from "antd";
import User from '@/common/js/user';
import { saveEduCategory, getEduCategoryTreeList, editEduCategory } from '@/data-source/category';

class NewEditCourseCategoryModal extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      categoryName:
        this.props.type === "edit" ? this.props.node.categoryName : null,
    };
  }

  componentDidMount() {
    console.log(this.props)
    this.queryCategoryTree();
  }

  // 查询分类树
  queryCategoryTree = () => {
    let query = {
      tenantId: User.tenantId
    };
    getEduCategoryTreeList(query)
  };

  // 确定新增或编辑
  confirmOperate = async () => {
    const { categoryName } = this.state;
    if (!categoryName) {
      return message.warning('请填写分类名称')
    }
    const { node, addLevelType, type } = this.props;
    let params = {
      tenantId: User.tenantId,
      categoryName,
      parentId: '0'

    };
    console.log(this.props.node)
    if (type === "new") {
      if(this.props.node){
        params.parentId =addLevelType==='equal'?this.props.node.parentId: this.props.node.id
      }
      saveEduCategory( params ).then(() => {
        message.success('添加成功')
        this.props.close();
      })
    } else {
      params.id =this.props.node.id;
      editEduCategory(params).then(() => {
        message.success('编辑成功')
        this.props.close();
      })
    }
  };

  // 校验是否重名
  checkExist = (sameLevelNodes, categoryName) => {
    if (sameLevelNodes.length > 0 && sameLevelNodes[0].parentId === "0") {
      if (categoryName === "未分类") {
        return true;
      }
    }
    var result = null;
    sameLevelNodes.forEach((item) => {
      if (result != null) {
        return result;
      }
      if (item.categoryName === categoryName) {
        result = item;
      }
    });
    return result;
  };

  // 查询同级节点
  getEqualLevelNodes = (data, parentId) => {
    let nodes = [];
    data.forEach((item) => {
      if (item.parentId === parentId) {
        nodes.push(item);
      }
      if (item.sonCategoryList) {
        nodes.push(...this.getEqualLevelNodes(item.sonCategoryList, parentId));
      }
    });
    return nodes;
  };

  // 查询子级节点
  getChildLevelNodes = (data, id) => {
    let nodes = [];
    data.forEach((item) => {
      if (item.id === id && item.sonCategoryList) {
        nodes.push(...item.sonCategoryList);
      }
      if (item.sonCategoryList) {
        nodes.push(...this.getChildLevelNodes(item.sonCategoryList, id));
      }
    });
    return nodes;
  };

  getSameLevelNodes = (data, type) => {
    let sameLevelNodes = [];
    if (type === "equal") {
      let parentId = this.props.node ? this.props.node.parentId : "0";
      sameLevelNodes = this.getEqualLevelNodes(data, parentId);
    } else {
      sameLevelNodes = this.getChildLevelNodes(data, this.props.node.id);
    }
    return sameLevelNodes;
  };

  render() {
    const { title, label, addLevelType } = this.props;
    const { categoryName, treeData } = this.state;
    const _that = this;
    return (
      <Modal
        visible={true}
        title={title}
        onOk={this.confirmOperate}
        onCancel={() => this.props.close()}
      >
        <Form ref={this.formRef}>
          <Form.Item
            name="categoryName"
            label={label}
            required
            rules={[
              {
                required: true,
                message: `请输入${label}`,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  let sameLevelNodes = _that.getSameLevelNodes(
                    treeData,
                    addLevelType
                  );
                  if (_that.checkExist(sameLevelNodes, value)) {
                    return Promise.reject("此分类名称已存在");
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              defaultValue={categoryName}
              placeholder={`请输入${label}，最多10个字`}
              maxLength={10}
              autoComplete="off"
              onChange={(e) => {
                this.setState({
                  categoryName: e.target.value,
                });
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
export default NewEditCourseCategoryModal;
