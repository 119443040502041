/*
 * @Author: liuqing
 * @Date: 2021-10-20 16:32:11
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-20 17:25:34
 * @Description: 查看考核项详情
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useState } from 'react';
import { XMModal, XMTable } from '@/components';
import style from './AssessmentsDetailModal.module.less';
import { getAssessItemDetail } from '@/data-source/assess';
import { Form, TableColumnsType } from 'antd';
import { scoreUnitMap, TScoreUnit } from '@/common/js/enum';

interface IProps {
  id: string;
  onCancel: () => void;
}

export default function AssessmentsDetailModal({ id, onCancel }: IProps) {
  const [detail, setDetail] = useState<any>({});
  const {
    name,
    description,
    tagNames = [],
    scoreItems = [],
    calculateScore,
    totalPoint,
    declared,
    unit,
    uploadCert,
  } = detail;

  useEffect(() => {
    getDetail();
  }, []);

  function getDetail() {
    getAssessItemDetail(id).then(res => {
      setDetail(res.result || {});
    });
  }

  const columns: TableColumnsType = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '名称',
    },
    {
      key: 'limitCount',
      dataIndex: 'limitCount',
      title: '最大数量',
    },
    {
      key: 'itemScore',
      dataIndex: 'itemScore',
      title: <span>单项得分（{scoreUnitMap[unit as TScoreUnit]}）</span>,
    },
  ];

  return (
    <XMModal
      title='查看考核项'
      width={780}
      visible={true}
      footer={null}
      onCancel={onCancel}
    >
      <div className={style.headerBox}>
        <div className={style.name}>{name}</div>
        <div className={style.desc} dangerouslySetInnerHTML={{ __html: description }}></div>
        <div className={style.tag}>标签：{tagNames.join('/') || '-'}</div>
      </div>
      <Form labelCol={{ span: 4 }}>
        <Form.Item label='计分项'>
          {calculateScore ? (
            <>
              <XMTable rowKey='id' columns={columns} dataSource={scoreItems} />
              <div className={style.totalText}>总分：{totalPoint}</div>
            </>
          ) : (
            '关闭'
          )}
        </Form.Item>
        <Form.Item label='上传证明'>
          {uploadCert ? '必须上传' : '可选上传'}
        </Form.Item>
        <Form.Item label='申报详情'>{declared ? '必填' : '非必填'}</Form.Item>
      </Form>
    </XMModal>
  );
}
