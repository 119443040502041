import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Select, Button, message ,Modal} from 'antd';
import { AppContext } from '@/store';
import { Page } from '@/components';
import './userInfo.less'
import User from '@/common/js/user';
import ENUM from './enum'
import EditPhoneModal from './components/EditPhoneModel'
import EditPasswordModal from './components/EditPasswordModal'
import BindWechatModal from './components/BindWechatModal'
import XMCropper from '@/components/cropper';
import { setStorePermission, setStoreUserInfo } from '@/store/actions';

import { saveAdminSetting, getAdminFullDetailById, unBindWechat,getAdminDetailById } from '@/data-source/admin';
const { Option } = Select;

function UserInfo() {
    const ctx = useContext<any>(AppContext);
    const [userInfo, setUserInfo] = useState<any>({
        phone: 13606603546,
        userName: 'zhujian'
    })
    const [editPhone, setEditPhone] = useState(false)
    const [bindwechat, setBindwechat] = useState(false)
    const [editPassword, setEditPassword] = useState(false)
    const [hassubmit, setHasSbmit] = useState(false);

    useEffect(() => {
        setUserInfo(ctx.state.userInfo)
    }, [JSON.stringify(ctx.state.userInfo)])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 7 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 11 },
        },


    };

    function unBindWechatHandle() {
        Modal.confirm({
            icon: <span style={{color:'#FFB714'}}  className="iconfont modal-confirm-icon">&#xe8b0;</span>,
            title: '解除微信绑定',
            content: '确定要解除微信绑定',
            okText:'解除绑定',
            onOk: () => {
                unBindWechat({ id: userInfo.id,wechatOpenId:userInfo.wechatOpenId }).then(() => {
                    message.success('解绑成功');
                    getAdminFullDetail()
                })
            }
        })
    }

    function handleSaveInfo() {
        setHasSbmit(true)
        const { avatar, name, id } = userInfo;
        if (!name) {
            return
        }
        saveAdminSetting({
            avatar,
            name,
            id
        }).then(() => {
            message.success('保存成功')
            getAdminFullDetail()
        })
    }


    function getAdminFullDetail() {
        getAdminDetailById((User as any).uid).then(res => {
            ctx.dispatch(setStoreUserInfo(res.result));

        });
    }


    return <Page title='个人设置'>
        <div className="basicInfo userInfo">
            <div className="title">基本信息</div>
            <Form {...formItemLayout}>
                <div className="avatorBox">
                    <Form.Item
                        label="头像"
                    // validateStatus="error"
                    // help="Should be combination of numbers & alphabets"
                    >
                        <XMCropper changeurl={(url: any) => {
                            const info = { ...userInfo };
                            info.avatar = url;
                            console.log(info, '6789')
                            setUserInfo(info)
                            console.log(url, 'fghjbnm')
                        }} title='设置头像' url={userInfo.avatar || 'https://image.xiaomaiketang.com/xm/rJeQaZxtc7.png'} />
                        {/* <img className='a' src={userInfo.avator || 'https://image.xiaomaiketang.com/xm/rJeQaZxtc7.png'} alt="" /> */}
                    </Form.Item>
                </div>
                <Form.Item
                    required
                    label="姓名"
                    validateStatus={hassubmit && !userInfo.name ? "error" : undefined}
                    help={hassubmit && !userInfo.name ? "请输入姓名" : undefined}
                >
                    <Input value={userInfo.name} onChange={(e: any) => {
                        const info = { ...userInfo };
                        info.name = e.target.value;
                        setUserInfo(info)
                    }}></Input>
                </Form.Item>
                <Form.Item
                    required
                    label="账号类型"
                >
                    {
                        ENUM.userType[userInfo.accountType]
                    }


                </Form.Item>
                {
                    userInfo.accountType === 'ACADEMIC' && <Form.Item
                        required
                        label="所属学校"
                    >
                        {
                            userInfo.tenantName
                        }


                    </Form.Item>
                }
                <Button onClick={handleSaveInfo} style={{ marginLeft: 140 }} type='primary'> 保存</Button>
            </Form>
        </div>
        <div className="dataInfo userInfo" style={{ marginTop: 36 }}>
            <div className="title">安全信息</div>
            <Form {...formItemLayout}>
                <Form.Item
                    label="手机号"
                >
                    <div className="content">
                        {
                            userInfo.account
                        }
                        <div className="operate" onClick={() => {
                            setEditPhone(true)
                        }}>更换</div>
                    </div>


                </Form.Item>
                <Form.Item
                    label="登陆密码"
                >
                    <div className="content">
                        ******
                        <div onClick={() => {
                            setEditPassword(true)
                        }} className="operate">设置</div>
                    </div>


                </Form.Item>
                <Form.Item
                    label="微信绑定"
                >
                    <div className="content">
                        {userInfo.bindWechat ? '已绑定' : '未绑定'}
                        <div className="operate" onClick={() => {
                            userInfo.bindWechat ? unBindWechatHandle() : setBindwechat(true)
                        }}>{userInfo.bindWechat ? '解绑' : '绑定'}</div>
                    </div>


                </Form.Item>
            </Form>
        </div>
        {
            editPhone && <EditPhoneModal onClose={() => {
                setEditPhone(false)
            }} userInfo={userInfo}></EditPhoneModal>
        }

        {
            editPassword && <EditPasswordModal onClose={() => {
                setEditPassword(false)
            }} userInfo={userInfo}></EditPasswordModal>
        }

        {
            bindwechat && <BindWechatModal  close={() => {
                setBindwechat(false);
                getAdminFullDetail()
            }} userInfo={userInfo}></BindWechatModal >
        }



    </Page >
}

export default UserInfo
