/*
 * @Author: liuqing
 * @Date: 2021-09-18 15:08:20
 * @LastEditors: liuqing
 * @LastEditTime: 2021-09-18 15:17:41
 * @Description: React Component history
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import { createHashHistory, History } from 'history';

const RCHistory: History = createHashHistory();

export default RCHistory;
