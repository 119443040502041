/*
 * @Author: 吴文洁
 * @Date: 2020-08-31 09:34:31
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-20 14:11:29
 * @Description:
 * @Copyright: 杭州杰竞科技有限公司 版权所有
 */

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosPromise,
} from 'axios';
import { message } from 'antd';

import {
  BASIC_HOST,
  TIME_OUT,
  PROJECT,
} from '@/domains/basic-domain/constants';

import User from './user';
import RCHistory from '@/routes/history';

interface FetchOptions {
  requestType: string; // 请求类型  form为表单类型   json为json类型，默认json类型
}

class Axios {
  static post(
    method: string,
    url: string,
    params: any,
    options: FetchOptions = { requestType: 'json' }
  ): Promise<any> {
    const _url = `${url}?uid=${User.uid}&token=${User.token}`;
    return new Promise((resolve, reject) => {
      const instance: AxiosInstance = axios.create({
        timeout: TIME_OUT,
        responseType: 'json',
        headers: {
          product: PROJECT,
          xmToken: User.token,
          path: User.path,
          uid: User.uid,
          tenantId: User.tenantId,
          'Content-Type':
            options.requestType === 'json'
              ? 'application/json; charset=UTF-8'
              : 'application/x-www-form-urlencoded',
        },
      });

      if (method !== 'GET' && options.requestType === 'form') {
        instance.defaults.transformRequest = [
          (queryParam): string => {
            let ret: string = '';
            const queryKeys = Object.keys(queryParam);
            queryKeys.forEach((item: string, index: number): void => {
              if (index < queryKeys.length - 1) {
                ret += `${encodeURIComponent(item)}=${encodeURIComponent(
                  queryParam[item]
                )}&`;
              } else {
                ret += `${encodeURIComponent(item)}=${encodeURIComponent(
                  queryParam[item]
                )}`;
              }
            });
            ret.replace(/&$/, '');
            return ret;
          },
        ];
      }

      instance.interceptors.request.use(
        (config: AxiosRequestConfig): AxiosRequestConfig => {
          return config;
        },
        (error: Error): Promise<any> => {
          return Promise.reject(error);
        }
      );

      instance.interceptors.response.use(
        (response: AxiosResponse): AxiosResponse | AxiosPromise => {
          const {
            message: ResMessage,
            success,
            resultMsg,
            resultCode,
            code,
          } = response.data;
          if (success || resultCode === 0) {
            return response;
          }
          console.log(code,response.data)
          if (code === 'WECHAT_NOT_BIND') {
            return Promise.reject(response.data);
          }
         
          message.error(ResMessage || resultMsg);
          return Promise.reject(response.data);
        },
        (error): AxiosPromise => {
          const requestStatus = error.request.status;
          switch (requestStatus) {
            case 401:
              RCHistory.replace('/login');
              break;
            case 403:
              // message.error('暂无查看权限');
              RCHistory.replace('/login');
              break;
            case 500:
              message.error(error.response.data.message);
              break;
            default:
              message.error(error.message);
              break;
          }
          return Promise.reject(error.message);
        }
      );

      let config: any;
      if (method === 'GET') {
        config = Object.assign({ params, url: `${BASIC_HOST}${_url}`, method });
      } else {
        config = Object.assign({
          data: params,
          url: `${BASIC_HOST}${_url}`,
          method,
        });
      }

      instance(config)
        .then((res: AxiosResponse): void => {
          resolve(res.data);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  }
}

export default Axios;
