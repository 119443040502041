import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Input, Button, message, Popover } from 'antd';
import EditorBox from '@/components/Editor';
import './report.less'
import upload from '@/common/js/upload'
import { getReportItemDetail, reportItem } from '@/data-source/assess';
import { getBlob } from '@/domains/basic-domain/utils'
import ImageViver from '@/components/ImageViver';
import { FileTypeIcon } from '@/common/js/enum';
const { TextArea } = Input;

function ReportExamModal(props: any) {
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 21 },
        },
    };

    const [files, setFiles] = useState<any[]>([])
    const [hassubmit, setHasSbmit] = useState(false);
    const [info, setInfo] = useState<any>({});
    const [modal, setModal] = useState<any>(null)
    const [resInfo, setResInfo] = useState<any>({})
    const [hasInfo, setHasInfo] = useState<any>(false)
    const fileRef = useRef<any>(null)
    const fileListRef = useRef<any>(null)


    useEffect(() => {
        const { assessItemId, creatorId } = props.info;
        const { taskId } = props;
        getReportItemDetail({
            assessItemId,
            taskId
        }).then((res: any) => {

            setInfo(res.result);
            setFiles(res.result.confirm);
            setHasInfo(true)
        })
    }, [])

    useEffect(() => {
        console.log(1234)
        fileListRef.current = files;
    }, [files])

    useEffect(() => {
        console.log('scoreList')
        let score = 0;
        let num = 0;
        info.scoreList?.map((item: any) => {
            score += parseInt((item.reportCount || 0) * (item.itemScore || 0) as any);
            num += parseInt(item.reportCount) || 0;
        })
        console.log(score, num)
        setResInfo({ score, num })
    }, [info.scoreList])

    function handleUpload(e: any) {
        if (fileListRef.current.length === 10) {
            message.warning('最多上传10个文件')
            return
        }
        const file = fileRef.current.files && fileRef.current.files[0];
        if (file.size > 100 * 1024 * 1024) {
            message.warning('上传文件不可大于100M')
            return
        }
        console.log(file)
        upload.uploadBlobToOSS(file, new Date().valueOf() + file.name, null, 'object').then((signInfo: any) => {
            const { url, resourceId } = signInfo;
            const _files = [...fileListRef.current];
            _files.push({
                resourceId,
                url,
                resourceName: file.name,
                fileFormat: file.type.split('/')[1]
            })
            console.log(signInfo, JSON.stringify(_files));
            setFiles(_files)
        });

    }

    function handleSubmit() {
        const reportCountList = info.scoreList?.filter((item: any) => {
            return item.reportCount || item.reportCount === 0
        })
        if (info.calculateScore && !reportCountList.length) {
            message.warning('请填写申报数量')
            return
        }

        if (info.uploadCert && !files.length) {
            message.warning('请上传证明')
            return
        }

        if (info.declared && !info.detail) {
            message.warning('请填写申报证明')
            return
        }


        reportItem({
            ...info,
            confirm: files,
            taskId: props.taskId,
        }).then(() => {
            message.success('申报成功');
            props.success();
            props.close();

        })
    }

    function renderContent(item: any, index: any) {
        return <div className='editNameContent'>
            <div className="title">
                文件名称
            </div>
            <TextArea value={item._resourceName} onChange={(e: any) => {
                const _item = { ...item };
                _item._resourceName = e.target.value;
                const _files = [...files];
                _files[index] = _item;
                setFiles(_files)
            }} ></TextArea>
            <div className="foster">
                <Button
                    onClick={() => {
                        const _item = { ...item };
                        _item.visible = false;
                        const _files = [...files];
                        _files[index] = _item;
                        setFiles(_files)
                    }}
                    style={{ marginRight: 12 }}>取消</Button>
                <Button
                    onClick={() => {
                        const _item = { ...item };
                        _item.visible = false;
                        _item.resourceName = _item._resourceName;
                        const _files = [...files];
                        _files[index] = _item;

                        setFiles(_files)
                    }}

                    type='primary'>确定</Button>
            </div>
        </div>
    }

    const modalInfo: any = {};
    if (props.type !== 'edit') {
        modalInfo.footer = null
    }



    return <Modal
        visible={true}
        width={720}
        {...modalInfo}
        title={`${props.type === 'edit' ? '申报' : '查看'}考核任务`}
        onCancel={props.close}
        onOk={handleSubmit}
        className='ReportExamModal'
    >
        <div className="headerBox">
            <div className="title">
                {
                    info.name
                }
            </div>
            <div className="desc" dangerouslySetInnerHTML={{ __html: info.description }} >
            </div>
            <div className="tagDesc">
                <span style={{ marginRight: 24 }} className="tag">标签：{
                    info.tagNames?.join('/')
                }</span>
                {/* <span className="score">得分设置：分级计分</span> */}
            </div>
        </div>
        <Form
            {...formItemLayout}
        >
            {
                info.calculateScore && <Form.Item
                    label='申报数量'
                    required={props.type === 'edit'}
                    validateStatus={(hassubmit && !info.tenantName) ? "error" : undefined}
                    help={(hassubmit && !info.tenantName) ? "学校名称不能未空" : undefined}
                >
                    <div className="xmTable">
                        <div className="header">
                            <div className="item">级别名称</div>
                            <div className="item">申报数量</div>
                            <div className="item">单项得分(人)</div>
                            <div className="item">当前得分</div>
                        </div>
                        <div className="body">
                            {
                                info.scoreList?.map((item: any, index: any) => {
                                    return <div key={item.id} className={index === info.scoreList.length - 1 ? "list last" : "list"}>
                                        <div className="item">{item.name}</div>
                                        <div className="item">
                                            {
                                                props.type === 'edit' ? <input
                                                    max={item.limitCount}
                                                    value={item.reportCount}
                                                    onChange={(e: any) => {
                                                        let value = parseInt(e.target.value) ? parseInt(e.target.value) : parseInt(e.target.value) === 0 ? 0 : ''
                                                        if (value > item.limitCount) {
                                                            value = item.limitCount
                                                        }
                                                        const _info = { ...info };
                                                        _info.scoreList = [...info.scoreList];
                                                        _info.scoreList[index].reportCount = value;
                                                        _info.scoreList[index].reportScore = _info.scoreList[index].itemScore;
                                                        setInfo(_info)
                                                    }}

                                                    placeholder={`请输入0-${item.limitCount}`} /> : item.reportCount
                                            }
                                        </div>
                                        <div className="item"

                                        >
                                            {
                                                item.itemScore
                                            }
                                        </div>
                                        <div className="item">{(item.reportCount || 0) * (item.itemScore || 0) || '-'}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div style={{ marginTop: 4 }} className="xmTable">
                        <div className="header">
                            <div className="item">总计</div>
                            <div className="item">{resInfo.num || '-'}</div>
                            <div className="item">-</div>
                            <div className="item">{resInfo.score || '-'}</div>
                        </div>
                    </div>

                </Form.Item>
            }

            <Form.Item
                label='上传证明'
                required={props.type === 'edit' && info.uploadCert}
            // validateStatus={(hassubmit && !info.tenantName) ? "error" : undefined}
            // help={(hassubmit && !info.tenantName) ? "学校名称不能未空" : undefined}
            >
                {
                    props.type === 'edit' && <div>
                        <Button onClick={() => {
                            fileRef.current.click()
                        }}

                        >上传文件</Button> <span>文件数量限制10个，每个文件大小不超过100M</span>
                    </div>
                }


                {
                    !!files.length && <div className="fileListBox">
                        <div className="total">
                            {`共${files.length}个文件`}
                        </div>
                        <div className="fileList">
                            {
                                files.map((item: any, index: any) => {
                                    console.log(FileTypeIcon, item.fileFormat)
                                    return <div className="fileBox">
                                        <div className="num">{index + 1 > 9 ? index + 1 : '0' + (index + 1)}</div>

                                        <div className="name"><img src={(FileTypeIcon as any)[item.fileFormat as any]} alt="" />   <Popover visible={item.visible} content={renderContent(item, index)} title={null} trigger={[]}>{item.resourceName} </Popover></div>


                                        {
                                            props.type === 'edit' ? <div className="operate">
                                                <span
                                                    onClick={() => {
                                                        const _files = [...files];
                                                        _files[index].visible = true;
                                                        _files[index]._resourceName = _files[index].resourceName;
                                                        setFiles(_files)
                                                    }}
                                                >重命名</span>
                                                <div className="line"></div>
                                                <span onClick={() => {
                                                    const _files = [...fileListRef.current];
                                                    _files.splice(index, 1);
                                                    setFiles(_files)
                                                }}>移除</span>
                                            </div> : <div className="operate">
                                                <span
                                                    onClick={() => {
                                                        const { fileFormat } = item;
                                                        if (item.fileFormat === 'jpg' || item.fileFormat === 'png' || item.fileFormat === 'jepg') {
                                                            const _modal = <ImageViver url={item.url} close={() => {
                                                                setModal(null)
                                                            }} />
                                                            setModal(_modal)
                                                        } else if ((fileFormat === "ppt" || fileFormat === "pptx" || fileFormat === "docx" || fileFormat === "word" || fileFormat === "doc")) {
                                                            const prefixUrl = "https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fxmdev-resource-pub.oss-cn-hangzhou.aliyuncs.com%2Fcommon%2F1000000000000000000%2F20211018123219%2F1634531538956%25E5%2592%258C%25E5%2585%2589%25E5%25B0%2598%25E6%25A8%25BE%25E8%25BD%25A9%25E9%25A1%25B9%25E7%259B%25AE%25E4%25BA%25A4%25E4%25BB%2598%25E9%25A1%25BA%25E5%25BB%25B6%25E5%258D%258F%25E8%25AE%25AE%25E4%25B9%25A6%25E2%2580%2594%25E2%2580%259412%25E5%25B9%25A2.pdf"
                                                            const scanUrl = `${prefixUrl}${encodeURIComponent(item.url)}`
                                                            window.open(scanUrl, "_blank")
                                                        } else {
                                                            window.open(item.url, "_blank")
                                                        }

                                                    }}
                                                >查看</span>
                                                <div className="line"></div>
                                                <span onClick={() => {
                                                    getBlob(item.url, item.resourceName)
                                                }}>下载</span>
                                            </div>
                                        }

                                    </div>
                                })

                            }
                        </div>
                    </div>
                }
            </Form.Item>


            <Form.Item
                label='申报证明'
                required={props.type === 'edit' && info.declared}
            // validateStatus={(hassubmit && !info.tenantName) ? "error" : undefined}
            // help={(hassubmit && !info.tenantName) ? "学校名称不能未空" : undefined}
            >
                {
                    props.type === 'edit' ? <div>
                        {
                            hasInfo && <EditorBox
                                placeholder='请输入申报详情，限制为500字'
                                detail={{
                                    content: info.detail
                                }}
                                onChange={(val: any) => {
                                    const _info = { ...info };
                                    _info.detail = val;
                                    setInfo(_info)
                                }}
                            />
                        }
                    </div> : <div style={{maxHeight:180,overflow:'auto'}}   dangerouslySetInnerHTML={{ __html: info.detail }}></div>
                }

            </Form.Item>

        </Form>

        <input accept=".ppt,.pptx,.doc,.docx,.pdf,.jpg,.jpeg,.png,.xlsx,.xls" onChange={handleUpload} style={{ visibility: 'hidden', position: 'absolute', top: -100 }} type="file" ref={(dom) => {
            fileRef.current = dom
        }} />
        {
            modal
        }
    </Modal>

}

export default ReportExamModal
