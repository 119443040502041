/*
* @Author: liuqing
* @Date: 2021-09-28 10:58:29
 * @LastEditors: liuqing
 * @LastEditTime: 2021-09-28 11:31:53
* @Description: 
* @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
*/
import React, { Context } from "react";

export const AppContext: Context<any> = React.createContext(null)
AppContext.displayName = 'AppContext'
