import React from 'react';
import E from 'wangeditor';

import { randomString } from '@/domains/basic-domain/utils';

import './index.less';

class EditorBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editorId: randomString(16),
      textLength: 0,
    }
  }

  componentDidMount() {
    this.renderEditor()
  }

  renderEditor() {
    const { editorId } = this.state;
    const { detail, onChange } = this.props;
    const editorInt = new E(`#editor`);
    console.log(editorInt,'editorInt')
    // return
    editorInt.config.menus = [
      'bold',  // 粗体
      'fontName',  // 字体
      'italic',  // 斜体
      'underline',  // 下划线
      'strikeThrough',  // 删除线
      'foreColor',  // 文字颜色
      'backColor',  // 背景颜色
      'list',  // 列表
      'justify',  // 对齐方式
      'emoticon',  // 表情
    ]

    editorInt.config.emotions = [
      {
        title: 'emoji',
        type: 'emoji',
        content: ['😀', '😃', '😄', '😁', '😆', '😅', '😂', '😊', '🙂', '🙃', '😉', '😓', '😅', '😪', '🤔', '😬', '🤐']
      }
    ]
    editorInt.config.zIndex = 999;
    editorInt.config.pasteFilterStyle = false;
    editorInt.config.placeholder=this.props.placeholder;
    editorInt.config.pasteIgnoreImg = true;
    // 自定义处理粘贴的文本内容
    editorInt.config.pasteTextHandle = function (content) {
      if (content === '' && !content) return ''
      var str = content
      str = str.replace(/<xml>[\s\S]*?<\/xml>/ig, '')
      str = str.replace(/<style>[\s\S]*?<\/style>/ig, '')
      str = str.replace(/<\/?[^>]*>/g, '')
      str = str.replace(/[ | ]*\n/g, '\n')
      str = str.replace(/&nbsp;/ig, ' ')
      return str
    }
    editorInt.config.onchange = (html) => {
      const textLength = editorInt.txt.text().replace(/&nbsp;/ig, ' ').length;
      this.setState({ textLength }, () => {
        onChange(html, this.state.textLength);
      })
    }
    editorInt.create();
    editorInt.txt.html(detail.content);
    // editorInt.change && editorInt.change();
  }

  render() {
    const { editorId, textLength } = this.state;
    const { limitLength = 500 } = this.props;

    return <div className="wang-editor-container ">
      <div className="editor-box" id={`editor`}></div>
      {limitLength && <div className="editor-tips">超了{textLength - limitLength}个字</div>}
    </div>
  }
}

export default EditorBox;
