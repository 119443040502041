/*
 * @Author: liuqing
 * @Date: 2021-09-28 13:58:17
 * @LastEditors: liuqing
 * @LastEditTime: 2021-09-28 14:31:11
 * @Description: 空状态
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React from 'react';
import Lottie from 'react-lottie';
import { Empty } from 'antd';
import college from '@/common/lottie/college';

interface IXMEmpty {
  type?: 'college';
  description?: string;
}

const TYPE = {
  college
}

function XMEmpty(props: IXMEmpty) {
  const { type = 'college', description = '暂无数据' } = props;

  function renderImage() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: TYPE[type],
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <Lottie
        options={defaultOptions}
        height={150}
        width={150}
        isStopped={false}
        isPaused={false}
      />
    );
  }

  return (
    <Empty
      image={renderImage()}
      imageStyle={{ height: 150 }}
      description={description}
    />
  );
}

export default XMEmpty;
