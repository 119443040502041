/*
 * @Author: liuqing
 * @Date: 2021-09-10 10:25:10
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-14 14:28:49
 * @Description: 首页路由
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import { MenuConfig } from '@/routes/interface';

import Home from '@/modules/home';

const HomeConfig: MenuConfig = {
  key: 'home',
  name: '首页',
  routes: [
    {
      key: 'home',
      name: '首页',
      path: '/edu/home',
      exact: true,
      component: Home,
    },
    {
      key: 'home',
      name: '首页',
      path: '/sch/home',
      exact: true,
      component: Home,
    },
  ],
};

export default HomeConfig;


