/*
 * @Author: liuqing
 * @Date: 2021-09-23 11:12:18
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-19 19:21:55
 * @Description:
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Menu } from 'antd';
import cls from 'classnames';
// import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

import { IMenuItem } from '@/routes/config/menu';
import style from './aside.module.less';
import RCHistory from '@/routes/history';
import { countNoReadTask, markTaskReadStatus } from '@/data-source/assess';

interface IAsideProps {
  menu: IMenuItem[];
}

function Aside(props: IAsideProps) {
  const { menu: menuList } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [showAssessTaskRedDot, setShowAssessTaskRedDot] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    getNotRead();
  }, []);

  useEffect(() => {
    menuList.map(item => {
      if (location.pathname.startsWith(item.link)) {
        setSelectedKeys([item.groupCode]);
      }
    });
  }, [location.pathname, menuList]);

  function getNotRead() {
    countNoReadTask().then(res => {
      setShowAssessTaskRedDot(res.result > 0);
    });
  }

  function handleChangeCollapsed() {
    setCollapsed(!collapsed);
  }

  function handleClickMenuItem(item: IMenuItem) {
    if (item.groupCode === 'SchAssessTask' && showAssessTaskRedDot) {
      markTaskReadStatus().then(() => {
        setShowAssessTaskRedDot(false);
      });
    }
    RCHistory.push(item.link);
  }

  return (
    <div className={style.xmAside}>
      <div
        className={style.logo}
        style={{ justifyContent: collapsed ? 'flex-end' : 'space-between' }}
      >
        {!collapsed && (
          <img src='https://image.xiaomaiketang.com/xm/8yPGd7jkGd.png' alt='' />
        )}
        {collapsed ? (
          <span
            className={cls(style.foldBtn, 'icon iconfont')}
            onClick={handleChangeCollapsed}
          >
            &#xe6ce;
          </span>
        ) : (
          <span
            className={cls(style.foldBtn, 'icon iconfont')}
            onClick={handleChangeCollapsed}
          >
            &#xe6cf;
          </span>
        )}
      </div>
      <Menu
        style={{ width: collapsed ? 64 : 164 }}
        mode={collapsed ? 'vertical' : 'inline'}
        selectedKeys={selectedKeys}
        inlineCollapsed={collapsed}
      >
        {menuList.map((item: IMenuItem) => {
          if (item.children) {
            return null;
          } else {
            return (
              <Menu.Item
                className={style.menuItem}
                key={item.groupCode}
                icon={<img src={item.img} className={style.iconImg} />}
                onClick={() => handleClickMenuItem(item)}
              >
                <span className={style.menuItemTitle}>
                  {item.groupName}
                  {item.groupCode === 'SchAssessTask' &&
                    showAssessTaskRedDot && (
                      <span className={style.menuRedDot}></span>
                    )}
                </span>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </div>
  );
}

export default Aside;
