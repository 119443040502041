/*
 * @Author: 吴文洁
 * @Date: 2020-04-28 18:05:30
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-16 16:41:23
 * @Description: 路由基础配置
 */
import React from 'react';
import { RouteConfig } from '@/routes/interface';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale/zh_CN';
import allRoutes from './config';
import RCHistory from './history';

import Login from '@/modules/login';
import AppContext from '@/modules/root/AppContext';
import XMEmpty from '@/components/XMEmpty';

const validateMessages = {
  required: '请输入${label}',
};

console.log(allRoutes, 'allRoutes');
export const RootRouter = () => (
  <HashRouter {...RCHistory}>
    <ConfigProvider
      locale={zh_CN}
      form={{ validateMessages }}
      renderEmpty={() => <XMEmpty />}
    >
      <Switch>
        <Route key='1' exact path='/login' render={() => <Login />}></Route>
        <Route key='2' path='/' render={() => <AppContext />}></Route>
      </Switch>
    </ConfigProvider>
  </HashRouter>
);

export const MainRouter = () => {
  return (
    <Switch>
      {allRoutes.map((route: RouteConfig) => {
        return (
          <Route
            key={route.path}
            component={route.component}
            path={route.path}
            exact={route.exact}
          />
        );
      })}
      {/* <Route key='-1' exact path='/' render={() => <Redirect to='/home' />} />
      <Redirect
        from='/home'
        to={window.accountType === 'ACADEMIC' ? '/sch/examine' : '/edu/examine'}
      /> */}
    </Switch>
  );
};

export default RootRouter;
