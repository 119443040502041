import React, { useState } from 'react';
import { Modal, Input, Form, message } from 'antd';
import { updateAdminPhone, } from '@/data-source/admin';
import BindWechat from '@/components/BindWechat'
function BindWechatModal(props: any) {

    return <Modal title={'绑定微信'} footer={null} onCancel={props.close} visible={true}>
        <BindWechat success={props.close} type='bind'  text={'绑定'}  />

    </Modal>
}

export default BindWechatModal
