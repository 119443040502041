import React, { useState, useContext, useMemo } from 'react';
import { AppContext } from '@/store';
import { Menu, Dropdown, Divider } from 'antd'
import {withRouter} from 'react-router-dom';
import user from '@/common/js/user';
import './header.less'

function Header(props:any) {
    const ctx = useContext<any>(AppContext);
    const [info, setInfo] = useState<any>({
        avator: 'https://image.xiaomaiketang.com/xm/rJeQaZxtc7.png',
        name: 'RTYUIJNHH'
    })

    const menu = useMemo(() => {
        return <Menu className='header_dropdown'>
            <Menu.Item key="0">
                <div className='setInfo' onClick={()=>{
                    props.history.push('/setting')
                }}>
                    <div>
                        <div className="name">{ctx.state.userInfo.name}</div>
                        <div className="account">{ctx.state.userInfo.account}</div>
                    </div>

                    <div className="shezhi">个人设置 <span className="icon iconfont">&#xe79b;</span></div>
                </div>
            </Menu.Item>
            <Divider type="horizontal" />
            <Menu.Item key="1">
                <div className='loginout' onClick={()=>{
                    user.loginOut();
                    props.history.push('/login')
                }}>
                    <div className='text'> <span className="icon iconfont">&#xe870;</span>退出登录</div>
                    <span className="icon iconfont">&#xe79b;</span>
                </div>
            </Menu.Item>
        </Menu>
    }, [ctx.state.userInfo.name, ctx.state.userInfo.phone])

    return <div className="xm_header">

        <div className="box">
            <div className="schoolName">
                {
                    ctx.state.userInfo.accountType === 'ACADEMIC' && ctx.state.userInfo.tenantName
                    // ctx.state.userInfo.tenantName
                }
            </div>
            <Dropdown arrow placement="bottomRight" overlay={menu}>
                <div className="userInfo">
                    <img src={ctx.state.userInfo.avatar || 'https://image.xiaomaiketang.com/xm/rJeQaZxtc7.png'} alt="" />
                    <span className="name">{ctx.state.userInfo.name}</span>
                    <span className="icon iconfont">&#xe79b;</span>
                </div>
            </Dropdown>
        </div>
        <div className="bag"></div>
    </div>

}

export default withRouter(Header);

