/*
 * @Author: liuqing
 * @Date: 2021-09-18 15:27:41
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-19 13:55:33
 * @Description: 路由入口
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import { MenuConfig, RouteConfig } from '@/routes/interface';
import Home from './home';
import User from './user';
import Examine from './examine';
import Category from './category';
import Tag from './tag';

// 领域路由配置
export const menuConfigs: MenuConfig[] = [
  Home,
  User,
  Examine,
  Category,
  Tag
];

/** 所有处理后的路由的集合,用于生成Route组件 */
export const allRoutes: RouteConfig[] = menuConfigs
  .map((config: MenuConfig) => {
    return config.routes || [];
  })
  .reduce((prev: RouteConfig[], next: RouteConfig[]) => {
    return prev.concat(...next);
  });

export default allRoutes;
