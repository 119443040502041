import Service from '@/common/js/service';

export function saveTagGroup(params: object): Promise<any> { //添加标签组
    return Service.Public('eduTag/saveTagGroup', params);
}

export function getTagGroupList(params: object): Promise<any> { //通过租户查询权标签组列表
    return Service.Public('eduTag/getTagGroupList', params);
}

export function editTagGroup(params: object): Promise<any> { //编辑标签组
    return Service.Public('eduTag/editTagGroup', params);
}

export function getTagByPage(params: object): Promise<any> { //分页查询标签
    return Service.Public('eduTag/getTagByPage', params);
}

export function getTagTree(tagName?: string): Promise<any> { // 查询标签树
    return Service.Public('eduTag/getTagTree', { tagName });
}

export function saveTag(params: object): Promise<any> {
    return Service.Public('eduTag/saveTag', params);
}

export function editTag(params: object): Promise<any> { //编辑标签
    return Service.Public('eduTag/editTag', params);
}

export function delTagGroup(params: object): Promise<any> { //删除标签组
    return Service.Public('eduTag/delTagGroup', params);
}

export function delTag(params: object): Promise<any> { //删除标签组
    return Service.Public('eduTag/delTag', params);
}





