/*
 * @Author: liuqing
 * @Date: 2021-10-11 11:20:55
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-20 16:57:22
 * @Description: 管理端考核表
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useState } from 'react';
import { useRouteMatch, withRouter } from 'react-router';
import { Button, Divider, message, TableColumnsType, Tooltip } from 'antd';
import {
  CategorySelect,
  Page,
  XMClearFilter,
  XMEllipsis,
  XMModal,
  XMSearchInput,
  XMTable,
} from '@/components';
import RCHistory from '@/routes/history';
import AssignModal from './AssignModal';
import { delAssessTable, getAssessTableByPage } from '@/data-source/assess';
import moment from 'moment';
import _ from 'underscore';
import { TStandardType } from '@/common/js/enum';

const standardTypeMap = {
  SCORE: '分',
  PASSITEM: '项',
};

const defaultQuery = {
  current: 1,
};

function EduExamine(props: any) {
  const match = useRouteMatch();
  const [modal, setModal] = useState<any>(null);
  const [query, setQuery] = useState<any>(defaultQuery);
  const [records, setRecords] = useState<any>([]);
  const [total, setTotal] = useState<any>(0);

  useEffect(() => {
    getData();
  }, [query]);

  function getData() {
    getAssessTableByPage(query).then(res => {
      const { total, records } = res.result;
      setRecords(records);
      setTotal(total);
    });
  }

  function handleClickAdd() {
    RCHistory.push(`${match.url}/add`);
  }

  function handleClickDelete(id: string) {
    XMModal.danger({
      title: '你确定要删除当前考核表吗？',
      content: '删除后，所有的填报数据均会丢失！',
      okText: '确认删除',
      onOk: () => {
        delAssessTable(id).then(() => {
          message.success('删除成功');
          getData();
        });
      },
    });
  }

  function handlePageChange(page: number) {
    const _query = _.clone(query);
    _query.current = page;
    setQuery(_query);
  }

  function handleClickAssign(record: any) {
    const modal = (
      <AssignModal
        assessTableId={record.id}
        tenantIdList={record.tenantIdList}
        onCancel={() => setModal(null)}
        onOk={() => {
          getData();
          setModal(null);
        }}
      />
    );
    setModal(modal);
  }

  function handleRowClick(record: any) {
    return {
      onClick: (e: any) => {
        // 热区判断
        const hotArea = !((e.target || {}).className || '').includes(
          'not__allow'
        );
        if (hotArea) {
          RCHistory.push(`/edu/examine/detail/${record.id}`);
        }
      },
    };
  }

  function renderColumns(): TableColumnsType {
    return [
      {
        key: 'name',
        dataIndex: 'name',
        title: '名称',
        render: value => {
          if (value) {
            if (value.length > 12) {
              return (
                <Tooltip title={value}>
                  <span className='a-link'>
                    {value.substring(0, 12)}...
                  </span>
                </Tooltip>
              );
            }
            return <span className='a-link'>{value}</span>;
          }
          return <span className='a-link'>-</span>;
        },
      },
      {
        key: 'created',
        dataIndex: 'created',
        title: '创建时间',
        render: value => {
          return moment(value).format('YYYY-MM-DD');
        },
      },
      {
        key: 'categoryName',
        dataIndex: 'categoryName',
        title: '分类',
        render: value => <XMEllipsis text={value} length={12} />,
      },
      {
        key: 'itemCount',
        dataIndex: 'itemCount',
        title: '考核项',
      },
      {
        key: 'totalScore',
        dataIndex: 'totalScore',
        title: '总分',
      },
      {
        key: 'standardType',
        dataIndex: 'standardType',
        title: '达标设置',
        render: (value: TStandardType, record: any) => {
          if (!record.standardSetting) {
            return '-';
          }
          return (record.standardCount || 0) + standardTypeMap[value];
        },
      },
      {
        key: 'standardTaskCount',
        dataIndex: 'standardTaskCount',
        title: '达标数/考核任务总数',
        render: (value, record: any) => {
          return (record.standardSetting ? (value || 0) : '-' ) + '/' + (record.taskCount || 0);
        },
      },
      {
        className: 'not__allow',
        key: 'operation',
        dataIndex: 'operation',
        title: '操作',
        render: (value, record: any) => {
          return (
            <>
              {(record.tenantIdList || []).length === 0 && (
                <>
                  <span
                    className='a-link not__allow'
                    onClick={() => {
                      RCHistory.push(`${match.url}/add?id=${record.id}`);
                    }}
                  >
                    编辑
                  </span>
                  <Divider type='vertical' />
                </>
              )}
              <span
                className='a-link not__allow'
                onClick={() => handleClickDelete(record.id)}
              >
                删除
              </span>
              <Divider type='vertical' />
              <span
                className='a-link not__allow'
                onClick={() => handleClickAssign(record)}
              >
                指派
              </span>
            </>
          );
        },
      },
    ];
  }

  return (
    <Page title='考核表'>
      <div className='page__top__filter__wrap'>
        <Button type='primary' onClick={handleClickAdd}>
          新建考核表
        </Button>
        <div className='filter__right__wrap'>
          <XMSearchInput
            className='filter__item'
            label='名称'
            placeholder='请输入名称'
            value={query.name}
            onChange={_.debounce(
              (e: any) => setQuery({ ...query, name: e.target.value }),
              200
            )}
            onSearch={value => {
              setQuery({
                ...query,
                name: value,
                current: 1,
              });
            }}
          />
          <CategorySelect
            className='filter__item'
            showLabel
            value={query.categoryId}
            onChange={value =>
              setQuery({ ...query, categoryId: value, current: 1 })
            }
          />
        </div>
        <XMClearFilter
          onClick={() => {
            setQuery({
              current: 1,
            });
          }}
        />
      </div>
      <XMTable
        dataSource={records}
        rowClassName='allow-click'
        rowKey='id'
        columns={renderColumns()}
        total={total}
        current={query.current}
        onPageChange={handlePageChange}
        onRow={handleRowClick}
      />
      {modal}
    </Page>
  );
}

export default withRouter(EduExamine);
