import React, { useState } from 'react';
import { Modal, Input, Form, message } from 'antd';
import { updateAdminPhone, updateAdminPassword } from '@/data-source/admin';
import { withRouter } from 'react-router-dom';
import md5 from 'md5';

function EditPasswordModal(props: any) {
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [hasubmit, setHasSbmit] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 0 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },


    };

    function submit() {
        setHasSbmit(true)
        // setPasswordError(true);
        updateAdminPassword({
            "id": props.userInfo.id,
            "newPwd": md5(password),
            "oldPwd": md5(oldPassword)
        }).then(() => {
            message.success('修改成功');
            props.history.push('/login')
        })
    }

    return <Modal title="设置密码" visible={true} onOk={submit} onCancel={props.onClose}>
        <p>为了你的账户安全，请输入密码验证。</p>
        <p style={{marginBottom:8}}>{props.userInfo.account}</p>
        <Form {...formItemLayout}>
            <Form.Item
                validateStatus={passwordError ? "error" : undefined}
                help={passwordError ? "原密码错误" : undefined}
            >
                <Input type='password'
                    placeholder='请输入原密码'
                    onChange={(e: any) => {
                        setOldPassword(e.target.value)
                    }}></Input>
            </Form.Item>
            <Form.Item
                validateStatus={(hasubmit && !password.length) ? "error" : undefined}
                help={(hasubmit && !password.length) ? "请输入密码" : undefined}
            >
                <Input
                    type='password'
                    placeholder='请输入密码'
                    onChange={(e: any) => {
                        setPassword(e.target.value)
                    }}></Input>

            </Form.Item>
        </Form>


    </Modal>
}

export default withRouter(EditPasswordModal)
