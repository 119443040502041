
/*
* @Author: liuqing
* @Date: 2021-10-11 11:18:37
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-20 13:38:34
* @Description: 考核表
* @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
*/
import Service from "@/common/js/service";

// 考核表列表
export function getAssessTableByPage(params: object) {
  return Service.Public('assess/getAssessTableByPage', params);
}

// 考核表详情
export function getAssessTableDetail(id: number | string) {
  return Service.Public('assess/getAssessTableDetail', { id });
}

// 添加考核表
export function saveAssessTable(params: object) {
  return Service.Public('assess/saveAssessTable', params);
}

// 编辑考核表
export function editAssessTable(params: object) {
  return Service.Public('assess/editAssessTable', params);
}

// 删除考核表
export function delAssessTable(id: number | string) {
  return Service.Public('assess/delAssessTable', { id });
}

// 指派考核表
export function appointAssessTable(params: object) {
  return Service.Public('assess/appointAssessTable', params);
}

// 考核项列表
export function getAssessItemByPage(params: object) {
  return Service.Public('assess/getAssessItemByPage', params);
}

// 考核项详情
export function getAssessItemDetail(id: number | string) {
  return Service.Public('assess/getAssessItemDetail', { id });
}

// 添加考核项
export function saveAssessItem(params: object) {
  return Service.Public('assess/saveAssessItem', params);
}

// 删除考核项
export function delAssessItem(id: string) {
  return Service.Public('assess/delAssessItem', { id });
}

// 编辑考核项
export function editAssessItem(params: object) {
  return Service.Public('assess/editAssessItem', params);
}

// 管理端分页查询考核任务
export function getEduTaskByPage(params: object) {
  return Service.Public('assess/getEduTaskByPage', params);
}

// 学校端分页查询考核任务
export function getSchTaskByPage(params: object) {
  return Service.Public('assess/getSchTaskByPage', params);
}

// 考核任务详情
export function getAssessTaskDetail(id: string) {
  return Service.Public('assess/getAssessTaskDetail', { id });
}

export function getReportItemDetail(params: object) {
  return Service.Public('assess/getReportItemDetail', params);
}

export function reportItem(params: object) {
  return Service.Public('assess/reportItem', params);
}

// 查询租户未读考核任务数量
export function countNoReadTask() {
  return Service.Public('assess/countNoReadTask', {});
}

// 标记考核任务已读状态
export function markTaskReadStatus() {
  return Service.Public('assess/markTaskReadStatus', {});
}



