import React, { useState } from 'react';
import { Modal, Input, Form, message, } from 'antd';
import { updateAdminPhone, getAdminFullDetailById } from '@/data-source/admin';
import { withRouter } from 'react-router-dom';
import md5 from 'md5';

function EditPhone(props: any) {
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [hassubmit, setHasSbmit] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 0 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },


    };

    function submit() {
        setHasSbmit(true)
        // setPasswordError(true);
        if (!password || phone.length != 11) {
            return
        }
        updateAdminPhone({
            "id": props.userInfo.id,
            "password": md5(password),
            "phone": phone
        }).then(() => {
            message.success('修改成功');
            props.history.push('/login')
        }).catch((err) => {
            if (err.code === "DATA_ERROR") {
                setPasswordError(true)
            }
        })
    }

    return <Modal title="更换手机号" visible={true} onOk={submit} onCancel={props.onClose}>
        <p>为了你的账户安全，请输入密码验证。</p>
        <p style={{marginBottom:8}}>{props.userInfo.account}</p>
        <Form {...formItemLayout}>
            <Form.Item
                validateStatus={(passwordError) ? "error" : undefined}
                help={(passwordError) ? "密码错误" : undefined}
            >
                <Input type='password'
                    placeholder='请输入密码'
                    onChange={(e: any) => {
                        setPassword(e.target.value)
                    }}></Input>


            </Form.Item>
            <Form.Item
                validateStatus={(hassubmit && !phone.length) ? "error" : undefined}
                help={(hassubmit && phone.length != 11) ? "请输入正确的手机号" : undefined}
            >
                <Input
                    type='number'
                    placeholder='请输入现手机号'
                    onChange={(e: any) => {
                        setPhone(e.target.value)
                    }}></Input>

            </Form.Item>
        </Form>


    </Modal>
}

export default withRouter(EditPhone)