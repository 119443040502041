/*
 * @Author: liuqing
 * @Date: 2021-09-23 15:22:20
 * @LastEditors: liuqing
 * @LastEditTime: 2021-09-28 16:59:08
 * @Description: 二次确认弹窗封装
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { ReactNode } from 'react';

import { Modal, ModalProps, ModalFuncProps } from 'antd';

interface IXMModalProps extends ModalProps {
  children: ReactNode;
}
function XMModal(props: IXMModalProps) {
  return (
    <Modal centered {...props}>
      {props.children}
    </Modal>
  );
}

XMModal.danger = function (props: ModalFuncProps) {
  return Modal.confirm({
    icon: <span className='iconfont modal-danger-icon'>&#xe8b0;</span>,
    autoFocusButton: null,
    centered: true,
    okButtonProps: {
      type: 'primary',
      danger: true,
    },
    ...props,
  });
};

XMModal.confirm = function (props: ModalFuncProps) {
  return Modal.confirm({
    icon: <span className='iconfont modal-confirm-icon'>&#xe8b1;</span>,
    autoFocusButton: null,
    centered: true,
    ...props,
  });
};

export default XMModal;
