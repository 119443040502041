/*
 * @Author: liuqing
 * @Date: 2021-09-24 17:54:26
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-15 16:27:29
 * @Description: 
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import { MenuConfig } from '@/routes/interface';

import { EduExamine } from '@/modules/examine';
import AddExamine from '@/modules/examine/edu/add/AddExamine';
import ExamineDetail from '@/modules/examine/edu/detail/ExamineDetail';
import SchExamine from '@/modules/examine/sch/SchExamine';
import SchExamineDetail from '@/modules/examine/sch/detail/ExamineDetail';

const ExamineConfig: MenuConfig = {
  key: 'examine',
  name: '考核表',
  routes: [
    {
      key: 'examine',
      name: '考核表',
      path: '/edu/examine',
      exact: true,
      component: EduExamine,
    },
    {
      key: 'examine',
      name: '新建考核表',
      path: '/edu/examine/add',
      exact: true,
      component: AddExamine,
    },
    {
      key: 'examine',
      name: '考核表详情',
      path: '/edu/examine/detail/:id',
      exact: true,
      component: ExamineDetail,
    },
    {
      key: 'examine',
      name: '考核任务表',
      path: '/sch/examine',
      exact: true,
      component: SchExamine,
    },
    {
      key: 'examine',
      name: '考核任务表',
      path: '/sch/examine/detail/:id',
      exact: true,
      component: SchExamineDetail,
    },
  ],
};

export default ExamineConfig;
