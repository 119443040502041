/*
 * @Author: liuqing
 * @Date: 2021-09-22 14:35:01
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-12 17:04:19
 * @Description:
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import RCHistory from '@/routes/history';
import React, { CSSProperties, ReactNode } from 'react';
import { Button } from 'antd';
import cls from 'classnames';
import style from './index.module.less';

interface IPageProps {
  title?: string | ReactNode; // 页面标题
  breadcrumb?: string | ReactNode; // 面包屑
  footer?: boolean; // 是否显示页脚
  footerClassName?: string; // 页脚 class
  footerStyle?: CSSProperties; // 页脚样式
  children: ReactNode;
  onBack?: () => void; // 返回事件
  onCancel?: () => void; // 页脚取消按钮
  onSave?: () => void; // 页脚保存按钮
  renderFooter?: () => ReactNode; // 自定义页脚
}

function Page(props: IPageProps) {
  const {
    title,
    breadcrumb,
    onBack,
    footer,
    footerClassName,
    footerStyle,
    renderFooter,
    onCancel,
    onSave,
  } = props;

  function handleBack() {
    // 默认返回前一页
    if (onBack) {
      onBack();
    } else {
      RCHistory.goBack();
    }
  }

  return (
    <>
      <div className={cls(style.pageContainer, footer ? style.hasFooter : '')}>
        {title && <h2 className={style.pageTitle}>{title}</h2>}
        {breadcrumb && (
          <div className={style.breadcrumbWrap}>
            <div className={style.backText} onClick={handleBack}>
              <span className='iconfont'>&#xe895;</span>
              <span style={{ marginLeft: 2 }}>返回</span>
              <span style={{ margin: '0 8px' }}>｜</span>
            </div>
            <div className={style.breadcrumbText}>{breadcrumb}</div>
          </div>
        )}
        {props.children}
      </div>
      {footer && (
        <div
          className={cls(style.pageFooter, footerClassName)}
          style={footerStyle}
        >
          {renderFooter ? (
            renderFooter()
          ) : (
            <>
              <Button className='mr8' onClick={onCancel}>
                取消
              </Button>
              <Button type='primary' onClick={onSave}>
                保存
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Page;
