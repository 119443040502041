import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'antd';

function ImageViver(props: any) {
    return <Modal visible={true} footer={null} onCancel={props.close} width={720} title={'查看图片'} >
        <img style={{ width: '100%' }} src={props.url} alt="" />

    </Modal>
}

export default ImageViver