/*
 * @Author: 吴文洁
 * @Date: 2020-08-31 09:34:36
 * @LastEditors: 吴文洁
 * @LastEditTime: 2020-08-31 09:35:14
 * @Description: 
 * @Copyright: 杭州杰竞科技有限公司 版权所有
 */

import { XMStorageImplements } from '@/domains/basic-domain/interface';

const LS: Storage = window.localStorage;

class XMStorage implements XMStorageImplements {

  supportLocalStorage() {
    return !!LS;
  }

  get(key: string) {
    if (this.supportLocalStorage()) {
      return LS.getItem(key);
    }
  }

  set(key: string, value: any) {
    if (this.supportLocalStorage()) {
      LS.setItem(key, value);
    }
  }

  setObj(key: string, obj: any) {
    if (this.supportLocalStorage()) {
      LS.setItem(key, JSON.stringify(obj));
    }
  }

  getObj(key: string) {
    let value: null | string = null;
    if (this.supportLocalStorage()) {
      const LSItem = LS.getItem(key);
      try {
        if (LSItem) {
          value = JSON.parse(LSItem);
        }
      } catch (error) {
        value = LSItem;
      }
    }
    return value;
  }

  remove(key: string) {
    if (this.supportLocalStorage()) {
      LS.removeItem(key);
    }
  }

  clear() {
    if (this.supportLocalStorage()) {
      LS.clear();
    }
  }
}

export default new XMStorage();