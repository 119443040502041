/*
 * @Author: 吴文洁
 * @Date: 2020-12-21 17:40:39
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-20 17:37:31
 * @Description: 
 * @Copyright: 杭州杰竞科技有限公司 版权所有
 */
import MicroEvent from 'microevent';

class Bus {
  public bind(key: string, fn: Function) {};
  public unbind(key: string) {};
  public trigger(key: string, ...args: any) {};
};
MicroEvent.mixin(Bus);

export default new Bus();
