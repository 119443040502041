import Service from '@/common/js/service';

export function getDistrictTree(params: object): Promise<any> {
  return Service.Public('district/getDistrictTree', params);
}

export function saveEduTenant(params: object): Promise<any> {
  return Service.Public('tenant/saveEduTenant', params);
}

export function getEduTenantByPage(params: object): Promise<any> {
  return Service.Public('tenant/getEduTenantByPage', params);
}

export function getEduTenantList(tenantName?: string): Promise<any> {
  return Service.Public('tenant/getEduTenantList', { tenantName });
}

export function editEduTenant(params: object): Promise<any> {
  return Service.Public('tenant/editEduTenant', params);
}


export function delEduTenant(params: object): Promise<any> {
  return Service.Public('tenant/delEduTenant', params);
}


