/*
 * @Author: liuqing
 * @Date: 2021-09-29 14:23:50
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-21 09:39:10
 * @Description: 添加考核项
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Switch,
  Table,
  TableColumnsType,
  TreeSelect,
} from 'antd';
import { XMModal, XMRichTextEditor, XMStaticSelect } from '@/components';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { v4 as uuid } from 'uuid';
import _ from 'underscore';
import style from './AddAssessmentsModal.module.less';
import {
  editAssessItem,
  getAssessItemDetail,
  saveAssessItem,
} from '@/data-source/assess';
import { getTagTree } from '@/data-source/tag';
import Bus from '@/domains/basic-domain/bus';

interface IAddAssessmentsProps {
  id?: string;
  onCancel?: () => void;
  onOk?: (result?: any) => void;
}

const scoreUnit = [
  {
    id: 'PERSON',
    name: '人',
  },
  {
    id: 'PIECES',
    name: '个',
  },
  {
    id: 'PERCENT',
    name: '百分比',
  },
];

function AddAssessmentsModal(props: IAddAssessmentsProps) {
  const { id, onCancel, onOk } = props;
  const isEdit = !!id;
  const [overLimit, setOverLimit] = useState(false);
  const [calculateScore, setCalculateScore] = useState(false);
  const [description, setDescription] = useState(undefined);
  const [tagData, setTagData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [totalPoint, setTotalPoint] = useState<number>(0);
  const [scoreItems, setScoreItems] = useState([
    {
      id: uuid(),
      name: '',
      limitCount: '',
      itemScore: '',
    },
  ]);
  const [scoreItemsHelp, setScoreItemsHelp] = useState<string | undefined>(
    undefined
  );
  const [scoreItemsStatus, setScoreItemsStatus] = useState<ValidateStatus>('');
  const [form] = Form.useForm();

  useEffect(() => {
    getTagData();
  }, []);

  useEffect(() => {
    autoCalculate();
  }, [scoreItems]);

  useEffect(() => {
    if (id) {
      getAssessItemDetail(id).then(res => {
        const {
          name,
          description,
          tags,
          calculateScore,
          declared,
          unit,
          scoreItems,
          uploadCert,
        } = res.result;
        form.setFieldsValue({
          name,
          tags,
          unit,
          declared,
          uploadCert,
        });
        // 如果初始没有打开计分项，给它补一个
        if (!calculateScore) {
          scoreItems.push({
            id: uuid(),
            name: '',
            limitCount: '',
            itemScore: '',
          });
        }
        setCalculateScore(calculateScore);
        setDescription(description);
        setScoreItems(scoreItems);
        setLoaded(true);
      });
    }
  }, [id]);

  function getTagData() {
    getTagTree().then(res => {
      const result = res.result || [];
      const data = result.map((item: any) => {
        const tagList = (item.tagList || []).map((tag: any) => {
          return {
            title: tag.name,
            value: tag.id,
            key: tag.id,
          };
        });
        return {
          title: item.groupName,
          value: item.id,
          key: item.id,
          disableCheckbox: true,
          children: tagList,
        };
      });
      setTagData(data);
    });
  }

  function handleClickAddScoreItem() {
    const value = scoreItems.slice();
    value.push({
      id: uuid(),
      name: '',
      limitCount: '',
      itemScore: '',
    });
    setScoreItems(value);
  }

  function autoCalculate() {
    let totalPoint = 0;
    scoreItems.map((item: any) => {
      totalPoint += Number(item.itemScore) * Number(item.limitCount);
    });
    setTotalPoint(totalPoint);
  }

  function validateScoreItems() {
    // 计分项打开了才会校验
    if (calculateScore) {
      if (
        scoreItems.every(item => item.name && item.itemScore && item.limitCount)
      ) {
        setScoreItemsHelp(undefined);
        setScoreItemsStatus('');
      } else if (!scoreItemsHelp) {
        setScoreItemsHelp('计分项请填写完整');
        setScoreItemsStatus('error');
      }
    }
  }

  function handleOk() {
    validateScoreItems();
    if (overLimit) {
      return message.warn('考核说明超出字数了');
    }
    form.validateFields().then(values => {
      if (!scoreItemsHelp) {
        const params = {
          ...values,
          totalPoint,
          description: description,
          calculateScore: calculateScore,
        };
        if (calculateScore) {
          params.scoreItems = scoreItems.map(item => {
            delete item.id;
            return item;
          });
        }
        if (isEdit) {
          params.id = id;
          editAssessItem(params).then(res => {
            message.success('修改成功');
            onOk && onOk();
            Bus.trigger('assessments_modal_update_item', {id, item: res.result})
          });
        } else {
          saveAssessItem(params).then(res => {
            message.success('保存成功');
            onOk && onOk(res.result || {});
          });
        }
      }
    });
  }

  function renderScore() {
    const scoreColumns: TableColumnsType<object> = [
      {
        key: 'name',
        dataIndex: 'name',
        title: '名称',
        render: (value, record: any, index: number) => {
          return (
            <Input
              bordered={false}
              placeholder='请输入，20字以内'
              maxLength={20}
              value={record.name}
              onChange={e => {
                const _scoreItems = _.clone(scoreItems);
                _scoreItems[index].name = e.target.value;
                setScoreItems(_scoreItems);
                validateScoreItems();
              }}
            />
          );
        },
      },
      {
        key: 'limitCount',
        dataIndex: 'limitCount',
        title: '最大数量',
        render: (value, record: any, index: number) => {
          return (
            <InputNumber
              bordered={false}
              placeholder='请输入0-100'
              min={0}
              max={100}
              value={record.limitCount}
              onChange={e => {
                if (e == null) {
                  e = '';
                }
                const _scoreItems = _.clone(scoreItems);
                _scoreItems[index].limitCount = String(e).replace(/\.\d*$/, '');
                setScoreItems(_scoreItems);
                validateScoreItems();
              }}
            />
          );
        },
      },
      {
        key: 'itemScore',
        dataIndex: 'itemScore',
        title: '单项得分',
        render: (value, record: any, index: number) => {
          return (
            <InputNumber
              bordered={false}
              placeholder='请输入0-100'
              min={0}
              max={100}
              value={record.itemScore}
              onChange={e => {
                if (e == null) {
                  e = '';
                }
                const _scoreItems = _.clone(scoreItems);
                _scoreItems[index].itemScore = String(e).replace(
                  /(\d)(\.)(\d{1})(\d*)/,
                  '$1$2$3'
                );
                setScoreItems(_scoreItems);
                validateScoreItems();
              }}
            />
          );
        },
      },
      {
        key: 'operate',
        title: '操作',
        render: (value: any, record: any, index: number) => {
          if (index !== 0) {
            return (
              <span
                className='a-link'
                onClick={() => {
                  const items = _.clone(scoreItems);
                  items.splice(index, 1);
                  setScoreItems(items);
                }}
              >
                删除
              </span>
            );
          }
          return null;
        },
      },
    ];

    return (
      <div className={style.scoreWrap}>
        <Form.Item
          label='计分单位'
          labelCol={{ span: 4 }}
          name='unit'
          rules={[{ required: true }]}
          initialValue='PERSON'
        >
          <XMStaticSelect width={220} dataSet={scoreUnit} allowClear={false} />
        </Form.Item>
        <Form.Item
          label='计分项'
          labelCol={{ span: 4 }}
          required
          help={scoreItemsHelp}
          validateStatus={scoreItemsStatus}
        >
          <Button
            type='primary'
            ghost
            style={{ marginBottom: 12 }}
            onClick={handleClickAddScoreItem}
          >
            添加计分项
          </Button>
          <Table
            bordered
            rowKey='id'
            size='middle'
            className={style.scoreTable}
            columns={scoreColumns}
            dataSource={scoreItems}
            pagination={false}
            style={{ marginBottom: 4 }}
          />
          <div className={style.totalText}>总分：{totalPoint}</div>
        </Form.Item>
      </div>
    );
  }

  return (
    <XMModal
      width={780}
      visible={true}
      title={isEdit ? '编辑考核项' : '添加考核项'}
      okText='保存'
      maskClosable={false}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form labelCol={{ span: 4 }} autoComplete='off' form={form}>
        <Form.Item label='名称' rules={[{ required: true }]} name='name'>
          <Input
            style={{ width: 320 }}
            placeholder='请输入名称，20字以内'
            maxLength={20}
          />
        </Form.Item>
        <Form.Item label='考核说明'>
          {(!isEdit || loaded) && (
            <XMRichTextEditor
              placeholder='请输入考核说明，限制200字'
              limit={200}
              value={description}
              onChange={(length: number, html: any) => {
                if (length > 200) {
                  setOverLimit(true);
                } else {
                  setOverLimit(false);
                }
                setDescription(html);
              }}
            />
          )}
        </Form.Item>
        <Form.Item label='标签' name='tags'>
          <TreeSelect
            style={{ width: 280 }}
            dropdownClassName='tag-select'
            treeData={tagData}
            treeCheckable
            allowClear
            showArrow
            showSearch={false}
            placeholder='请选择标签'
            getPopupContainer={trigger => trigger.parentElement}
          />
        </Form.Item>
        <Form.Item
          label='计分'
          className={calculateScore ? 'score-form-item' : ''}
        >
          <Switch
            checked={calculateScore}
            onChange={value => setCalculateScore(value)}
          />
          {calculateScore && renderScore()}
        </Form.Item>
        <Form.Item label='上传证明' name='uploadCert' initialValue={true}>
          <Radio.Group>
            <Radio value={true}>必须上传</Radio>
            <Radio value={false}>可选上传</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='填写申报详情' name='declared' initialValue={true}>
          <Radio.Group>
            <Radio value={true}>必填</Radio>
            <Radio value={false}>非必填</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </XMModal>
  );
}

export default AddAssessmentsModal;
