import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Modal, Input, Form, message, } from 'antd';
import GaoDeMap from '@/components/GAOMAP/Index'
import User from '@/common/js/user';
import { saveEduTenant, editEduTenant } from '@/data-source/school';

function getQueryVariable(variable: any) {
    let index = window.location.hash.indexOf("?") + 1;
    let query = window.location.hash.substring(index);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
}

function AddSchoolModal(props: any) {
    const {
        tenantName = '',
        linkPhone = '',
        linkName = '',
        latitude = "30.299433",
        longitude = "120.099639",
        address: detail,
        areaCode: district,
        cityCode: city,
        provinceCode: province,

    } = props.info
    const messageId = getQueryVariable("id") || "";
    const [info, setInfo] = useState<any>({
        tenantName,
        linkPhone,
        linkName
    });
    const [hassubmit, setHasSbmit] = useState(false);
    const [cloud, setCloud] = useState(false);
    const [address, setAddress] = useState({
        detail,
        district,
        city,
        province,
        latitude,
        longitude,
        adcode: ''
    });
    const mapRef = useRef<any>(null);

    useEffect(() => {
        mapRef.current && mapRef.current.initialAddress(address.longitude, address.latitude);
    }, [])
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },
    };

    function submit() {
        setHasSbmit(true)
        if (info.tenantName && info.linkName && info.linkPhone && info.linkPhone.length === 11) {
            if (props.type === 'add') {
                saveEduTenant({
                    "address": address.detail,
                    "areaCode": address.adcode,
                    "cityCode": address.adcode?.slice(0, 4) + '00',
                    "latitude": address.latitude,
                    "linkName": info.linkName,
                    "linkPhone": info.linkPhone,
                    "longitude": address.longitude,
                    "provinceCode": address.adcode?.slice(0, 2) + '0000',
                    "tenantId": User.tenantId,
                    "tenantName": info.tenantName
                }).then((res) => {
                    message.success(res.message);
                    props.onClose()
                })
            } else {
                editEduTenant({
                    "address": address.detail,
                    "areaCode": address.adcode,
                    "cityCode": address.adcode?.slice(0, 4) + '00',
                    "latitude": address.latitude,
                    "linkName": info.linkName,
                    "linkPhone": info.linkPhone,
                    "longitude": address.longitude,
                    "provinceCode": address.adcode?.slice(0, 2) + '0000',
                    "tenantId": User.tenantId,
                    "tenantName": info.tenantName,
                    id:props.info.id
                }).then((res) => {
                    message.success(res.message)
                    props.onClose()
                })
            }

        }
    }


    return <Modal width={680} title={props.type === 'add' ? '添加学校' : '编辑学校'} visible={true} onOk={submit} onCancel={props.onClose}>
        <Form {...formItemLayout}>
            <Form.Item
                label='学校名称'
                required
                validateStatus={(hassubmit && !info.tenantName) ? "error" : undefined}
                help={(hassubmit && !info.tenantName) ? "学校名称不能未空" : undefined}
            >
                <Input
                    placeholder='请输入学校名称，40字以内'
                    style={{ width: 350 }}
                    value={info.tenantName}
                    maxLength={40}
                    onChange={(e: any) => {
                        const _info = { ...info };
                        _info.tenantName = e.target.value;
                        setInfo(_info)
                    }}></Input>

            </Form.Item>
            <Form.Item
                label='联系人姓名'
                required
                validateStatus={hassubmit && !info.linkName ? "error" : undefined}
                help={hassubmit && !info.linkName ? "请输入联系人姓名" : undefined}
            >
                <Input
                    style={{ width: 220 }}
                    placeholder='请输入联系人姓名，10字以内'
                    maxLength={10}
                    value={info.linkName}
                    onChange={(e: any) => {
                        const _info = { ...info };
                        _info.linkName = e.target.value;
                        setInfo(_info)
                    }}></Input>
            </Form.Item>
            <Form.Item
                label='联系人手机'
                required
                validateStatus={hassubmit && (info.linkPhone.length != 11) ? "error" : undefined}
                help={hassubmit && (info.linkPhone.length != 11) ? "请输入正确的手机号" : undefined}
            >
                <Input
                    placeholder='请输入联系人手机号'
                    maxLength={11}
                    style={{ width: 220 }}
                    value={info.linkPhone}
                    onChange={(e: any) => {
                        const _info = { ...info };
                        _info.linkPhone = e.target.value;
                        setInfo(_info)
                    }}></Input>
            </Form.Item>
            <Form.Item
                name="area"
                label="标记地址"
                required>
                <Input value={`${address.province} / ${address.city || address.province} / ${address.district}`} style={{ marginBottom: 12, width: 240 }} disabled />
                <Input style={{ marginBottom: 12 }} maxLength={30} onChange={(e: any) => {
                    const _address = { ...address };
                    _address.detail = e.target.value;
                    setAddress(_address);
                }} value={address.detail} placeholder='请输入详细地址，例如XX路XX号（最多30个字）' />
                <GaoDeMap
                    width="500px"
                    height="400px"
                    mapRef={mapRef}
                    edit={false}
                    disabled={cloud}
                    changeAddress={(address: any, type: any) => {
                        address.detail = `${address.township}${address.street}${address.streetNumber}`
                        setAddress(address)

                    }}></GaoDeMap>
            </Form.Item>
        </Form>


    </Modal>
}

export default AddSchoolModal
