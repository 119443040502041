/*
 * @Author: liuqing
 * @Date: 2021-09-10 09:58:57
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-19 14:22:31
 * @Description: 入口组件
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router';
import { Layout } from 'antd';
import { MainRouter } from '@/routes';
import Header from '@/modules/root/Header';
import Aside from '@/modules/root/Aside';
import { getAdminFullDetailById } from '@/data-source/admin';
import User from '@/common/js/user';
import { AppContext } from '@/store';
import { setStorePermission, setStoreUserInfo } from '@/store/actions';
import { IMenuItem, menuList } from '@/routes/config/menu';
import Permission from '@/common/js/permissions';
import RCHistory from '@/routes/history';

const { Content } = Layout;

function App() {
  const ctx = useContext<any>(AppContext);
  const location = useLocation();
  const [menu, setMenu] = useState<IMenuItem[]>([]);

  useEffect(() => {
    getAdminFullDetailById((User as any).uid).then(res => {
      const { authPermissionList } = res.result;
      const permissionCode = authPermissionList.map(
        (item: any) => item.groupCode
      );
      const menu = menuList.filter((item: IMenuItem) =>
      permissionCode.includes(item.groupCode)
      );
      ctx.dispatch(setStorePermission(authPermissionList));
      ctx.dispatch(setStoreUserInfo(res.result));
      window.accountType = res.result.accountType;
      setMenu(menu);

      // 暂时重定向到考核表
      if (location.pathname === '/') {
        const permission = new Permission(permissionCode);
        if (permission.hasEduAssessTable()) {
          RCHistory.replace('/edu/examine');
        } else if (permission.hasSchAssessTask()) {
          RCHistory.replace('/sch/examine');
        }
      }
    });
  }, []);

  return (
    <div className='app xm_glx_app'>
      <div className='content__body'>
        <div className='left'>
          <Aside menu={menu} />
        </div>
        <div className='right'>
          <div className='header'>
            <Header />
          </div>
          <div className='main-container'>
            <Content>
              <MainRouter />
            </Content>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(App);
