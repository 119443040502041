/*
 * @Author: liuqing
 * @Date: 2021-10-09 18:32:13
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-16 17:00:51
 * @Description: 学校端用户管理
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Divider, Form, Input, message, Modal } from 'antd';
import {
  Page,
  XMClearFilter,
  XMModal,
  XMSearchInput,
  XMTable,
} from '@/components';
import { ColumnsType } from 'antd/es/table';
import _ from 'underscore';
import {
  delSchAdmin,
  editSchAdmin,
  getSchAdminByPage,
} from '@/data-source/admin';

function SchUser() {
  const defaultQuery = {
    current: 1,
  };

  const [currentUserId, setCurrentUserId] = useState('');
  const [total, setTotal] = useState(0);
  const [records, setRecords] = useState([]);
  const [query, setQuery] = useState<any>(defaultQuery);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    getData();
  }, [query]);

  function getData() {
    getSchAdminByPage(query).then(res => {
      const { total, records } = res.result;
      setRecords(records);
      setTotal(total);
    });
  }

  function handleClickEdit(record: any) {
    setModalVisible(true);
    setCurrentUserId(record.id);
    form.setFieldsValue({
      name: record.name,
    });
  }

  function handleClickDelete(record: any) {
    XMModal.danger({
      title: '你确定要删除该用户吗？',
      content: `删除后，将删除“${record.name}”相关联的数据。`,
      okText: '确定删除',
      onOk: () => {
        delSchAdmin(record.id).then(() => {
          message.success('操作成功');
          getData();
        });
      },
    });
  }

  function handlePageChange(page: number) {
    const _query = _.clone(query);
    _query.current = page;
    setQuery(_query);
  }

  function handleOk() {
    form.validateFields().then(values => {
      const params = {
        ...values,
        id: currentUserId
      }
      editSchAdmin(params).then(() => {
        setModalVisible(false);
        getData();
        message.success('保存成功');
      });
    });
  }

  function renderColumns(): ColumnsType<any> {
    return [
      {
        key: 'name',
        dataIndex: 'name',
        title: '用户',
      },
      {
        key: 'account',
        dataIndex: 'account',
        title: '手机号',
      },
      {
        key: 'operator',
        dataIndex: 'operator',
        title: '操作',
        render: (value, record) => {
          return (
            <>
              <span className='a-link' onClick={() => handleClickEdit(record)}>
                编辑
              </span>
              <Divider type='vertical' />
              <span
                className='a-link'
                onClick={() => handleClickDelete(record)}
              >
                删除
              </span>
            </>
          );
        },
      },
    ];
  }

  return (
    <Page title='用户管理'>
      <div className='page__top__filter__wrap'>
        <div className='filter__right__wrap'>
          <XMSearchInput
            className='filter__item'
            placeholder='请输入员工姓名'
            value={query.name}
            onChange={_.debounce(
              (e: any) =>
                setQuery({ ...query, name: e.target.value, current: 1 }),
              200
            )}
            onSearch={value => {
              setQuery({
                ...query,
                name: value,
                current: 1,
              });
            }}
          />
        </div>
        <XMClearFilter
          onClick={() => {
            setQuery(defaultQuery);
          }}
        />
      </div>
      <div className='tip-message'>
        用户共
        <span style={{ color: '#333', fontWeight: 'bolder' }}>{total}</span>人
      </div>
      <XMTable
        dataSource={records}
        rowKey='id'
        columns={renderColumns()}
        current={query.current}
        total={total}
        onPageChange={handlePageChange}
      />
      <Modal
        width={480}
        title='编辑用户'
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleOk}
      >
        <Form
          labelCol={{ span: 5 }}
          form={form}
          autoComplete='off'
          initialValues={{}}
        >
          <Form.Item label='真实姓名' name='name' rules={[{ required: true }]}>
            <Input
              placeholder='请输入真实姓名'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  );
}

export default withRouter(SchUser);
