/*
 * @Author: liuqing
 * @Date: 2021-10-19 13:59:23
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-19 14:22:14
 * @Description: 权限
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
class Permission {
  permissionCodes: any;
  constructor(codes: any) {
    this.permissionCodes = codes;
  }

  getInstance() {
    return this;
  }

  hasEduAssessTable() {
    return this.permissionCodes.includes('EduAssessTable');
  }

  hasSchAssessTask() {
    return this.permissionCodes.includes('SchAssessTask');
  }
}

export default Permission;
