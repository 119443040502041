/*
 * @Author: liuqing
 * @Date: 2021-09-22 14:14:48
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-14 14:26:26
 * @Description: 用户管理路由
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import { MenuConfig } from '@/routes/interface';

import { EduUser, SchUser } from '@/modules/user';
import UserSetting from '@/modules/user/UserSetting';
import School from '@/modules/school/Index';

const UserConfig: MenuConfig = {
  key: 'user',
  name: '用户管理',
  routes: [
    {
      key: 'userManagement',
      name: '用户管理',
      path: '/edu/user',
      component: EduUser,
    },
    {
      key: 'userManagement',
      name: '用户管理',
      path: '/sch/user',
      component: SchUser,
    },
    {
      key: 'userManagement',
      name: '用户设置',
      path: '/setting',
      component: UserSetting,

    },
    {
      key: 'userManagement',
      name: '用户设置',
      path: '/school',
      component: School,
    }
  ],
};

export default UserConfig;
