/*
 * @Author: 陈剑宇
 * @Date: 2020-05-07 14:43:01
 * @LastEditTime: 2021-10-25 11:07:27
 * @LastEditors: liuqing
 * @Description: 
 * @FilePath: /wheat-web-demo/src/domains/basic-domain/constants.ts
 */
import { MapInterface } from '@/domains/basic-domain/interface'

// 默认是 dev 环境
const ENV: string = process.env.DEPLOY_ENV || 'dev';

const BASIC_HOST_MAP: MapInterface = {
  dev: 'https://dev-heimdall.xiaomai5.com/',
  dev1: 'https://dev1-heimdall.xiaomai5.com/',
  rc: 'https://rc-heimdall.xiaomai5.com/',
  gray: 'https://gray-heimdall.xiaomai5.com/',
  prod: 'https://portal.xiaomai7.com/'
};

// axios headers config
export const TIME_OUT: number = 20000;
export const PROJECT = 'edubff';
export const PREFIX = 'edu';
// host
export const BASIC_HOST: string = BASIC_HOST_MAP[ENV];
