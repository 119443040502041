/*
 * @Author: 吴文洁
 * @Date: 2020-08-20 09:20:43
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-18 11:01:11
 * @Description:
 * @Copyright: 杭州杰竞科技有限公司 版权所有
 */

import moment from "moment";

// 限制字数
const getEllipsText = (text: string, limitNum: number) => {
  const limitText: string = text.replace(/\n/g, ' ');

  if (limitText.length > limitNum) {
    return `${limitText.substr(0, limitNum)}...`;
  }
  return limitText;
};

/**
 * 获取url参数
 */
export const getUrlParams = (name: string) => {
  let url = decodeURIComponent(window.location.href);
  if (url.indexOf('?') === -1) {
    return '';
  }
  let temp1 = url.split('?');
  let pram = temp1[1];
  let keyValue = pram.split('&');
  let obj: any = {};
  for (let i = 0; i < keyValue.length; i++) {
    let item = keyValue[i].split('=');
    let key = item[0];
    let value = item[1];
    obj[key] = value;
  }
  return obj[name];
};

export const formatDate = (timestamp: number | undefined, formatStr = 'YYYY-MM-DD') => {
  return moment(timestamp).format(formatStr);
}

export const randomString = (strLen: number = 32) => {
  const chars: string = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = chars.length;

  let pwd: string = '';
  for (let i: number = 0; i < strLen; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd;
}

export const getBlob = (url: any,filename:any) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest()

    xhr.open("GET", url, true)
    xhr.responseType = "blob"
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        if (window.navigator &&(window.navigator as any).msSaveOrOpenBlob) {
          (navigator as any).msSaveBlob(blob, filename)
        } else {
          const link = document.createElement("a")
          const body:any = document.querySelector("body")

          // 创建对象url
          link.href = window.URL.createObjectURL(blob)
          link.download = filename

          body.appendChild(link)

          link.click()
          body.removeChild(link)

          // 通过调用 URL.createObjectURL() 创建的 URL 对象
          window.URL.revokeObjectURL(link.href)
        }
      }
    }
    xhr.send()
  })
}



export { getEllipsText };
