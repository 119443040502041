import React, { useContext, useEffect, useState, useRef } from 'react';
import { Modal, Button } from 'antd';
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import upload from '@/common/js/upload'
import './index.less'

function XMCropper(props: any) {
    const [visible, setVisible] = useState(!!props.visible);
    const [url, setUrl] = useState(props.url);
    const [cropUrl, setCropUrl] = useState(props.url);
    const imgRef = useRef(null);
    const cropperRef = useRef<any>(null);
    const fileRef = useRef<any>(null);

    useEffect(()=>{
        console.log(props.url,'props.url')
        setCropUrl(props.url);
        setUrl(props.url)
    },[props.url])

    useEffect(() => {
        if(!visible){
            return
        }
        if (cropperRef.current) {
            cropperRef.current.destroy();
        }
        if (imgRef.current) {
            cropperRef.current = new Cropper(imgRef.current, {
                viewMode: 1,
                dragMode: 'none',
                initialAspectRatio: 1,
                aspectRatio: 1,
                preview: '.before',
                background: false,
                autoCropArea: 0.6,
                zoomOnWheel: false,
                crop: () => {
                    setCropUrl(cropperRef.current.getCroppedCanvas({
                        imageSmoothingQuality: 'high'
                    }).toDataURL('image/jpeg'))
                }
            })
        }

    }, [url,visible])

    function handleSelect() {
        const file = fileRef.current.files[0];
        if (!file) {
            return
        }
        upload.uploadBlobToOSS(file, 'avatar' + new Date().valueOf()).then((imgAddress: any) => {
            setUrl(imgAddress)
        });
        
    }

    function handleUpload(){
        console.log(cropUrl,'cropUrl')
        cropperRef.current.getCroppedCanvas({
            imageSmoothingQuality: 'high'
        }).toBlob((blob: any) => {
            upload.uploadBlobToOSS(blob, 'avatar' + new Date().valueOf()).then((imgAddress) => {
             props.changeurl(imgAddress);
             setVisible(false)
            });
          });
    }

    return <div className="xmcropper">
        <div className="img">
            <img src={props.url} alt="" />
            <div onClick={() => {
                setVisible(true)
            }} className="bag">
                <div className="icon iconfont">&#xe898;</div>
            </div>

        </div>
        <Modal visible={visible} width={480}
            className='cropperModal'
            title={props.title}
            onCancel={() => {
                setVisible(false)
            }}
            onOk={handleUpload}

        >
            <div className="content">
                <div className="left">
                    <div className="imgBox">
                        <div className="cropImg">
                            <img id='cropImg' ref={(dom: any) => {
                                imgRef.current = dom;
                            }} src={url} alt="" />
                        </div>
                    </div>

                    <Button key="submit" onClick={() => {
                        fileRef.current.click();
                    }} >
                        点击上传
                    </Button>
                </div>
                <div className="right">
                    <div className="img">
                        <p> 预览</p>
                        <img src={cropUrl} alt="" />
                    </div>

                </div>
            </div>

            <input accept="image/jpg,image/jpeg,image/png" onChange={handleSelect} style={{ visibility: 'hidden', position: 'absolute', top: -100 }} type="file" ref={(dom) => {
                fileRef.current = dom
            }} />
        </Modal>
    </div>

}
export default XMCropper