/*
 * @Author: liuqing
 * @Date: 2021-09-30 15:14:54
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-21 09:46:01
 * @Description: 添加已有考核项
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useState } from 'react';
import { XMEllipsis, XMModal, XMSearchInput, XMTable } from '@/components';
import { delAssessItem, getAssessItemByPage } from '@/data-source/assess';
import { message, Tooltip, TableColumnsType, Divider } from 'antd';
import _ from 'underscore';
import { TableRowSelection } from 'antd/lib/table/interface';
import AddAssessmentsModal from './AddAssessmentsModal';
import AssessmentsDetailModal from './AssessmentsDetailModal';
import Bus from '@/domains/basic-domain/bus';

interface IProps {
  defaultSelectedRows: React.Key[];
  onCancel?: () => void;
  onOk?: (selectedRows: any[]) => void;
}

const defaultQuery = {
  current: 1,
};

function AddAssessmentsExistModal(props: IProps) {
  const { defaultSelectedRows: defaultSelected, onCancel, onOk } = props;
  const [modal, setModal] = useState<any>(null);
  const [query, setQuery] = useState<any>(defaultQuery);
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);

  const [allSelectedRows, setAllSelectedRows] = useState<any>(defaultSelected); // 所有选中的项
  const [allSelectedRowKeys, setAllSelectedRowKeys] = useState<any>(
    _.pluck(defaultSelected, 'id')
  );
  const [selectedRows, setSelectedRows] = useState<any>(defaultSelected); // 当页选中的项
  const [beforeRows, setBeforeRows] = useState<any>(defaultSelected); // 当页选中的项

  useEffect(() => {
    getData();
  }, [query]);

  useEffect(() => {
    setAllSelectedRowKeys(_.pluck(allSelectedRows, 'id'));
  }, [allSelectedRows]);

  async function getData() {
    return getAssessItemByPage(query).then(res => {
      const { total, records } = res.result;

      const ids = _.pluck(res.result.records, 'id');
      const _selectedRows: any[] = [];
      const _selectedRowsKeys: any = [];
      const _beforeRows: any = [];
      allSelectedRows.map((item: any) => {
        if (ids.indexOf(item.id) !== -1) {
          _selectedRows.push(records[ids.indexOf(item.id)]);
          _selectedRowsKeys.push(item.id);
        } else {
          _beforeRows.push(item);
        }
      });
      setSelectedRows(_selectedRows);
      setAllSelectedRowKeys(_selectedRowsKeys);
      setBeforeRows(_beforeRows);
      setAllSelectedRows([..._selectedRows, ..._beforeRows]);
      setRecords(records);
      setTotal(total);
    });
  }

  const columns: TableColumnsType = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '名称',
      render: value => {
        return <XMEllipsis text={value} length={8} hyphen />;
      },
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: '考核说明',
      render: value => {
        if (!value) {
          return '-';
        }
        if (value.length > 8) {
          return (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: value }}></div>}
            >
              <div style={{ display: 'inline-flex' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: value.substring(0, 8),
                  }}
                ></div>
                <span>...</span>
              </div>
            </Tooltip>
          );
        }
        return <div dangerouslySetInnerHTML={{ __html: value }}></div>;
      },
    },
    {
      key: 'tagNames',
      dataIndex: 'tagNames',
      title: '标签',
      render: value => <XMEllipsis text={value.join('/')} length={8} hyphen />,
    },
    {
      key: 'calculateScore',
      dataIndex: 'calculateScore',
      title: '计分',
      render: value => (value ? '是' : '否'),
    },
    {
      key: 'totalPoint',
      dataIndex: 'totalPoint',
      title: '分数',
      render: (value, record: any) => {
        if (record.calculateScore) {
          return value;
        }
        return '-';
      },
    },
    {
      key: 'uploadCert',
      dataIndex: 'uploadCert',
      title: '上传证明',
      render: (value: boolean) => {
        return value ? '必填' : '非必填';
      },
    },
    {
      key: 'declared',
      dataIndex: 'declared',
      title: '填写申报详情',
      render: (value: boolean) => {
        return value ? '必填' : '非必填';
      },
    },
    {
      key: 'operation',
      dataIndex: 'operation',
      title: '操作',
      render: (value, record: any) => {
        const close = () => setModal(null);
        // 考核项已经被关联只能查看
        if (value) {
          return (
            <>
              <span
                className='a-link'
                onClick={() => {
                  const modal = (
                    <AddAssessmentsModal
                      id={record.id}
                      onCancel={close}
                      onOk={() => {
                        close();
                        getData();
                      }}
                    />
                  );
                  setModal(modal);
                }}
              >
                编辑
              </span>
              <Divider type='vertical' />
              <span
                className='a-link'
                onClick={() => {
                  XMModal.danger({
                    title: '你确定要删除当前考核项么？',
                    content: '删除后，将无法恢复哦。',
                    okText: '确认删除',
                    onOk: () => {
                      delAssessItem(record.id).then(() => {
                        getData().then(() => {
                          Bus.trigger(
                            'assessments_modal_delete_item',
                            record.id
                          );
                          message.success('删除成功');
                        });
                      });
                    },
                  });
                }}
              >
                删除
              </span>
            </>
          );
        }
        return (
          <span
            className='a-link'
            onClick={() => {
              const modal = (
                <AssessmentsDetailModal id={record.id} onCancel={close} />
              );
              setModal(modal);
            }}
          >
            查看
          </span>
        );
      },
    },
  ];

  function getRowSelection(): TableRowSelection<any> {
    return {
      selectedRowKeys: allSelectedRowKeys,
      onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
        setAllSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
        setAllSelectedRows([...selectedRows, ...beforeRows]);
      },
    };
  }

  function handlePageChange(page: number) {
    const _query = _.clone(query);
    _query.current = page;
    setQuery(_query);
  }

  function handleOk() {
    if (records.length === 0) {
      return message.warn('请先新建考核项哦');
    }
    if (beforeRows.length === 0 && allSelectedRowKeys.length === 0) {
      return message.warn('请先勾选考核项哦');
    }
    onOk && onOk(allSelectedRows);
  }

  return (
    <XMModal
      title='添加已有考核项'
      visible
      width={876}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <XMSearchInput
        width={200}
        placeholder='输入考核项名称'
        style={{ marginBottom: 16 }}
        value={query.name}
        onChange={_.debounce(
          (e: any) => setQuery({ ...query, name: e.target.value }),
          200
        )}
        onSearch={value => {
          setQuery({
            ...query,
            name: value,
          });
        }}
      />
      <XMTable
        rowKey='id'
        rowSelection={getRowSelection()}
        dataSource={records}
        columns={columns}
        total={total}
        current={query.current}
        onPageChange={handlePageChange}
        paginationSize='small'
      />
      {modal}
    </XMModal>
  );
}

export default AddAssessmentsExistModal;
