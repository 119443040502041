/*
 * @Author: liuqing
 * @Date: 2021-09-24 18:07:51
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-21 10:36:45
 * @Description: 新建考核表
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { withRouter } from 'react-router';
import {
  Button,
  Form,
  Input,
  Switch,
  Radio,
  Table,
  InputNumber,
  Tooltip,
  TableColumnsType,
  message,
} from 'antd';
import {
  CategorySelect,
  Page,
  XMEllipsis,
  XMModal,
  XMRichTextEditor,
} from '@/components';
import style from './AddExamine.module.less';
import AddAssessmentsExistModal from './AddAssessmentsExistModal';
import AddAssessmentsModal from './AddAssessmentsModal';
import RCHistory from '@/routes/history';
import _ from 'underscore';
import {
  editAssessTable,
  getAssessTableDetail,
  saveAssessTable,
} from '@/data-source/assess';
import { getUrlParams } from '@/domains/basic-domain/utils';
import { TStandardType } from '@/common/js/enum';
import Bus from '@/domains/basic-domain/bus';

function AddExamine() {
  const id = getUrlParams('id');
  const [modal, setModal] = useState<any>(null);
  const [loaded, setLoaded] = useState<any>(false);
  const [scoreItems, setScoreItems] = useState<any>([]);
  const [totalPoint, setTotalPoint] = useState(0);
  const [calculateCount, setCalculateCount] = useState(0);
  const [notCalculateCount, setNotCalculateCount] = useState(0);
  const [standardSetting, setStandardSetting] = useState<boolean>(true);
  const [standardType, setStandardType] = useState<TStandardType>('PASSITEM');
  const [standardCount, setStandardCount] = useState<number | string>('');
  const [overLimit, setOverLimit] = useState<boolean>(false);
  const [description, setDescription] = useState<any>('');
  const [form] = Form.useForm();
  const scoreItemsRef = useRef<any>(null);

  const isEdit = useMemo(() => !!id, [id]);

  useEffect(() => {
    Bus.bind('assessments_modal_delete_item', (id: string) => {
      const _items = scoreItemsRef.current.filter(
        (item: any) => item.id !== id
      );
      setScoreItems(_items);
    });
    return () => {
      Bus.unbind('assessments_modal_delete_item');
    };
  }, []);

  useEffect(() => {
    Bus.bind('assessments_modal_update_item', ({ id, item }: any) => {
      scoreItemsRef.current.map((_item: any, index: any) => {
        if (_item.id === id) {
          const _scoreItems = [...scoreItemsRef.current];
          _scoreItems.splice(index, 1);
          _scoreItems.splice(index, 0, item);
          setScoreItems(_scoreItems);
        }
      });
    });
    return () => {
      Bus.unbind('assessments_modal_update_item');
    };
  }, []);

  useEffect(() => {
    scoreItemsRef.current = scoreItems;
  }, [scoreItems]);

  useEffect(() => {
    if (isEdit) {
      getDetail();
    }
  }, [isEdit]);

  useEffect(() => {
    let total = 0;
    let calculateCount = 0;
    let notCalculateCount = 0;
    scoreItems.map((item: any) => {
      total += item.totalPoint;
      if (item.calculateScore) {
        calculateCount++;
      } else {
        notCalculateCount++;
      }
    });
    if (calculateCount <= 0) {
      setStandardType('PASSITEM');
      setStandardCount('');
    }
    setTotalPoint(total);
    setCalculateCount(calculateCount);
    setNotCalculateCount(notCalculateCount);
  }, [scoreItems]);

  function getDetail() {
    getAssessTableDetail(id).then(res => {
      const {
        name,
        categoryId,
        description,
        assessItemList = [],
        standardSetting,
        standardType,
        standardCount,
      } = res.result || {};
      form.setFieldsValue({
        name,
        categoryId,
      });
      setDescription(description);
      setScoreItems(assessItemList);
      setStandardSetting(standardSetting);
      setStandardType(standardType);
      setStandardCount(standardCount);
      setLoaded(true);
    });
  }

  function close() {
    setModal(null);
  }

  function handleClickAddExist() {
    const modal = (
      <AddAssessmentsExistModal
        onCancel={close}
        defaultSelectedRows={scoreItems}
        onOk={selectedRows => {
          close();
          setScoreItems(selectedRows);
        }}
      />
    );
    setModal(modal);
  }

  function handleClickAddAssessments() {
    const modal = (
      <AddAssessmentsModal
        onCancel={close}
        onOk={result => {
          close();
          if (result) {
            const items = _.clone(scoreItems);
            items.push(result);
            setScoreItems(items);
          }
        }}
      />
    );
    setModal(modal);
  }

  function handleGoBack() {
    // TODO: 是否已填写
    XMModal.confirm({
      title: '你确定要返回吗？',
      content: '填写后的信息将会清空',
      cancelText: '点错了',
      okText: '确认返回',
      onOk: () => {
        RCHistory.goBack();
      },
    });
  }

  function handleSave() {
    if (overLimit) {
      return message.warn('考核表说明超出字数了');
    }
    if (scoreItems.length === 0) {
      return message.warn('请添加考核项');
    }
    if (standardSetting) {
      if (standardType === 'PASSITEM') {
        if (!standardCount) {
          return message.warn('请添加达标项数');
        }
        if (standardCount > calculateCount) {
          return message.warn('通过项不能大于总计分项');
        }
      } else {
        if (!standardCount) {
          return message.warn('请添加达标分数');
        }
        if (standardCount > totalPoint) {
          return message.warn('得分不能大于总分');
        }
      }
    }
    form.validateFields().then(values => {
      const params = {
        ...values,
        assessItemList: scoreItems.map((item: any) => item.id),
        description,
        standardSetting,
        standardType,
        standardCount,
      };
      if (isEdit) {
        params.id = id;
        editAssessTable(params).then(() => {
          message.success('保存成功');
          RCHistory.push('/edu/examine');
        });
      } else {
        saveAssessTable(params).then(() => {
          message.success('保存成功');
          RCHistory.push('/edu/examine');
        });
      }
    });
  }

  function enhanceText(text: string | number) {
    return <span style={{ color: '#2966FF' }}> {text}</span>;
  }

  // 考核项表格列
  function renderItemColumns(): TableColumnsType<object> {
    return [
      {
        key: 'name',
        dataIndex: 'name',
        title: '名称',
        render: value => <XMEllipsis text={value} length={8} hyphen />,
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: '考核说明',
        render: value => {
          if (!value) {
            return '-';
          }
          if (value.length > 8) {
            return (
              <Tooltip
                title={<div dangerouslySetInnerHTML={{ __html: value }}></div>}
              >
                <div style={{ display: 'inline-flex' }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: value.substring(0, 8),
                    }}
                  ></div>
                  <span>...</span>
                </div>
              </Tooltip>
            );
          }
          return value;
        },
      },
      {
        key: 'tagNames',
        dataIndex: 'tagNames',
        title: '标签',
        render: value => {
          if (value.length === 0) {
            return '-';
          }
          return value.join('/');
        },
      },
      {
        key: 'calculateScore',
        dataIndex: 'calculateScore',
        title: '计分',
        render: value => (value ? '是' : '否'),
      },
      {
        key: 'totalPoint',
        dataIndex: 'totalPoint',
        title: '分数',
        render: (value, record: any) => {
          if (record.calculateScore) {
            return value;
          }
          return '-';
        },
      },
      {
        key: 'uploadCert',
        dataIndex: 'uploadCert',
        title: '上传证明',
        render: value => (value ? '必填' : '非必填'),
      },
      {
        key: 'declared',
        dataIndex: 'declared',
        title: '填写申报详情',
        render: value => (value ? '必填' : '非必填'),
      },
      {
        key: 'operation',
        dataIndex: 'operation',
        title: '操作',
        render: (value, record, index) => {
          return (
            <span
              className='a-link'
              onClick={() => {
                const items = _.clone(scoreItems);
                items.splice(index, 1);
                setScoreItems(items);
              }}
            >
              删除
            </span>
          );
        },
      },
    ];
  }

  return (
    <Page
      breadcrumb={`${isEdit ? '编辑' : '新建'}考核表`}
      onBack={handleGoBack}
      footer={true}
      onCancel={handleGoBack}
      onSave={handleSave}
    >
      <Form labelCol={{ span: 2 }} form={form} autoComplete='off'>
        <div className={style.formItem}>
          <div className='page__item__title'>基本信息</div>
          <Form.Item label='考核名称' rules={[{ required: true }]} name='name'>
            <Input
              style={{ width: 240 }}
              placeholder='请输入考核名称，最多20字'
              maxLength={20}
            />
          </Form.Item>
          <Form.Item
            label='分类'
            rules={[{ required: true, message: '请选择分类' }]}
            name='categoryId'
          >
            <CategorySelect width={240} />
          </Form.Item>
          <Form.Item label='考核表说明'>
            {(!isEdit || loaded) && (
              <XMRichTextEditor
                width={550}
                placeholder='请输入考核表说明，限制为1000字'
                value={description}
                onChange={(length: number, html: any) => {
                  if (length > 1000) {
                    setOverLimit(true);
                  } else {
                    setOverLimit(false);
                  }
                  setDescription(html);
                }}
              />
            )}
          </Form.Item>
        </div>

        <div className={style.formItem}>
          <div className='page__item__title'>考核项管理</div>
          <Form.Item label='考核项' required>
            <div>
              <Button type='primary' onClick={handleClickAddExist}>
                添加已有考核项
              </Button>
              <Button
                style={{ marginLeft: 12 }}
                onClick={handleClickAddAssessments}
              >
                新建考核项
              </Button>
            </div>
            <div style={{ margin: '16px 0 12px' }}>
              考核项共{enhanceText(scoreItems.length)}
              ，总分{enhanceText(totalPoint)}，其中计分项
              {enhanceText(calculateCount)}，不计分项
              {enhanceText(notCalculateCount)}
            </div>
            <Table
              rowKey='id'
              bordered
              size='middle'
              columns={renderItemColumns()}
              dataSource={scoreItems}
              pagination={false}
            />
          </Form.Item>
        </div>

        <div className={style.formItem}>
          <div className='page__item__title'>考核通过</div>
          <Form.Item label='达标设置'>
            <Switch
              checked={standardSetting}
              onChange={checked => setStandardSetting(checked)}
            />
          </Form.Item>
          {standardSetting && (
            <Form.Item label='达标类型' className={style.standardType}>
              <Radio.Group
                value={standardType}
                onChange={e => {
                  setStandardCount('');
                  setStandardType(e.target.value);
                }}
              >
                <Radio value='SCORE' disabled={calculateCount <= 0}>
                  按得分
                </Radio>
                <Radio value='PASSITEM'>按照通过项</Radio>
              </Radio.Group>
              <div>
                <Choose>
                  <When condition={standardType === 'SCORE'}>
                    <InputNumber
                      className={style.input}
                      placeholder='请输入分数'
                      min={0}
                      max={totalPoint}
                      value={standardCount}
                      onChange={value => {
                        if (value == null) {
                          value = '';
                        }
                        const val = String(value).replace(
                          /(\d)(\.)(\d{1})(\d*)/,
                          '$1$2$3'
                        );
                        setStandardCount(val);
                      }}
                    />
                    <div className={style.inputTip}>达到该分数线的视为达标</div>
                  </When>
                  <Otherwise>
                    <InputNumber
                      className={style.input}
                      placeholder='请输入项数'
                      min={0}
                      max={scoreItems.length}
                      value={standardCount}
                      onChange={value => {
                        if (value == null) {
                          value = '';
                        }
                        const val = String(value).replace(/\.\d*$/, '');
                        setStandardCount(val);
                      }}
                    />
                    <div className={style.inputTip}>达到该项数视为达标</div>
                  </Otherwise>
                </Choose>
              </div>
            </Form.Item>
          )}
        </div>
      </Form>
      {modal}
    </Page>
  );
}

export default withRouter(AddExamine);
