/*
 * @Author: liuqing
 * @Date: 2021-10-20 16:55:28
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-20 16:59:35
 * @Description: 枚举
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
export type TStandardType = 'SCORE' | 'PASSITEM';

export type TScoreUnit = 'PERSON' | 'PIECES' | 'PERCENT';

export const FileTypeIcon = {
  word: "https://image.xiaomaiketang.com/xm/6biwHTSPe5.png",
  docx: "https://image.xiaomaiketang.com/xm/6biwHTSPe5.png",
  doc: "https://image.xiaomaiketang.com/xm/6biwHTSPe5.png",
  excel: "https://image.xiaomaiketang.com/xm/h6a6eF882a.png", 
  ppt: "https://image.xiaomaiketang.com/xm/847pFAdYGW.png",
  pptx: "https://image.xiaomaiketang.com/xm/847pFAdYGW.png",
  pdf: "https://image.xiaomaiketang.com/xm/rrEJMNkhTG.png",
  mp3: "https://image.xiaomaiketang.com/xm/ykjnSWDyQ6.png",
  mp4: "https://image.xiaomaiketang.com/xm/yK3ASiS8ch.png",
  jpg: "https://image.xiaomaiketang.com/xm/XRkX8JBTPs.png",
  jpeg: "https://image.xiaomaiketang.com/xm/XRkX8JBTPs.png",
  png: "https://image.xiaomaiketang.com/xm/XRkX8JBTPs.png",
  gif: "https://image.xiaomaiketang.com/xm/XRkX8JBTPs.png",
  bmp: "https://image.xiaomaiketang.com/xm/XRkX8JBTPs.png",
  video: 'https://image.xiaomaiketang.com/xm/yK3ASiS8ch.png'
};

export const scoreUnitMap = {
  PERSON: '人',
  PIECES: '个',
  PERCENT: '百分比',
};
