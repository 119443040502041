import { MenuConfig } from '@/routes/interface';

import Tag from '@/modules/tag';

const TagConfig: MenuConfig = {
  key: 'categoryManage',
  name: '标签管理',
  routes: [
    {
      key: 'categoryManage',
      name: '标签管理',
      path: '/tag',
      exact: true,
      component: Tag,
    },
  ],
};

export default TagConfig;
