import { MenuConfig } from '@/routes/interface';

import Category from '@/modules/category';

const HomeConfig: MenuConfig = {
  key: 'categoryManage',
  name: '分类管理',
  routes: [
    {
      key: 'category',
      name: '分类管理',
      path: '/category',
      exact: true,
      component: Category,
    },
  ],
};

export default HomeConfig;
