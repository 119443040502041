/*
 * @Author: liuqing
 * @Date: 2021-09-23 16:57:39
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-11 13:42:34
 * @Description: 表格和分页器的二次封装
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React from 'react';
import { Table, TableProps, Pagination, PaginationProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import style from './index.module.less';

interface XMTableProps extends TableProps<any> {
  size?: SizeType;
  paginationSize?: 'small' | 'default';
  total?: number;
  current?: number;
  onPageChange?: PaginationProps['onChange'];
}

function XMTable(props: XMTableProps) {
  const {
    size = 'middle',
    paginationSize = 'default',
    total = 0,
    current,
    onPageChange,
    ...restProps
  } = props;

  return (
    <>
      <Table {...restProps} bordered size={size} pagination={false} />
      {total > 0 && (
        <>
          <div className={style.paginationWrap}>
            <div>共{total}条数据</div>
            <Pagination
              showQuickJumper
              showSizeChanger={false}
              showTitle={false}
              total={total}
              size={paginationSize}
              current={current}
              onChange={onPageChange}
            />
          </div>
        </>
      )}
    </>
  );
}

export default XMTable;
