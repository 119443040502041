import React, { useState, useEffect } from 'react';
import { Form, Input, message, Modal, Select } from 'antd';
import { saveTag, editTag } from '@/data-source/tag';
import User from '@/common/js/user';
const { Option } = Select;

function AddTagGroup(props: any) {
    const [hassubmit, setHasSbmit] = useState(false);
    const [info, setInfo] = useState<any>({
        bizId:props.group.id,
        name:props.tag?.name
    });

    function handleOk() {
        const { name, bizId } = info;
        if (!name || !bizId) {
            return
        }
        if (props.type === 'add') {
            saveTag({
                name, bizId
            }).then(() => {
                message.success('添加成功');
                props.fresh();
                props.close();
            })
        } else {
            editTag({
               name, bizId,
               id:props.tag.id
            }).then(() => {
                message.success('编辑成功');
                props.fresh();
                props.close()
            })
        }


    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },
    };

    return <Modal
        width={480}
        visible={true}
        title={props.type == 'add' ? '添加标签' : '编辑标签'}
        onOk={handleOk}
        onCancel={() => props.close()}
    >
        <Form {...formItemLayout}>
            <Form.Item
                label='标签组'
                required
                validateStatus={hassubmit && !info.bizId ? "error" : undefined}
                help={hassubmit && !info.bizId ? "请选择标签组" : undefined}
            >
                <Select value={info.bizId}
                    placeholder='请选择标签组'
                    onChange={(value) => {
                        const _info = { ...info };
                        _info.bizId = value;
                        setInfo(_info)
                    }}>
                    {
                        props.groupList.map((item: any) => {
                            return <Option key={item.id} value={item.id}>{item.groupName}</Option>
                        })
                    }
                </Select>

            </Form.Item>
            <Form.Item
                label='名称'
                required
                validateStatus={hassubmit && !info.bizId ? "error" : undefined}
                help={hassubmit && !info.bizId ? "请输入名称" : undefined}
            >
                <Input
                    placeholder='请输入名称，8字以内'
                    style={{ width: 220 }}
                    value={info.name}
                    maxLength={8}
                    onChange={(e: any) => {
                        const _info = { ...info };
                        _info.name = e.target.value;
                        setInfo(_info)

                    }}></Input>

            </Form.Item>
        </Form>
    </Modal>

}

export default AddTagGroup
