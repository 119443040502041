import React, { useState, useEffect } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { saveTagGroup, editTagGroup } from '@/data-source/tag';
import User from '@/common/js/user';

function AddTagGroup(props: any) {
    const [groupName, setGroupName] = useState(props.item?.groupName)

    function handleOk() {
        if (!groupName) {
            message.warning('请输入名称，8字以内')
            return
        }
        if (props.type === 'add') {
            saveTagGroup({
                "groupName": groupName,
                "tenantId": User.tenantId,
            }).then(() => {
                message.success('添加成功');
                props.fresh();
                props.close();
            })
        } else {
            editTagGroup({
                "groupName": groupName,
                "id": props.item?.id
            }).then(() => {
                message.success('编辑成功');
                props.fresh();
                props.close()
            })
        }


    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },
    };

    return <Modal
        width={480}
        visible={true}
        title={props.type=='add'?'添加标签组':'编辑标签组'}
        onOk={handleOk}
        onCancel={() => props.close()}
    >
        <Form {...formItemLayout}>
            <Form.Item
                label='名称'
                required

            >
                <Input
                    placeholder='请输入名称，8字以内'
                    style={{ width: 220 }}
                    value={groupName}
                    maxLength={8}
                    onChange={(e: any) => {
                        setGroupName(e.target.value)
                    }}></Input>

            </Form.Item>
        </Form>
    </Modal>

}

export default AddTagGroup
