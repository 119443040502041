import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Tree, Button, message, Space, Dropdown, Menu, Modal } from 'antd';
import {
    Page,
    XMModal,
    XMSearchInput,
} from '@/components';
import OpearteCourseCategoryModal from './OpearteCourseCategoryModal'
import User from '@/common/js/user';
import { delEduCategory, getEduCategoryTreeList, moveEduCategory } from '@/data-source/category';
import './index.less'
import _ from 'underscore';
const { DirectoryTree } = Tree;


function Category(props: any) {
    const [originTreeData, setOriginTreeData] = useState([])
    const [modal, setModal] = useState<any>(null)
    // const [treeData, setTreeData] = useState([])
    const [treeMap, setTreeMap] = useState<any>({})
    const [expandedKeys, setExpandedKeys] = useState<any>([])
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [query, setQuery] = useState<any>({
        tenantId: User.tenantId
    })
    const clickRef = useRef<any>(null);

    useEffect(() => {
        getEduCategoryTree()
    }, [query])


    function getEduCategoryTree() {
        getEduCategoryTreeList(query).then((res) => {
            setOriginTreeData(res.result)
            const treeList = getTreeMap(res.result, {});
            setTreeMap(treeList)
            if (query.categoryName) {
                const _expandedKeys:any = [];
               Object.keys(treeList).map((key: any) => {
                treeList[key].children && treeList[key].children.length && _expandedKeys.push(treeList[key].id)
                })
                setExpandedKeys(_expandedKeys)
            }
        })
    }

    const treeData: any = useMemo(() => {
        return renderTreeNodes(originTreeData, '') || []
    }, [treeMap])

    // 树结构平铺
    function getTreeMap(data: any, map: any, level?: number) {
        data.forEach((item: any) => {
            map[item.id] = item;
            item.categoryLevel = level || 0
            if (item.children && item.children.length > 0) {
                getTreeMap(item.children, map, (level || 0) + 1);
            }
        });

        return map;
    };

    function onDrop(info: any) {
        if (
            (info.node.categoryName === '未分类' && info.dropPosition === 0) ||
            (info.node.categoryName === '未分类' && info.dropToGap && info.dropPosition === -1)
        ) {
            return;
        }
        // 未分类不可以拖拽
        if (info.dragNode.categoryName === '未分类' && info.dragNode.categoryLevel === 0) return message.info('“未分类”为默认分类暂不支持移动');

        let parentId = info.dropToGap ? info.node.parentId : info.node.id;
        moveEduCategory({
            "moveId": info.dragNode.id,
            parentId
        }).then(() => {
            getEduCategoryTree()
        })
    }

    // 新增或编辑分类
    function newEditCourseCategory(categoryType: any, addLevelType: any, type: any, node?: any) {
        let title = '';
        let label = '';
        switch (categoryType) {
            case 'newEqualLevelCategory':
                title = '新增分类';
                label = '分类名称';
                break;
            case 'newChildLevelCategory':
                title = '新增子分类';
                label = '子分类名称';
                break;
            case 'editEqualLevelCategory':
                title = '编辑分类';
                label = '分类名称';
                break;
            case 'editChildLevelCategory':
                title = '编辑子分类';
                label = '子分类名称';
                break;
        }
        const m = (
            <OpearteCourseCategoryModal
                node={node}
                addLevelType={addLevelType}
                type={type}
                title={title}
                label={label}
                close={() => {
                    setModal(null)
                    getEduCategoryTree()
                }}
            />
        );
        setModal(m)

    };

    // 获取相关节点
    function getRelatedNodes(parentId: any) {
        return treeMap[parentId] ? treeMap[parentId].children : [];
    };

    // 更多操作-【重命名 删除】
    function initDropMenu(item: any) {
        return (
            <Menu>
                <Menu.Item key='0'>
                    <span
                        onClick={() => {
                            clickRef.current = true;
                            let categoryType = item.categoryLevel === 0 ? 'editEqualLevelCategory' : 'editChildLevelCategory';
                            newEditCourseCategory(categoryType, 'equal', 'edit', item);
                        }}>
                        重命名
                    </span>
                </Menu.Item>
                <Menu.Item key='1'>
                    <span
                        onClick={() => {
                            clickRef.current = true;
                            XMModal.danger({
                                title: '确认删除该分类吗？',
                                content: '删除后，分类下的所有内容将自动转入“未分类”中。',
                                okText: '确认删除',
                                onOk: () => {
                                    delEduCategory({ id: item.id }).then(() => {
                                        message.success('删除成功')
                                        getEduCategoryTree()
                                    })
                                }
                            })
                        }}>
                        删除
                    </span>
                </Menu.Item>
            </Menu>
        );
    };

    function renderTreeNodes(data: any, value: any) {
        let newTreeData = data.map((item: any) => {
            item.title = item.categoryName;
            item.key = item.id;
            item.title = (
                <div
                    style={{
                        opacity: !value || (value && item.categoryName.indexOf(value) > -1) ? 1 : 0.5,
                    }}
                    className='node-title-div'
                    onMouseOver={(e) => {
                        let mouseNodeOpts = e.currentTarget.getElementsByTagName('div')[0];
                        if (mouseNodeOpts) {
                            mouseNodeOpts.style.visibility = 'visible';
                        }
                    }}
                    onMouseOut={(e) => {
                        let mouseNodeOpts = e.currentTarget.getElementsByTagName('div')[0];
                        if (mouseNodeOpts) {
                            mouseNodeOpts.style.visibility = 'hidden';
                        }
                    }}>
                    <span>{item.categoryName}</span>
                    {item.categoryName !== '未分类' && (
                        <Space className='title-opts' size={16}>
                            <span
                                onClick={(e: any) => {
                                    clickRef.current = true;
                                    if (
                                        (item.categoryLevel === 0 && (originTreeData || []).length >= 29) ||
                                        (item.categoryLevel > 0 && getRelatedNodes(item.parentId).length >= 30)
                                    ) {
                                        return message.info('最多只能添加30个分类');
                                    }
                                    newEditCourseCategory('newEqualLevelCategory', 'equal', 'new', item);
                                }}>
                                <span className='icon iconfont' style={{ color: '#BFBFBF' }}>
                                    &#xe6cd;{' '}
                                </span>
                                <span>同级</span>
                            </span>
                            {item.categoryLevel < 2 && (
                                <span
                                    onClick={() => {
                                        clickRef.current = true;
                                        if (getRelatedNodes(item.id) && getRelatedNodes(item.id).length >= 30) {
                                            message.info('最多只能添加30个子分类');
                                            return;
                                        }
                                        newEditCourseCategory('newChildLevelCategory', 'child', 'new', item);
                                    }}>
                                    <span className='icon iconfont' style={{ color: '#BFBFBF' }}>
                                        &#xe6cc;{' '}
                                    </span>
                                    <span>子级</span>
                                </span>
                            )}
                            <Dropdown overlay={initDropMenu(item)}>
                                <span>
                                    <span className='icon iconfont' style={{ color: '#BFBFBF' }}>
                                        &#xe6cb;{' '}
                                    </span>
                                    <span>更多</span>
                                </span>
                            </Dropdown>
                        </Space>
                    )}
                </div>
            );
            item.icon =
                item.categoryName === '未分类' ? (
                    <img
                        style={{
                            width: '24px',
                            height: '24px',
                            opacity: !value || (value && item.categoryName.indexOf(value) > -1) ? 1 : 0.5,
                        }}
                        src='https://image.xiaomaiketang.com/xm/defaultCategory.png'
                        alt=''
                    />
                ) : (
                    <img
                        style={{
                            width: '24px',
                            height: '24px',
                            opacity: !value || (value && item.categoryName.indexOf(value) > -1) ? 1 : 0.5,
                        }}
                        src='https://image.xiaomaiketang.com/xm/hasCategory.png'
                        alt=''
                    />
                );

            if (item.children) {
                item.children = renderTreeNodes(item.children, value);
            }
            return item;
        });
        return newTreeData;
    };



    return <Page title='分类管理'>
        <div className='filter__right__wrap'>
            <XMSearchInput
                className='filter__item'
                width={200}
                placeholder='搜索分类名称'
                label='搜索名称'
                value={query.categoryName}
                onChange={_.debounce((e: any) => {
                    const _query = { ...query };
                    _query.categoryName = e.target.value
                    if(!e.target.value){
                        setExpandedKeys([])
                    }
                    setQuery(_query)
                }, 300)}
            />
        </div>
        <Button
            type='primary'
            style={{ marginTop: 12 }}
            onClick={() => {
                if ((originTreeData || []).length >= 29) {
                    message.info('最多只能添加30个分类');
                    return;
                }
                newEditCourseCategory('newEqualLevelCategory', 'equal', 'new');
            }}>
            新增一级分类
        </Button>
        <div className={`xm-show-tip `} >
            <span className="icon iconfont">&#xe8b0;</span>
            <p>该分类用于考核表模块</p>
        </div>
        <div className="content  course-category-tree">
            <DirectoryTree
                expandedKeys={expandedKeys}
                // autoExpandParent={autoExpandParent}
                onExpand={(expandedKeys: any) => {
                    console.log(expandedKeys)
                    
                    if (clickRef.current) {
                        clickRef.current = false
                        return
                    }
                    setExpandedKeys(expandedKeys)
                }}
                // selectedKeys={selectedKeys}
                // onSelect={this.onSelect}
                draggable
                blockNode
                onDrop={onDrop}
                treeData={treeData}></DirectoryTree>
        </div>
        {modal}
    </Page>

}

export default Category
