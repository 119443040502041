/*
 * @Author: liuqing
 * @Date: 2021-10-14 14:54:53
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-20 16:57:46
 * @Description: 考核任务表
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useState } from 'react';
import { Badge, TableColumnsType } from 'antd';

import {
  CategorySelect,
  Page,
  XMClearFilter,
  XMSearchInput,
  XMStaticSelect,
  XMTable,
} from '@/components';
import { getSchTaskByPage } from '@/data-source/assess';
import _ from 'underscore';
import { formatDate } from '@/domains/basic-domain/utils';
import style from '@/modules/examine/edu/detail/ExamineDetail.module.less';
import RCHistory from '@/routes/history';
import { TStandardType } from '@/common/js/enum';

const statusMap: any = {
  // default: {
  //   id: undefined,
  //   name: '全部',
  // },
  PROGRESS: {
    id: 'PROGRESS',
    name: '进行中',
    status: 'processing',
  },
  INIT: {
    id: 'INIT',
    name: '未开始',
    status: 'default',
  },
  OVER: {
    id: 'OVER',
    name: '已结束',
    status: 'default',
  },
};

const standardTypeMap = {
  SCORE: '按得分',
  PASSITEM: '按照通过项',
};

function SchExamine() {
  const [query, setQuery] = useState<any>({
    current: 1,
  });
  const [tableList, setTableList] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getData();
  }, [query]);

  function getData() {
    getSchTaskByPage(query).then(res => {
      const { total, records } = res.result;
      setTableList(records);
      setTotal(total);
    });
  }

  const columns: TableColumnsType = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '名称',
    },
    {
      key: 'taskStatus',
      dataIndex: 'taskStatus',
      title: '状态',
      render: value => (
        <Badge status={statusMap[value].status} text={statusMap[value].name} />
      ),
    },
    {
      key: 'categoryName',
      dataIndex: 'categoryName',
      title: '分类',
    },
    {
      key: 'created',
      dataIndex: 'created',
      title: '创建时间',
      render: value => formatDate(value),
    },
    {
      key: 'startDate',
      dataIndex: 'startDate',
      title: '申报时限',
      render: (value, record: any) => {
        return `${formatDate(value)}～${formatDate(record.endDate)}`;
      },
    },
    {
      key: 'standardType',
      dataIndex: 'standardType',
      title: '达标类型',
      render: (value: TStandardType) => standardTypeMap[value],
    },
    {
      key: 'standardStatus',
      dataIndex: 'standardStatus',
      title: '达标状态',
      render: value => {
        if (value === undefined) {
          return '-';
        }
        if (value) {
          return (
            <span
              className={style.standardStatus}
              style={{
                borderColor: '#3BBDAA',
                color: '#3BBDAA',
              }}
            >
              已达标
            </span>
          );
        } else {
          return (
            <span
              className={style.standardStatus}
              style={{
                borderColor: '#FF4F4F',
                color: '#FF4F4F',
              }}
            >
              未达标
            </span>
          );
        }
      },
    },
    {
      key: 'declaredCount',
      dataIndex: 'declaredCount',
      title: '申报项数/总项数',
      render: (value, record: any) => {
        return `${value || 0}/${record.itemCount || 0}`;
      },
    },
    {
      key: 'score',
      dataIndex: 'score',
      title: '得分/总分',
      render: (value, record: any) => {
        return `${value || 0}/${record.totalScore || 0}`;
      },
    },
    {
      key: 'operation',
      dataIndex: 'operation',
      title: '操作',
      render: (value, record: any) => {
        return (
          <span
            className='a-link'
            onClick={() => {
              RCHistory.push(`/sch/examine/detail/${record.id}`);
            }}
          >
            查看
          </span>
        );
      },
    },
  ];

  return (
    <Page title='考核任务表'>
      <div className='page__top__filter__wrap'>
        <div className='filter__right__wrap'>
          <XMSearchInput
            className='filter__item'
            label='名称'
            placeholder='请输入名称'
            value={query.name}
            onChange={_.debounce(
              (e: any) =>
                setQuery({ ...query, name: e.target.value, current: 1 }),
              200
            )}
            onSearch={(value) => {
              setQuery({
                ...query,
                name: value,
                current: 1,
              });
            }}
          />
          <CategorySelect
            className='filter__item'
            showLabel
            value={query.categoryId}
            onChange={value =>
              setQuery({ ...query, categoryId: value, current: 1 })
            }
          />
          <XMStaticSelect
            className='filter__item'
            label='状态'
            placeholder='全部'
            value={query.taskStatus}
            dataSet={_.values(statusMap)}
            onChange={value => {
              setQuery({
                ...query,
                taskStatus: value,
              });
            }}
          />
        </div>
        <XMClearFilter
          onClick={() => {
            setQuery({
              current: 1,
            });
          }}
        />
      </div>
      <XMTable
        rowKey='id'
        columns={columns}
        dataSource={tableList}
        total={total}
        current={query.current}
        onPageChange={page => {
          setQuery({
            ...query,
            current: page,
          });
        }}
      />
  
    </Page>
  );
}

export default SchExamine;
