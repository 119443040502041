import React, { useState, useEffect, useMemo } from 'react';
import { Button, Menu, Dropdown, Input, Divider, Modal, message } from 'antd';
import {
    Page,
    XMSearchInput,
    XMStaticSelect,
    XMTable,
    XMModal
} from '@/components';
import AddTagGroup from './AddTagGroupModal';
import AddTag from './AddTagModel'
import User from '@/common/js/user';
import { getTagGroupList, delTagGroup, getTagByPage, delTag } from '@/data-source/tag';
import './index.less'
import _ from 'underscore';


function Category(props: any) {
    const ali: any = 'center'
    const [modal, setModal] = useState<any>(null);
    const [groupList, setGroupList] = useState<any[]>([])
    const [selectedGroup, setSelectedGroup] = useState<any>({})
    const [tagList, setTagList] = useState([]);
    const [total, setTotal] = useState(0);
    const [handleGroup, setHandleGroup] = useState<any>({})
    const [query, setQuery] = useState<any>({
        current: 1,
        size: 10,
        "tenantId": User.tenantId,
    })

    useEffect(() => {
        getGroupList()
    }, [])

    useEffect(() => {
        if (query.bizId) {
            getTagList()
        }

    }, [query])

    useEffect(() => {
        setQuery({
            ...query,
            bizId: selectedGroup.id
        })

    }, [selectedGroup.id])

    const menu = <Menu>
        <Menu.Item>
            <div onClick={() => {
                handleGroupEdit()
            }} style={{ width: 90, textAlign: 'center' }}>编辑</div>
        </Menu.Item>
        <Menu.Item>
            <div onClick={handleGroupDelete} style={{ width: 90, textAlign: 'center' }}>删除</div>
        </Menu.Item>
    </Menu>

    function getTagList() {
        getTagByPage(query).then((res) => {
            setTagList(res.result.records);
            setTotal(res.result.total)
        })
    }


    function getGroupList() {
        getTagGroupList({ "tenantId": User.tenantId }).then((res: any) => {
            setGroupList(res.result);
            if (_.isEmpty(selectedGroup)) {
                res.result[0] && setSelectedGroup(res.result[0])
            }
            // res.result[0] && setSelectedGroup(res.result[0])
        })
    }

    function addTagGroup() {
        const _modal = <AddTagGroup type='add' fresh={getGroupList} close={() => {
            setModal(null)
        }} name='' />
        setModal(_modal)
    }


    function handleGroupEdit() {
        const _modal = <AddTagGroup fresh={getGroupList} type='edit' close={() => {
            setModal(null)
        }} item={handleGroup} />
        setModal(_modal)
    }

    function handleTagEdit(record: any) {
        const _modal = <AddTag type='edit' tag={record} group={selectedGroup} fresh={() => {
            getGroupList();
            getTagList()
        }} groupList={groupList} close={() => {
            setModal(null)
        }} name='' />
        setModal(_modal)
    }

    function handleTagDelete(record: any) {
        XMModal.danger({
            title: '你确定要删除标签么？',
            okText:'确认删除',
            content: '删除后，选择标签时不显示该标签',
            onOk: () => {
                delTag({ id: record.id }).then(() => {
                    getTagList()
                    getGroupList()
                    message.success('删除成功')
                })
            }
        })
    }


    function handleAddTag() {
        const _modal = <AddTag type='add' group={selectedGroup} fresh={() => {
            getGroupList();
            getTagList()
        }} groupList={groupList} close={() => {
            setModal(null)
        }} name='' />
        setModal(_modal)
    }

    function handleGroupDelete() {
        XMModal.danger({
            title: '你确定要删除标签组么？',
            content: '删除后，标准组下的标签一并清空',
            okText:'确认删除',
            onOk: () => {
                delTagGroup({ id: handleGroup.id }).then(() => {
                    message.success('删除成功');
                    getGroupList()
                })
            }
        })
    }

    function renderColumns() {
        return [
            {
                key: 'name',
                dataIndex: 'name',
                align: ali,
                title: '标签名称',
            },
            {
                key: 'operator',
                align: ali,
                dataIndex: 'operator',
                title: '操作',
                render: (_: any, record: any) => {
                    return (
                        <>
                            <span className='a-link' onClick={() => {
                                handleTagEdit(record)
                            }}>
                                编辑
                            </span>
                            <Divider type='vertical' />
                            <span className='a-link' onClick={() => {
                                handleTagDelete(record)
                            }}>
                                删除
                            </span>
                        </>
                    );
                },
            }
        ]
    }

    if (!groupList.length) {
        return <Page title='标签管理'>
            <Button
                style={{ width: 230, border: '1px solid #E8E8E8', color: '#666' }}
                onClick={addTagGroup}>
                添加标签组
            </Button>

            <div style={{marginTop:60,textAlign:'center',height:350}}>
                <img style={{width:150}} src="https://image.xiaomaiketang.com/xm/hMAsrtiEhe.png" alt="" />
                <p style={{color:'#999',marginTop:-15,position:'relative',left:15}}>请先从左上角添加标签组</p>
            </div>
            {modal}
        </Page>
    }



    return <Page title='标签管理'>
        <div className="tagGroupManage">
            <div className="_left">
                <Button
                    style={{ width: 230, border: '1px solid #E8E8E8', color: '#666' }}
                    onClick={addTagGroup}>
                    添加标签组
                </Button>
                <div className="list">
                    {
                        groupList.map((item, index) => {
                            return <div onClick={() => {
                                setSelectedGroup(item)
                            }} className={selectedGroup.id === item.id ? "item active" : "item"}>
                                <div className="name">{`${item.groupName}(${item.tagCount || 0})`}</div>
                                <div className={item.visible ? "ope visible" : 'ope'}>
                                    <Dropdown overlay={menu} onVisibleChange={(visible) => {
                                        const list = [...groupList];
                                        list[index].visible = visible;
                                        setGroupList(list);
                                        setHandleGroup(list[index])
                                    }} trigger={['click']} placement="bottomCenter" arrow>
                                        <div className="icon iconfont">&#xe927;</div>
                                    </Dropdown>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="_right">
                <div className="head">
                    <Button onClick={handleAddTag} type='primary'>添加标签</Button>
                    <div className="search">
                        <XMSearchInput
                            className='filter__item'
                            width={200}
                            value={query.name}
                            placeholder='请输入标签名称搜索'
                            label=''
                            onChange={(e) => {
                                const _query = { ...query };
                                _query.name = e.target.value;
                                _query.current = 1;
                                setQuery(_query)
                            }}
                        />
                        <span onClick={() => {
                            const _query = { ...query };
                            _query.name = '';
                            _query.current = 1;
                            setQuery(_query)
                        }} className='iconfont filter__clear__icon'>&#xe61b;</span>
                    </div>
                </div>
                <XMTable
                    dataSource={tagList}
                    columns={renderColumns()}
                    total={total}
                    current={query.current}
                    onPageChange={(page) => {
                        setQuery({
                            ...query,
                            current: page
                        })
                    }}
                />
            </div>
        </div>


        {modal}
    </Page>

}

export default Category
