/*
 * @Author: liuqing
 * @Date: 2021-10-11 10:27:17
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-19 10:51:15
 * @Description:
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Divider, message, TableColumnsType } from 'antd';
import {
  Page,
  XMClearFilter,
  XMModal,
  XMSearchInput,
  XMStaticSelect,
  XMTable,
} from '@/components';
import _ from 'underscore';
import { delEduAdmin, getEduAdminByPage } from '@/data-source/admin';
import AddModal from './AddModal';
import { TAccountType } from '@/routes/interface';
import { getEduTenantList } from '@/data-source/school';

const AccountType = [
  {
    id: 'BUREAU',
    name: '管理员',
  },
  {
    id: 'ACADEMIC',
    name: '教务人员',
  },
];

const AccountTypeMap = {
  SUPER: '超级管理员',
  BUREAU: '管理员',
  ACADEMIC: '教务人员',
};

function EduUser() {
  const defaultQuery = {
    current: 1,
  };

  const [modal, setModal] = useState<any>(null);
  const [total, setTotal] = useState(0);
  const [records, setRecords] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [query, setQuery] = useState<any>(defaultQuery);

  useEffect(() => {
    getData();
  }, [query]);

  useEffect(() => {
    getSchoolData();
  }, []);

  function getSchoolData() {
    getEduTenantList().then(res => {
      setSchoolList(res.result || []);
    });
  }

  function getData() {
    getEduAdminByPage(query).then(res => {
      const { total, records } = res.result;
      setRecords(records);
      setTotal(total);
    });
  }

  function handleClickAdd() {
    const close = () => setModal(null);
    const modal = (
      <AddModal
        onCancel={close}
        onOk={() => {
          getData();
          close();
        }}
      />
    );
    setModal(modal);
  }

  function handleClickEdit(record: any) {
    const close = () => setModal(null);
    const modal = (
      <AddModal
        isEdit
        id={record.id}
        onCancel={close}
        onOk={() => {
          getData();
          close();
        }}
      />
    );
    setModal(modal);
  }

  function handleClickDelete(record: any) {
    XMModal.danger({
      title: '你确定要删除该用户吗？',
      content: `删除后，将删除“${record.name}”相关联的数据。`,
      okText: '确定删除',
      onOk: () => {
        delEduAdmin(record.id).then(() => {
          message.success('操作成功');
          getData();
        });
      },
    });
  }

  function handlePageChange(page: number) {
    const _query = _.clone(query);
    _query.current = page;
    setQuery(_query);
  }

  function renderColumns(): TableColumnsType {
    return [
      {
        key: 'name',
        dataIndex: 'name',
        title: '用户',
      },
      {
        key: 'account',
        dataIndex: 'account',
        title: '手机号',
      },
      {
        key: 'tenantName',
        dataIndex: 'tenantName',
        title: '学校',
        render: (value, record: any) => {
          if (record.accountType === 'BUREAU') {
            return '-'
          }
          return value;
        }
      },
      {
        key: 'accountType',
        dataIndex: 'accountType',
        title: '账号类型',
        render: (value: TAccountType) => {
          return AccountTypeMap[value];
        },
      },
      {
        key: 'operator',
        dataIndex: 'operator',
        title: '操作',
        render: (value, record) => {
          return (
            <>
              <span className='a-link' onClick={() => handleClickEdit(record)}>
                编辑
              </span>
              <Divider type='vertical' />
              <span
                className='a-link'
                onClick={() => handleClickDelete(record)}
              >
                删除
              </span>
            </>
          );
        },
      },
    ];
  }

  return (
    <Page title='用户管理'>
      <div className='page__top__filter__wrap'>
        <Button type='primary' onClick={handleClickAdd}>
          添加用户
        </Button>
        <div className='filter__right__wrap'>
          <XMSearchInput
            className='filter__item'
            placeholder='请输入用户姓名'
            label='用户姓名'
            value={query.name}
            onChange={_.debounce(
              (e: any) =>
                setQuery({ ...query, name: e.target.value, current: 1 }),
              200
            )}
            onSearch={value => {
              setQuery({
                ...query,
                name: value,
                current: 1,
              });
            }}
          />
          <XMStaticSelect
            className='filter__item'
            label='学校名称'
            placeholder='请选择学校'
            dataSet={schoolList}
            value={query.tenantId}
            onChange={value => {
              const _query = _.clone(query);
              _query.tenantId = value;
              _query.current = 1;
              setQuery(_query);
            }}
          />
          <XMStaticSelect
            className='filter__item'
            label='身份'
            placeholder='请选择身份'
            value={query.accountType}
            dataSet={AccountType}
            onChange={value => {
              const _query = _.clone(query);
              _query.accountType = value;
              _query.current = 1;
              setQuery(_query);
            }}
          />
        </div>
        <XMClearFilter
          onClick={() => {
            setQuery(defaultQuery);
          }}
        />
      </div>
      <div className='tip-message'>
        用户共
        <span style={{ color: '#333', fontWeight: 'bolder' }}>{total}</span>人
      </div>
      <XMTable
        dataSource={records}
        rowKey='id'
        columns={renderColumns()}
        total={total}
        current={query.current}
        onPageChange={handlePageChange}
      />
      {modal}
    </Page>
  );
}

export default withRouter(EduUser);
