/*
 * @Author: 吴文洁
 * @Date: 2020-08-31 09:34:25
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-15 10:40:16
 * @Description:
 * @Copyright: 杭州杰竞科技有限公司 版权所有
 */

import Storage from './storage';
import { PREFIX } from '@/domains/basic-domain/constants';

class User {
  public set account(v: string) {
    Storage.set(`${PREFIX}_account`, v);
  }
  public get account(): string {
    return Storage.get(`${PREFIX}_account`) || '';
  }

  public set uid(v: string) {
    Storage.set(`${PREFIX}_uid`, v);
  }
  public get uid(): string {
    return Storage.get(`${PREFIX}_uid`) || '';
  }

  public set name(v: string) {
    Storage.set(`${PREFIX}_name`, v);
  }
  public get name(): string {
    return Storage.get(`${PREFIX}_name`) || '';
  }

  public set path(v: string) {
    Storage.set(`${PREFIX}_path`, v);
  }
  public get path(): string {
    return Storage.get(`${PREFIX}_path`) || '';
  }

  public set tenantId(v: string) {
    Storage.set(`${PREFIX}_tenantId`, v);
  }
  public get tenantId(): string {
    return Storage.get(`${PREFIX}_tenantId`) || '';
  }

  public set tenantName(v: string) {
    Storage.set(`${PREFIX}_tenantName`, v);
  }
  public get tenantName(): string {
    return Storage.get(`${PREFIX}_tenantName`) || '';
  }

  public set wechatOpenId(v: string) {
    Storage.set(`${PREFIX}_wechatOpenId`, v);
  }
  public get wechatOpenId(): string {
    return Storage.get(`${PREFIX}_wechatOpenId`) || '';
  }

  public set token(v: string) {
    Storage.set(`${PREFIX}_token`, v);
  }
  public get token(): string {
    return Storage.get(`${PREFIX}_token`) || '';
  }

  public loginOut() {
    Storage.set(`${PREFIX}_wechatOpenId`, '');
    Storage.set(`${PREFIX}_token`, '');
  }
}

export default new User();
