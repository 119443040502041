import { STORE_PERMISSION, STORE_USER_INFO } from '../actions/constants';

/*
 * @Author: liuqing
 * @Date: 2021-09-28 11:05:25
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-08 11:08:23
 * @Description:
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
export default function baseReducer(
  state: any,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case STORE_PERMISSION:
      return {
        ...state,
        authPermissionList: action.payload,
      };
    case STORE_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
}
