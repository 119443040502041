/*
 * @Author: liuqing
 * @Date: 2021-09-27 18:15:18
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-16 16:40:50
 * @Description: 菜单
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
export interface IMenuItem {
  groupName: string;
  groupCode: string;
  link: string;
  img: string;
  children?: IMenuItem[];
}

const eduMenuList: IMenuItem[] = [
  // {
  //   groupName: '中心首页',
  //   groupCode: 'EduHomePage',
  //   link: '/edu/home',
  //   img: 'https://image.xiaomaiketang.com/xm/EEcF8HQREF.svg',
  // },
  {
    groupName: '考核表',
    groupCode: 'EduAssessTable',
    link: '/edu/examine',
    img: 'https://image.xiaomaiketang.com/xm/yjFMQbHmhE.svg',
  },
  {
    groupName: '标签管理',
    groupCode: 'EduTag',
    link: '/tag',
    img: 'https://image.xiaomaiketang.com/xm/2asBDJte6d.svg',
  },
  {
    groupName: '分类管理',
    groupCode: 'EduCategory',
    link: '/category',
    img: 'https://image.xiaomaiketang.com/xm/X6efSXcnc3.svg',
  },
  {
    groupName: '用户管理',
    groupCode: 'EduAdminManage',
    link: '/edu/user',
    img: 'https://image.xiaomaiketang.com/xm/3dMtrTzmM7.svg',
  },
  {
    groupName: '学校管理',
    groupCode: 'EduSchoolManage',
    link: '/school',
    img: 'https://image.xiaomaiketang.com/xm/pfDGpP2Tai.svg',
  },
];

const schMenuList: IMenuItem[] = [
  // {
  //   groupName: '中心首页',
  //   groupCode: 'SchHomePage',
  //   link: '/sch/home',
  //   img: 'https://image.xiaomaiketang.com/xm/EEcF8HQREF.svg',
  // },
  {
    groupName: '考核任务表',
    groupCode: 'SchAssessTask',
    link: '/sch/examine',
    img: 'https://image.xiaomaiketang.com/xm/yjFMQbHmhE.svg',
  },
  {
    groupName: '用户管理',
    groupCode: 'SchAdminManage',
    link: '/sch/user',
    img: 'https://image.xiaomaiketang.com/xm/3dMtrTzmM7.svg',
  },
];

export const menuList: IMenuItem[] = [...eduMenuList, ...schMenuList];
