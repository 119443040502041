/*
 * @Author: liuqing
 * @Date: 2021-09-27 15:43:15
 * @LastEditors: liuqing
 * @LastEditTime: 2021-10-14 10:06:33
 * @Description: 
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */

import Service from "@/common/js/service";

export function getAdminFullDetailById(id: string | number) {
  return Service.Public('admin/getAdminFullDetailById', { id });
}

export function getAdminDetailById(id: string | number) {
  return Service.Public('admin/getAdminDetailById', { id });
}

export function getEduAdminByPage(params: object) {
  return Service.Public('admin/getEduAdminByPage', params);
}

export function saveEduAdmin(params: object) {
  return Service.Public('admin/saveEduAdmin', params);
}

export function editEduAdmin(params: object) {
  return Service.Public('admin/editEduAdmin', params);
}

export function delEduAdmin(id: string | number) {
  return Service.Public('admin/delEduAdmin', { id });
}

export function getSchAdminByPage(params: object) {
  return Service.Public('admin/getSchAdminByPage', params);
}

export function editSchAdmin(params: object) {
  return Service.Public('admin/editSchAdmin', params);
}

export function delSchAdmin(id: string | number) {
  return Service.Public('admin/delSchAdmin', { id });
}

export function saveAdminSetting(params: object) {
  return Service.Public('admin/saveAdminSetting', params);
}

export function updateAdminPassword(params: object) {
  return Service.Public('admin/updateAdminPassword', params);
}

export function updateAdminPhone(params: object) {
  return Service.Public('admin/updateAdminPhone', params);
}

export function unBindWechat(params: object) {
  return Service.Public('admin/unBindWechat', params);
}






