/*
 * @Author: liuqing
 * @Date: 2021-09-24 15:21:03
 * @LastEditors: liuqing
 * @LastEditTime: 2021-09-25 14:31:54
 * @Description: 下拉选择框的二次封装
 * @Copyright: ©2021 杭州杰竞科技有限公司 版权所有
 */
import React, { ReactNode } from 'react';
import { Cascader } from 'antd';


function XMCascader(props: any) {
    const { label, className, selectClassName, width, ...restProps } = props;
    return (
        <div className={className}>
            {label && <label>{label}：</label>}
            <Cascader
                {
                ...restProps
                }
                changeOnSelect={true}
                allowClear

            />
        </div>
    );
}

export default XMCascader;
